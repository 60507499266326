export const getFromLocalStorage = (key: string) => {
    return localStorage.getItem(key);
};

export const getToken = () => {
    return getFromLocalStorage("auth_token");
};

export const setLanguageToLocalStorage = (lang: string): void => {
    localStorage.setItem("language", lang);
};

export const getLanguageFromLocalStorage = () => {
    return localStorage.getItem("language");
};
