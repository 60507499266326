import React, { ReactElement } from "react";
import { EditLockField, EditLockItemStyle, EditLockLabel } from "./edit-lock.styled";
import { CustomDatePicker } from "../../../components/date-picker/date-picker.component";
import { Input, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { EditLockInterface } from "./edit-lock.types";

interface EditCodecardFormProps {
    formValues: EditLockInterface;
    onFormChangeLabel: (e: any) => void;
    onFormChangeNotes: (e: any) => void;
    onFormChangeTimezone: (key: string) => void;
    onFormChangeDate: (_: any, dateString: string) => void;
}

export const EditLockForm = (props: EditCodecardFormProps): ReactElement => {
    const {
        formValues,
        onFormChangeTimezone,
        onFormChangeDate,
        onFormChangeLabel,
        onFormChangeNotes,
    } = props;

    return (
        <>
            <EditLockItemStyle>
                <EditLockLabel>Battery change :</EditLockLabel>
                <EditLockField>
                    <CustomDatePicker
                        handleChange={onFormChangeDate}
                        placeholder={formValues.batteryChange}
                    />
                </EditLockField>
            </EditLockItemStyle>

            <EditLockItemStyle>
                <EditLockLabel>Label :</EditLockLabel>
                <EditLockField>
                    <Input
                        placeholder={"Label"}
                        value={formValues.label}
                        onChange={onFormChangeLabel}
                    />
                </EditLockField>
            </EditLockItemStyle>

            {false && (
                <EditLockItemStyle>
                    <EditLockLabel>Timezone :</EditLockLabel>
                    <EditLockField>
                        <Select
                            mode="multiple"
                            placeholder={"Timezone"}
                            value={formValues?.timezone}
                            options={[{ value: "22", label: "22", id: 1 }]}
                            style={{ width: "100%" }}
                            tokenSeparators={[","]}
                            onChange={onFormChangeTimezone}
                        />
                    </EditLockField>
                </EditLockItemStyle>
            )}

            <EditLockItemStyle>
                <EditLockLabel>Notes :</EditLockLabel>
                <EditLockField>
                    <TextArea rows={4} style={{ width: "100%" }} onChange={onFormChangeNotes} />
                </EditLockField>
            </EditLockItemStyle>
        </>
    );
};
