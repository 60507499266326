import React from "react";
import { AvatarWrapper, UserContainer, UserInfo } from "./user.styled";
import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { LanguageDropdown } from "../dropdowns/language-dropdown/language-dropdown.component";
import { useQuery } from "urql";
import { GET_USER } from "../../graphql/queries/users/user.query";
import { StandardButton } from "../buttons/standart-button/standard-button.component";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LoaderContentWrapper } from "../../styles/loader-wrapper.styled";
import Loader from "../loader/loader.component";
import { getStaticUserPath } from "../../routes/paths/static-paths";

interface TopBarContentProps {}

export const TopBarContent: React.FC<TopBarContentProps> = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleLogin = () => {
        window.open("https://account.telkey.com/", "_blank");
    };
    const handleLogout = () => {};

    const handleNavigateToManager = () => {
        navigate(getStaticUserPath());
    };

    const [result] = useQuery({
        query: GET_USER,
    });
    const { data, fetching } = result || {};

    if (fetching) {
        return (
            <LoaderContentWrapper>
                <Loader />
            </LoaderContentWrapper>
        );
    }

    const { User: user } = data || {};

    return (
        <UserContainer>
            {data ? (
                <UserInfo>
                    <Avatar
                        size={40}
                        icon={
                            user.avatarUrl ? (
                                <AvatarWrapper sizes={"40px"} src={user.avatarUrl} />
                            ) : (
                                <UserOutlined />
                            )
                        }
                    />
                    <div style={{ marginRight: "20px", marginLeft: "15px" }}>
                        <h3>{user?.name}</h3>
                        {user?.email}
                    </div>
                    <StandardButton
                        title={t("buttons.my_account")}
                        handleClick={handleNavigateToManager}
                    />
                    <div style={{ marginRight: "15px" }} />
                    <StandardButton title={t("buttons.logout")} handleClick={handleLogout} />
                </UserInfo>
            ) : (
                <UserInfo>
                    <StandardButton title={t("buttons.login")} handleClick={handleLogin} />
                </UserInfo>
            )}

            <LanguageDropdown />
        </UserContainer>
    );
};
