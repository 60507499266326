import React, { ReactElement } from "react";

import { ArrowLeftOutlined } from "@ant-design/icons";
import { PageHeader } from "antd";
import { ContentHeaderStyled } from "./content-header.styled";
import { CustomBreadcrumbs } from "../breadcrumbs/bredcrambs.component";
import { BreadcrumbsInterface } from "../../types/Breadcrumbs.interface";

interface ContentHeaderProps {
    label: string;
    isBackPath?: boolean;
    breadcrumbs?: BreadcrumbsInterface[];
    buttonsBlock?: any;
}

interface SimpleHeaderProps {
    label: string;
}
const SimpleHeader = (props: SimpleHeaderProps): ReactElement => {
    const { label } = props;

    return <p>{label}</p>;
};

interface BackPathHeaderProps {
    label: string;
    isBackPath?: boolean;
}
const BackPathHeader = (props: BackPathHeaderProps): ReactElement => {
    const { label, isBackPath } = props;

    const handleGoBack = () => {
        if (isBackPath) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            window?.history.state.idx === 0 ? null : window.history.back();
        }
    };

    return (
        <>
            <button onClick={handleGoBack}>
                {window?.history.state.idx === 0 ? (
                    <ArrowLeftOutlined style={{ fontSize: "17px" }} />
                ) : null}
                &nbsp;
                <span>{label}</span>
            </button>
        </>
    );
};

const ContentHeader = (props: ContentHeaderProps): ReactElement => {
    const { label, isBackPath, breadcrumbs, buttonsBlock } = props;

    const Header = isBackPath ? (
        <BackPathHeader label={label} isBackPath={isBackPath} />
    ) : (
        <SimpleHeader label={label} />
    );

    return (
        <ContentHeaderStyled>
            <PageHeader
                key={label}
                title={Header}
                className="site-page-header"
                extra={[buttonsBlock && buttonsBlock()]}
                breadcrumbRender={() => <CustomBreadcrumbs breadcrumbs={breadcrumbs} />}
            />
        </ContentHeaderStyled>
    );
};

export default ContentHeader;
