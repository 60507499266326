import React, { ReactElement } from "react";
import { PageContainer, PageContentContainer } from "../../styles/layouts";
import { ContentLayout } from "../../layouts/content-layout/content-layout.component";
import { EditLockPageBreadcrumbs } from "../../constants/breadcrumbs";
import { useOrganization } from "../../hooks/useOrganization";
import { useParams } from "react-router-dom";
import { EditLock } from "./edit-lock/edit-lock";
import ContentHeader from "../../components/content-header/content-header.component";
import Loader from "../../components/loader/loader.component";
import { LoaderPageWrapper } from "../../styles/loader-wrapper.styled";
import { useOrganizationBreadcrumbs } from "../../hooks/useOrganizationBreadcrumbs";
import { MenuItemsEnum } from "../../components/menu-component/menu.component";
import { PageEnum } from "../../types/PagesEnum";

interface CodecardEditPageBodyProps {
    organizationId: string;
    lockId: string;
}

const EditCodecardPageBody = (props: CodecardEditPageBodyProps): ReactElement => {
    const { organizationId, lockId } = props;

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <ContentHeader
                label={"Edit lock"}
                breadcrumbs={EditLockPageBreadcrumbs(organizationId, lockId, lockId)}
            />
            <EditLock organizationId={organizationId} lockId={lockId} />
        </div>
    );
};

interface RouteParams {
    organizationId: string;
    lockId: string;
}

export const EditLockPage = () => {
    const { organizationId, lockId } = useParams() as RouteParams;
    const { organization, fetching } = useOrganization();
    const { organizationBreadcrumbs } = useOrganizationBreadcrumbs(PageEnum.EDIT_LOCK);
    const { name: organizationName } = organization || {};

    if (fetching) {
        return (
            <LoaderPageWrapper>
                <Loader />
            </LoaderPageWrapper>
        );
    }

    return (
        <PageContainer>
            <PageContentContainer>
                <ContentLayout
                    breadcrumbs={organizationBreadcrumbs}
                    label={organizationName || ""}
                    openKey={MenuItemsEnum.Locks}
                    extra
                    isBackPath
                    ContentLayoutBody={
                        <EditCodecardPageBody organizationId={organizationId} lockId={lockId} />
                    }
                />
            </PageContentContainer>
        </PageContainer>
    );
};
