import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const SendCodecardWrapperStyled = styled.div`
    max-width: 45%;
    p {
        padding: 5px 0 20px 80px;
        color: ${colors.graySeven};
    }
`;
export const SendCodecardStyled = styled.div`
    display: flex;
    padding: 0 40px;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    margin: 0 0 20px 10px;
    Button {
        padding: 0 70px;
    }
    Select {
        margin: 0 0 20px 10px;
        width: 355px;
    }
    Input {
        margin-left: 10px;
        width: 355px;
    }
`;
export const SendDataContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin: 0 0 20px 0;
`;

export const SendCodecardButtons = styled.div`
    display: flex;
    box-shadow: rgb(240 240 240) 0px 1px 5px inset;
    align-items: center;
    justify-content: flex-end;
    padding-top: 24px;
    padding-right: 24px;
    margin: 0 0 20px 0;
    button {
        margin-left: 12px;
    }
`;
