import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PageContainer, PageContentContainer } from "../../styles/layouts";
import { ContentLayout } from "../../layouts/content-layout/content-layout.component";
import { useOrganization } from "../../hooks/useOrganization";
import ContentHeader from "../../components/content-header/content-header.component";
import { KeyValueLine } from "../../components/key-value-line/key-value-line";
import { OrganisationInterface } from "../../types/Organisation.interface";
import { DetailsName } from "./details-page-components/details-name/details-name";
import { DetailsLocation } from "./details-page-components/details-location/details-location";
import Loader from "../../components/loader/loader.component";
import { LoaderPageWrapper } from "../../styles/loader-wrapper.styled";
import { useOrganizationBreadcrumbs } from "../../hooks/useOrganizationBreadcrumbs";
import { MenuItemsEnum } from "../../components/menu-component/menu.component";
import { PageEnum } from "../../types/PagesEnum";

interface DetailsLayoutBodyProps {
    organization: OrganisationInterface;
}

const DetailsLayoutBody = (props: DetailsLayoutBodyProps): ReactElement => {
    const { organization } = props || {};
    const { id, name, zip, city, street, country, notes } = organization || {};
    const { t } = useTranslation();

    return (
        <div style={{ width: "100%" }}>
            <div>
                <ContentHeader label="Details" />

                <div>
                    <KeyValueLine
                        Key={<div>{t("details_page.name")}</div>}
                        Value={<DetailsName id={id} name={name!} />}
                    />
                    <KeyValueLine
                        Key={<div>{t("details_page.location")}</div>}
                        Value={
                            <DetailsLocation
                                id={id}
                                location={{
                                    street,
                                    zip,
                                    city,
                                    country,
                                }}
                            />
                        }
                    />
                    <KeyValueLine
                        Key={<div>{t("details_page.otherInfo")}</div>}
                        Value={<div>{notes}</div>}
                    />
                </div>
            </div>
        </div>
    );
};

export const DetailsPage = (): ReactElement => {
    const { organization, fetching } = useOrganization();
    const { organizationBreadcrumbs } = useOrganizationBreadcrumbs(PageEnum.DETAILS);

    const { name: organizationName } = organization || {};

    if (fetching) {
        return (
            <LoaderPageWrapper>
                <Loader />
            </LoaderPageWrapper>
        );
    }

    return (
        <PageContainer>
            <PageContentContainer>
                <ContentLayout
                    breadcrumbs={organizationBreadcrumbs}
                    label={organizationName || ""}
                    openKey={MenuItemsEnum.Details}
                    ContentLayoutBody={<DetailsLayoutBody organization={organization} />}
                    isBackPath
                />
            </PageContentContainer>
        </PageContainer>
    );
};
