export const GET_KEYS = `
    query($lock_id: Int!){
        Keys(lock_id: $lock_id){
            id,
            lock{
                id,
                label,
                type
            },
            codecard{id}
        }
    }
`;
