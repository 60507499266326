import React, { useEffect, useState } from "react";
import { PageContainer, PageContentContainer } from "../../styles/layouts";
import { ContentLayout } from "../../layouts/content-layout/content-layout.component";
import { FilterArea } from "../../components/filter-area/filter-area.component";
import { useQuery } from "urql";
import {
    GET_LOCKS,
    GET_LOCKS_ASC_BY_LABEL,
    GET_LOCKS_DESC_BY_LABEL,
} from "../../graphql/queries/locks/locks.query";
import { useParams } from "react-router-dom";
import { useOrganization } from "../../hooks/useOrganization";
import ContentHeader from "../../components/content-header/content-header.component";
import { Button } from "antd";
import { useOrganizationBreadcrumbs } from "../../hooks/useOrganizationBreadcrumbs";
import { useSearch } from "../../hooks/useSearch";
import { useTranslation } from "react-i18next";
import { useRouteNavigation } from "../../hooks/useNavigationPatch";
import { RouteParamsInterface } from "../../types/RouteParams.interface";
import Loader from "../../components/loader/loader.component";
import { Locks } from "./locks/locks.component";
import { NoItemMessage } from "../../components/messages/no-item-message/no-item-message.component";
import { FilterNotification } from "../../components/filter-notification/filter-notification.component";
import { NoItemMessageEnum } from "../../utils/enums/message.enum";
import { LoaderContentWrapper, LoaderPageWrapper } from "../../styles/loader-wrapper.styled";
import { MenuItemsEnum } from "../../components/menu-component/menu.component";
import { PageEnum } from "../../types/PagesEnum";

enum SortParamsEnum {
    asc = "asc",
    desc = "desc",
}

const getLocsGqlBySortParam = (sortParam: SortParamsEnum): string => {
    return sortParam === SortParamsEnum.asc ? GET_LOCKS_ASC_BY_LABEL : GET_LOCKS_DESC_BY_LABEL;
};

interface LocksLayoutBodyProps {}

export const LocksLayoutBody: React.FC<LocksLayoutBodyProps> = () => {
    const { organizationId } = useParams() as RouteParamsInterface;
    const [searchValue, setSearchValue] = useState("");

    const { navigateToCreateLock } = useRouteNavigation();

    const [locks, setLocks] = useState([]);
    const [sortByLabelParam, setSortByLabelParam] = useState(SortParamsEnum.asc);
    const [isNotification, setIsNotification] = useState(false);

    const [resultLocks] = useQuery({
        query: getLocsGqlBySortParam(sortByLabelParam),
        variables: { organisation_id: organizationId },
    });

    const { data, fetching } = resultLocks || {};

    useEffect(() => {
        if (data && Array.isArray(data.Locks)) {
            const { Locks } = data;

            setLocks(Locks);
        }
    }, [data]);

    const handleNavigateToAddLock = () => {
        navigateToCreateLock(organizationId);
    };

    const buttonsBlock = () => {
        return (
            <Button type="primary" onClick={handleNavigateToAddLock}>
                Add lock
            </Button>
        );
    };

    const handleChangeSortParam = (sortParam: SortParamsEnum): void => {
        setSortByLabelParam(sortParam);
    };

    const handleChangeSearch = (value: string) => {
        setSearchValue(value);
    };

    useEffect(() => {
        sortByLabelParam === SortParamsEnum.desc || searchValue.length
            ? setIsNotification(true)
            : setIsNotification(false);
    }, [sortByLabelParam, searchValue, isNotification]);

    const { searchResult: locksSearchResult } = useSearch(locks, "label", searchValue);

    const handleClear = () => {
        setSearchValue("");
        setSortByLabelParam(SortParamsEnum.asc);
    };

    if (fetching) {
        return (
            <LoaderContentWrapper>
                <Loader />
            </LoaderContentWrapper>
        );
    }

    return (
        <div style={{ width: "100%" }}>
            <ContentHeader label={"Locks"} buttonsBlock={buttonsBlock} />

            <FilterArea
                searchValue={searchValue}
                onChangeSearch={handleChangeSearch}
                sortValue={sortByLabelParam}
                onChangeSort={handleChangeSortParam}
            />

            {locksSearchResult?.length ? (
                locksSearchResult?.map((item: any, idx: number) => {
                    return <Locks key={idx} lock={item} />;
                })
            ) : (
                <div
                    style={{
                        width: "100%",
                        fontSize: "20px",
                        textAlign: "center",
                        marginTop: "20px",
                    }}
                >
                    <NoItemMessage type={NoItemMessageEnum.LOCKS} />
                </div>
            )}

            {isNotification && <FilterNotification handleClear={handleClear} />}
        </div>
    );
};

export const LocksPage = () => {
    const { organizationId } = useParams() as RouteParamsInterface;
    const { organization, fetching } = useOrganization();
    const { organizationBreadcrumbs } = useOrganizationBreadcrumbs(PageEnum.LOCKS);
    const { navigateToCreateCodecards } = useRouteNavigation();
    const { t } = useTranslation();

    const orgId = parseInt(organizationId);

    const [resultLocks] = useQuery({
        query: GET_LOCKS,
        variables: { organisation_id: orgId },
    });

    const { data } = resultLocks || {};
    const { Locks } = data || {};

    const handleNavigateToCreateCodecards = () => {
        navigateToCreateCodecards(organizationId);
    };

    const isDisabledBtn = !Locks?.length;

    const extra = (
        <Button disabled={isDisabledBtn} type="primary" onClick={handleNavigateToCreateCodecards}>
            {t("buttons.create_codecard")}
        </Button>
    );

    if (fetching) {
        return (
            <LoaderPageWrapper>
                <Loader />
            </LoaderPageWrapper>
        );
    }

    return (
        <PageContainer>
            <PageContentContainer>
                <ContentLayout
                    breadcrumbs={organizationBreadcrumbs}
                    label={organization?.name!}
                    extra={extra}
                    openKey={MenuItemsEnum.Locks}
                    ContentLayoutBody={<LocksLayoutBody />}
                    isBackPath
                />
            </PageContentContainer>
        </PageContainer>
    );
};
