import React, { ReactElement } from "react";
import { useInitApp } from "./hooks/useInitApp";

import { MainLayout } from "./layouts/main-layout/main-layout.component";
import "./i18/i18n";
import Loader from "./components/loader/loader.component";
import { LoaderProjectWrapper } from "./styles/loader-wrapper.styled";
import { useWindowDimensions } from "./hooks/useWindowDimensions";

const App = (): ReactElement => {
    useWindowDimensions();
    const { isInitializing } = useInitApp();

    if (isInitializing) {
        return (
            <LoaderProjectWrapper>
                <Loader />
            </LoaderProjectWrapper>
        );
    }

    return <MainLayout />;
};

export default App;
