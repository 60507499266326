export enum CodecardFormEnum {
    NAME = "name",
    LOCKS = "locks",
    VALID_FROM = "validFrom",
    VALID_TO = "validTo",
    VALID_FOR = "validFor",
    ACTIVE = "active",
    NOTES = "notes",
}

export enum LockFormEnum {
    NAME = "name",
    USERNAME = "username",
}

export enum SubUnitFormEnum {
    PARENT = "parent",
    NAME = "name",
    ORG_NUMBER = "orgNumber",
    STREET = "street",
    POSTCODE = "postcode",
    CITY = "city",
    COUNTRY = "country",
    NOTES = "notes",
    IS_NOTIFICATION_EMAIL = "isCreateNotificationEmail",
    IS_NOTIFICATION_SMS = "isCreateNotificationSms",
}
