import React, { ReactElement } from "react";

import LOGO from "../../images/logo.png";
import { SupportedDevicesEnum } from "../../types/SupportedDevicesEnum";
import {
    defaultLogoAdaptiveConfig,
    logoAdaptiveConfig,
    LogoAdaptiveConfigInterface,
} from "./telkey-logo-adaptive.config";
import { destructureObject } from "../../utils/destructureObject";

import { TelkeyLogoContainer } from "./telkey-logo.styled";

interface TelkeyLogoProps {
    deviceType?: SupportedDevicesEnum;
}

export const TelkeyLogo = (props: TelkeyLogoProps): ReactElement => {
    const { deviceType } = props;

    const { logoImg } = destructureObject<LogoAdaptiveConfigInterface>(
        logoAdaptiveConfig[deviceType as SupportedDevicesEnum],
        defaultLogoAdaptiveConfig,
    );
    const { width, height } = logoImg;

    return (
        <TelkeyLogoContainer>
            <img src={LOGO} width={width} height={height} alt="Logo" />

            <span>TELKEY</span>
        </TelkeyLogoContainer>
    );
};
