import { createContext, useContext } from "react";
import makeInspectable from "mobx-devtools-mst";

import { OrganisationsStore } from "./organisationsStore";
import { UsersStore } from "./usersStore";
import { WindowDimensionsStore } from "./windowDimensionsStore";
import { ApplicationStore } from "./applicationStore";

export interface StoreInterface {
    organisationStore: OrganisationsStore;
    usersStore: UsersStore;
    windowDimensionsStore: WindowDimensionsStore;
    applicationStore: ApplicationStore;
}

export type UseStoreType =
    | OrganisationsStore
    | UsersStore
    | WindowDimensionsStore
    | ApplicationStore;

export const store: StoreInterface = {
    organisationStore: new OrganisationsStore(),
    usersStore: new UsersStore(),
    windowDimensionsStore: new WindowDimensionsStore(),
    applicationStore: new ApplicationStore(),
};

export const StoreContext = createContext(store);

export enum Stores {
    ORGANISATION_STORE = "organisationStore",
    USERS_STORE = "usersStore",
    WINDOW_DIMENSIONS_STORE = "windowDimensionsStore",
    APPLICATION_STORE = "applicationStore",
}

export const useStore = (store: Stores): UseStoreType => {
    const stores: StoreInterface = useContext(StoreContext);

    makeInspectable(stores);

    return stores[store];
};
