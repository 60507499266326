import styled from "styled-components";

export const LoaderStyled = styled.div`
    border-radius: 50px;
`;
export const LoaderAnimatedStyled = styled.div`
    width: 50px;
    height: 48px;
    border-radius: 50px;
    animation: loading 10s linear infinite;
    @keyframes loading {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
