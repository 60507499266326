import styled from "styled-components";
import { colors } from "../../styles/colors";

const { backgroundColor } = colors;

export const MainLayoutContainer = styled.div`
    background-color: ${backgroundColor};
`;

export const ContentContainer = styled.div`
    width: calc(100%);
    display: flex;
`;

export const TopBarContainer = styled.div`
    width: 100%;
    background: #000000;
    display: flex;
    justify-content: space-between;
    padding: 0 25px;
    height: 64px;

    @media (max-width: 992px) {
        align-items: center;
    }
`;
