export const ADD_PERMISSION = `
    mutation (
        $organisation_id: Int!,
        $user_id: Int!,
        $type: Int!,
        ){
            addPermission(
                organisation_id:$organisation_id,
                user_id:$user_id,
                type: $type
        )
            {
                id,
                user{id, name},
                organisation_name,
                permission_type,
                created_at,
                updated_at,
                created_by{id, name},
                pending,
            }
        }
`;

export const REMOVE_PERMISSION = ` 
    mutation ($id: Int!)
    {
        removePermission(id:$id)
            {
                id,
                user{id, name},
                organisation{id, name},
                permission_type,
                created_at,
                updated_at,
                created_by{id, name},
                pending
            }
    }
`;

export const APPROVE_PERMISSION = ` 
    mutation ($id: Int!)
    {
        approvePermission(id:$id)
            {
                id,
                user{id, name},
                organisation{id, name},
                permission_type,
                created_at,
                updated_at,
                created_by{id, name},
                pending
            }
    }
`;
