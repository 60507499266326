import React, { ReactElement } from "react";

import { Select, SelectProps } from "antd";
import { getCountriesOptions } from "../../utils/countries.utils";

const { Option } = Select;

export const CountriesSelect = (props: SelectProps): ReactElement => {
    const countriesOptions = getCountriesOptions();

    return (
        <Select {...props}>
            {countriesOptions.map(({ value, label }) => (
                <Option value={value}>{label}</Option>
            ))}
        </Select>
    );
};
