import styled from "styled-components";

export const CreateSubUnitStyled = styled.div`
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
`;

export const CreateSubUnitWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 27px 16px;
`;

export const CreateSubUnitBtns = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;
    padding-right: 24px;
    margin: 24px;
    button + button {
        margin-left: 12px;
    }
`;
