import { StandardButtonStyled } from "./standard-button.styled";
import React from "react";

interface StandardButtonProps {
    title: string;
    handleClick: React.MouseEventHandler<HTMLButtonElement>;
    width?: number;
    height?: number;
}

export const StandardButton: React.FC<StandardButtonProps> = (props) => {
    const { title, handleClick, width, height } = props;

    return (
        <StandardButtonStyled width={width} height={height} onClick={handleClick}>
            {title}
        </StandardButtonStyled>
    );
};
