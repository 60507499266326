import { useEffect, useState } from "react";
import { CreateCodecardType } from "./create-codecard.type";

const validateForm = (formValues: CreateCodecardType): any => {
    const validatedFields: any = {
        name: { isValid: true },
        locks: { isValid: true },
    };
    let isFormValid = true;

    if (!formValues?.name) {
        isFormValid = false;
        validatedFields.name = {
            isValid: false,
            message: "Enter codecard name",
        };
    }

    if (!formValues?.locks.length) {
        isFormValid = false;
        validatedFields.locks = {
            isValid: false,
            message: "Lock error",
        };
    }

    return { validatedFields, isFormValid };
};

export const useCreateCodecardFormValidation = (formValues: CreateCodecardType): any => {
    const [validatedForm, setValidatedForm] = useState<any>({});

    useEffect(() => {
        const validatedForm = validateForm(formValues);

        setValidatedForm(validatedForm);
    }, [formValues]);

    return {
        validatedFields: validatedForm.validatedFields,
        isFormValid: validatedForm.isFormValid,
    };
};
