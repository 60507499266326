export const LOCKS = "locks";
export const LOCK = "lock";
export const USER = "user";
export const USERS = "users";
export const ORGANIZATIONS = "organizations";
export const MESSAGE = "message";
export const KEYS = "keys";
export const KEY = "key";
export const CODECARDS = "codecards";
export const CODECARD = "codecard";
export const DETAILS = "details";
export const SUBUNITS = "subunits";
export const NOTIFICATIONS = "notifications";
export const SETTINGS = "settings";

export const CREATE = "create";
export const ADD = "add";
export const DELETE = "delete";
export const EDIT = "edit";
export const SEND = "send";

export const BASE_ROUTE = `/manager/${ORGANIZATIONS}`;
export const BASE_STATIC_ROUTE = `${BASE_ROUTE}/:organizationId`;
