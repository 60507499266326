import { Params, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Stores, useStore } from "../../../stores/store";
import { OrganisationsStore } from "../../../stores/organisationsStore";
import { TableBodyContentStyled } from "../../../styles/table";
import { useRouteNavigation } from "../../../hooks/useNavigationPatch";
import { LoaderContentWrapper } from "../../../styles/loader-wrapper.styled";
import Loader from "../../../components/loader/loader.component";

interface CodecardWrapperProps {
    codecard: any;
    organizationId: string;
}

const CodecardWrapper = (props: CodecardWrapperProps) => {
    const { codecard, organizationId } = props;
    const { navigateToCodecard, navigateToSendCodecard, navigateToLock } = useRouteNavigation();
    const { t } = useTranslation();
    const { id, valid_from: validFrom, valid_to: validTo, keys } = codecard;

    const handleNavigateToCodecard = () => {
        navigateToCodecard(organizationId, id);
    };
    const handleNavigateToSendCodecard = () => {
        navigateToSendCodecard(organizationId, id);
    };
    const handleNavigateToLock = (id: any) => {
        navigateToLock(organizationId, id);
    };

    const getLock = () => {
        const lock = keys?.map(({ lock }: any, idx: number) => {
            const separator = keys.length - 1 > idx ? ", " : "";
            const displayName = lock?.label ? lock.label + separator : lock.type + separator;

            return (
                <span
                    style={{ cursor: "pointer", display: "flex", justifyContent: "start" }}
                    key={lock.id}
                    onClick={() => handleNavigateToLock(lock.id)}
                >
                    {displayName}
                </span>
            );
        });

        return lock;
    };

    return (
        <TableBodyContentStyled>
            <td
                style={{ cursor: "pointer", wordBreak: "break-all" }}
                onClick={handleNavigateToCodecard}
            >
                {id}
            </td>
            <td style={{ wordBreak: "break-all", textAlign: "left" }}>{getLock()}</td>
            <td style={{ color: "black" }}>
                {new Date(validFrom).toDateString()}-<br />
                {new Date(validTo).toDateString()}
            </td>
            <td>
                <button onClick={handleNavigateToSendCodecard}>{t("buttons.send")}</button>
            </td>
        </TableBodyContentStyled>
    );
};
interface CodecardsProps {
    codecard: any;
}
export const Codecards = (props: CodecardsProps) => {
    const { codecard } = props;
    const { organizationId }: Params = useParams();

    const { organisations } = useStore(Stores.ORGANISATION_STORE) as OrganisationsStore;

    if (!organisations) {
        return (
            <LoaderContentWrapper>
                <Loader />
            </LoaderContentWrapper>
        );
    }

    return <CodecardWrapper codecard={codecard} organizationId={organizationId!} />;
};
