import React, { ReactElement } from "react";

import { Button } from "antd";
import { useTranslation } from "react-i18next";

interface DetailsConfirmButtonsProps {
    isEditMode: boolean;
    onChangeMode: () => void;
    onSave: () => void;
}

export const DetailsConfirmButtons = (props: DetailsConfirmButtonsProps): ReactElement => {
    const { isEditMode, onSave, onChangeMode } = props;

    const { t } = useTranslation();

    if (isEditMode) {
        return (
            <div>
                <Button onClick={onChangeMode} style={{ marginRight: 10 }}>
                    {t("buttons.cancel")}
                </Button>
                <Button onClick={onSave} type="primary">
                    {t("buttons.save")}
                </Button>
            </div>
        );
    }

    return <Button onClick={onChangeMode}>{t("buttons.edit")}</Button>;
};
