import styled from "styled-components";
import { colors } from "../../styles/colors";

export const KeyValueLineContainer = styled.div`
    display: flex;
    border-top: 1px solid ${colors.borderGray};

    &:last-child {
        border-bottom: 1px solid ${colors.borderGray};
    }
`;

export const KeyContainer = styled.div`
    padding: 20px;
    background-color: ${colors.gray};
    border-left: 1px solid ${colors.borderGray};
    width: 50%;
`;

export const ValueContainer = styled.div`
    padding: 20px;
    width: 50%;
`;
