import React, { ReactElement } from "react";
import { NavLink } from "react-router-dom";
import { colors } from "../../styles/colors";
import {
    BreadcrumbsSeparatorStyled,
    BreadcrumbsStyled,
    SingleBreadcrumbStyled,
} from "./bredcrambs.styled";
import { BreadcrumbsInterface } from "../../types/Breadcrumbs.interface";

interface CustomBreadcrumbsProps {
    breadcrumbs?: BreadcrumbsInterface[];
    isLightColor?: boolean;
}

export const CustomBreadcrumbs = (props: CustomBreadcrumbsProps): ReactElement => {
    const { breadcrumbs, isLightColor = false } = props;

    return (
        <BreadcrumbsStyled>
            {breadcrumbs &&
                breadcrumbs.map(({ path, breadcrumbName }, idx: number) => (
                    <SingleBreadcrumbStyled key={idx}>
                        <NavLink
                            style={{
                                color:
                                    idx === breadcrumbs.length - 1
                                        ? isLightColor
                                            ? colors.white
                                            : colors.grayNine
                                        : isLightColor
                                        ? colors.white
                                        : colors.graySeven,
                            }}
                            to={path}
                        >
                            {breadcrumbName}
                        </NavLink>
                        {breadcrumbs.length !== idx + 1 && (
                            <BreadcrumbsSeparatorStyled key={"separator" + idx}>
                                &nbsp;/&nbsp;
                            </BreadcrumbsSeparatorStyled>
                        )}
                    </SingleBreadcrumbStyled>
                ))}
        </BreadcrumbsStyled>
    );
};
