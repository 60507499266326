import React from "react";
import { SendDataContainer } from "../send-codecard.styled";
import { Input, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useTranslation } from "react-i18next";

export const SendCodecardEmailComponent = (props: any) => {
    const { data } = props;
    const { t } = useTranslation();
    const { Option } = Select;
    const children: React.ReactNode[] = [];

    for (let i = 10; i < 36; i++) {
        children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
    }

    const handleChange = () => {
        // ...
    };

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <SendDataContainer style={{ marginBottom: "0" }}>
                    {t("send_codecard.labels.codecard")}
                    <Input placeholder="E.g. “Carpenter”" />
                </SendDataContainer>
                <p> {t("send_codecard.labels.add_locks")}</p>
            </div>
            <SendDataContainer>
                {t("send_codecard.labels.from_email")}
                <Input
                    disabled
                    placeholder={data?.Codecard.organisation_id.email}
                    value={data?.Codecard.organisation_id.email}
                />
            </SendDataContainer>

            <SendDataContainer>
                {t("send_codecard.labels.to")}
                <Input placeholder="example@email.com" />
            </SendDataContainer>

            <SendDataContainer>
                {t("send_codecard.labels.message_template")}
                <Select
                    showArrow
                    mode="tags"
                    style={{ width: "355px", marginLeft: "10px" }}
                    onChange={handleChange}
                    tokenSeparators={[","]}
                >
                    {children}
                </Select>
            </SendDataContainer>

            <SendDataContainer>
                {t("send_codecard.labels.subject")}
                <Input placeholder="Some default title (set in Message Templates)" />
            </SendDataContainer>

            <SendDataContainer>
                {t("send_codecard.labels.content")}
                <TextArea rows={4} style={{ width: "355px", marginLeft: "10px" }} />
            </SendDataContainer>
        </>
    );
};
