import { Select } from "antd";
import { CreateCodecardMessage } from "./create-codecard.styled";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "urql";
import { GET_LOCKS } from "../../../graphql/queries/locks/locks.query";
import { mapLocksToDropdownOption } from "../../../utils/array";
import { useParams } from "react-router-dom";
import Loader from "../../../components/loader/loader.component";
import { LoaderContentWrapper } from "../../../styles/loader-wrapper.styled";
import { LockInterface } from "../../../types/Lock.interface";

interface SelectCodecardProps {
    onFormChange: (key: string) => (event: any) => void;
    formValues: any[];
    lockId?: string | number;
}

export const SelectCodecard: React.FC<SelectCodecardProps> = (props) => {
    const { onFormChange, formValues = [], lockId } = props;
    const [dataLocks, setDataLocks] = useState([]);
    const { organizationId } = useParams();
    const { t } = useTranslation();

    const [resultLocks] = useQuery({
        query: GET_LOCKS,
        variables: { organisation_id: organizationId },
    });
    const { data, fetching } = resultLocks || {};

    useEffect(() => {
        if (data?.Locks && Array.isArray(data?.Locks)) {
            const { Locks } = data || {};
            const locks = Locks?.map(mapLocksToDropdownOption);
            setDataLocks(locks);
        }
    }, [data]);

    const locks = data?.Locks?.map(mapLocksToDropdownOption);
    const lock = locks?.filter(({ id }: LockInterface) => id === parseInt(lockId as string));
    const defaultValue = lockId ? lock : locks;

    useEffect(() => {
        onFormChange("locks")(defaultValue);
    }, [data]);

    if (fetching) {
        return (
            <LoaderContentWrapper>
                <Loader />
            </LoaderContentWrapper>
        );
    }

    return (
        <>
            <Select
                mode="multiple"
                placeholder={t("create_codecard.lock_or_locks")}
                value={formValues}
                options={dataLocks}
                style={{ width: "100%" }}
                tokenSeparators={[","]}
                onChange={onFormChange("locks")}
                defaultValue={defaultValue}
            />
            {!formValues ||
                (!formValues.length && (
                    <CreateCodecardMessage>{t("create_codecard.add_locks")}</CreateCodecardMessage>
                ))}
        </>
    );
};
