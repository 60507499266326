import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const MobileHeaderLinksStyled = styled.div`
    display: flex;
    color: ${colors.white};

    a {
        font-size: 14px;
        line-height: 22px;
        color: ${colors.lightGray};
        padding-left: 16px;
        padding-right: 16px;

        &:hover {
            color: ${colors.white};
        }
    }
`;
