import styled, { css } from "styled-components";

import { colors } from "../../styles/colors";

const { dark } = colors;

const NavigationContainerMobile = css`
    position: fixed;
    top: 64px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${colors.dark};
    z-index: 3000;
    padding: 15px;
`;
const NavigationContainerMobileOpen = css`
    ${NavigationContainerMobile}

    display: block;
`;
const NavigationContainerMobileClose = css`
    ${NavigationContainerMobile}

    display: none;
`;
const NavigationContainerDesktop = css`
    display: flex;
    flex-direction: column;
    background-color: #000000;
    padding: 16px;
    height: calc(100vh - 64px);
`;

const getNavigationStyles = (props: any) => {
    if (props.isMobile) {
        return props.isMenuOpen ? NavigationContainerMobileOpen : NavigationContainerMobileClose;
    }

    return NavigationContainerDesktop;
};

export const NavigationContainer = styled.div<{ isMobile: boolean; isMenuOpen: boolean }>`
    ${getNavigationStyles}

    .ant-input {
        border: none;
        box-shadow: 0 0 0 1px #595959;
        color: #595959;
        margin-bottom: 20px;
    }

    .ant-input {
        background: #595959;
        outline: 1px solid #262626;
        color: ${colors.graySeven};
        padding: 5px 12px;
    }

    .ant-menu.ant-menu-dark {
        background: ${dark};

        .ant-menu-sub {
            background: #000000;
        }
    }

    .ant-input:hover {
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const MenuContainer = styled.div`
    margin-top: 20px;
    .ant-menu-item-group-title {
        cursor: default;
    }

    .ant-menu-dark.ant-menu-dark .ant-menu-item-selected {
        background-color: #1890ff;
    }
`;

export const UserInfoContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
`;

export const UserAvatar = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: green;
    margin-right: 10px;
`;

export const UserContactsContainer = styled.div`
    color: #fff;
    padding: 0;
    margin: 0;
    h3 {
        color: #fff;
        font-size: 20px;
    }

    p {
        font-size: 14px;
    }
`;

interface MenuNavigationItemProps {
    layer: number;
    isSelected: boolean;
}

export const MenuNavigationItemStyled = styled.p<MenuNavigationItemProps>`
    cursor: pointer;
    display: flex;
    color: #fff;
    padding: 5px 0;
    padding-left: ${({ layer }) => layer * 30 + "px"};
    background-color: ${({ isSelected }) => (isSelected ? "#1890FF" : "transparent")};
    margin: 0 -16px;

    height: 45px;
    font-size: 16px;
    align-items: center;
`;

export const SideNavigationContainer = styled.div<{ isMobile: boolean }>`
    min-width: ${({ isMobile }) => (isMobile ? "none" : "255px")};
`;

export const BtnsBlock = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    border-top: 1px solid ${colors.graySeven};
    min-width: 255px;
    padding: 20px;
`;
