import styled from "styled-components";
import { colors } from "./colors";
const { dark } = colors;

export const LoaderProjectWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    align-items: center;
`;

export const LoaderNavigateWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    padding-top: 50px;
    background: ${dark};
`;

export const LoaderPageWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
`;

export const LoaderContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
`;
