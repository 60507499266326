import React, { ReactElement, useEffect, useState } from "react";
import { useMutation } from "urql";
import {
    UPDATE_ORGANIZATION_LOCATION,
    UPDATE_ORGANIZATION_LOCATION_WITH_COUNTRY,
} from "../../../../graphql/mutations/organization/organization.mutations";
import { getCountryLabelByCode } from "../../../../utils/countries.utils";
import { DetailsConfirmButtons } from "../details-confirm-buttons/details-confirm-buttons";
import { DetailsLocationForm } from "./details-location-form";
import { DetailsLocationContainer } from "./details-location.styled";
import { DetailsLocationInterface } from "./details-location.types";

interface DetailsLocationProps {
    id: string | number;
    location: any;
}

const detailsLocationEmptyState: DetailsLocationInterface = {
    country: "",
    zip: "",
    street: "",
    city: "",
};

export const DetailsLocation = (props: DetailsLocationProps): ReactElement => {
    const { id, location } = props;
    const { country, zip, city, street } = location || {};
    const [currentLocation, setCurrentLocation] =
        useState<DetailsLocationInterface>(detailsLocationEmptyState);
    const [isEditMode, setIsEditMode] = useState(false);

    // eslint-disable-next-line
    const [result, updateOrganisationLocation] = useMutation(
        currentLocation.country
            ? UPDATE_ORGANIZATION_LOCATION_WITH_COUNTRY(currentLocation.country)
            : UPDATE_ORGANIZATION_LOCATION,
    );

    useEffect(() => {
        setCurrentLocation({
            country,
            zip,
            city,
            street,
        });
    }, [country, zip, city, street, isEditMode]);

    const handleChangeMode = (): void => {
        setIsEditMode(!isEditMode);
    };

    const handleSaveLocation = async (): Promise<void> => {
        await updateOrganisationLocation({
            id: Number(id),
            street: currentLocation?.street || street,
            country: currentLocation?.country || country,
            zip: currentLocation?.zip || zip,
            city: currentLocation?.city || city,
        });

        setIsEditMode(!isEditMode);

        handleChangeMode();
    };

    const handleChangeLocation =
        (key: string) =>
        (e: any): void => {
            setCurrentLocation({
                ...currentLocation,
                [key]: e.target.value,
            });
        };

    const handleChangeCountry = (value: string): void => {
        setCurrentLocation({
            ...currentLocation,
            country: value,
        });
    };

    if (!id) {
        return <></>;
    }

    return (
        <DetailsLocationContainer>
            {isEditMode ? (
                <DetailsLocationForm
                    currentLocation={currentLocation}
                    onChangeLocation={handleChangeLocation}
                    onChangeCountry={handleChangeCountry}
                />
            ) : (
                <div>
                    <p>{street}</p>
                    <p>
                        {zip} {city}
                    </p>
                    <p>{getCountryLabelByCode(country)}</p>
                </div>
            )}

            <DetailsConfirmButtons
                isEditMode={isEditMode}
                onChangeMode={handleChangeMode}
                onSave={handleSaveLocation}
            />
        </DetailsLocationContainer>
    );
};
