export const CREATE_KEY = `
    mutation (
        $lock_id: Int!,
        $label: String!,
        $valid_from: String!,
        $valid_to: String,
        $notes: String,
        $codecard_id: String
    ) {
        CreateKey (create: {
            lock_id: $lock_id,
            label: $label, 
            valid_from: $valid_from,
            valid_to: $valid_to,
            notes: $notes,
            codecard_id: $codecard_id
            
        }) {
            id,
            label,
            notes,
            lock{id},
            codecard{id}
        }
    }
`;

export const UPDATE_KEY = `
    mutation (
        $id: Int!,
        $changes: KeyChanges!
    ) {
        UpdateKey (
            id: $id,
            changes: {
                label: $label,
                is_blacklisted: $is_blacklisted,
                notes: $notes,
                codecard_id: $codecard_id
            }
        ) {
            id,
            lock {
				id,
				created_at,
				installed_at,
				installed_by {
				id,
				name,
				email,
				oldEmail,
				staff,
				language,
				created_at,
				updated_at
				},
				type,
				serial_number,
				battery_change,
				registered_at,
				registered_by {
				id,
				name,
				email,
				staff,
				language,
				created_at,
				updated_at
				},
				updated_at,
				updated_by {
				id,
				name,
				email,
				oldEmail,
				staff,
				language,
				created_at,
				updated_at
				},
				organisation {
					id,
					name
				},
				label,
				timezone,
				notes,
				manufacturer_serial,
				order {
					batch_id,
					type,
					amount,
					next,
					created_at,
					updated_at
				}
			},
            label,
            valid_from,
            valid_to,
            created_at,
            updated_at,
            created_by {
                id,
                name,
                email,
                oldEmail,
                staff,
                language,
                created_at,
                updated_at,
              },
            is_blacklisted,
            key,
            key_type,
            notes,
            blacklisted_date,
            codecard {
				id,
				organisation_id {
					id
				},
				active,
				valid_from,
				valid_to,
				created_by {
					id,
					name,
					email,
					oldEmail,
					staff,
					language,
					created_at,
					updated_at,
				},
				created_at,
				updated_at,
				updated_by {
					id,
					name,
					email,
					oldEmail,
					staff,
					language,
					created_at,
					updated_at
				}
			}
        }
    }
`;
