import React, { ChangeEvent, ReactElement } from "react";

import { Input, Select } from "antd";
import { FilterAreaStyled } from "./filter-area.styled";

interface FilterAreaProps {
    searchPlaceholder?: string;
    searchValue?: string;
    onChangeSearch?: (searchValue: any) => void;

    sortOptions?: any[];
    sortValue?: string;
    onChangeSort?: (sortParam: any) => void;
    sortPlaceholder?: string;

    filterOptions?: any[];
    filterValue?: any;
    onChangeFilter?: (filter: any) => void;
    filterPlaceholder?: string;
}

const defaultSortOptions = [
    { label: "Name A-Z", id: 1, value: "asc" },
    { label: "Name Z-A", id: 2, value: "desc" },
];

export const FilterArea = (props: FilterAreaProps): ReactElement => {
    const {
        searchPlaceholder = "E.g. 'Fjallen'",
        searchValue = "",
        onChangeSearch,

        sortValue = "",
        sortOptions = defaultSortOptions,
        onChangeSort,
        sortPlaceholder = "Choose sort",

        filterOptions,
        filterValue,
        onChangeFilter,
        filterPlaceholder = "Select filter",
    } = props;

    const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>): void => {
        if (!onChangeSearch) {
            return;
        }

        onChangeSearch(event.target.value);
    };

    const handleChangeSort = (value: string): void => {
        if (!onChangeSort) {
            return;
        }

        onChangeSort(value);
    };

    return (
        <FilterAreaStyled>
            {onChangeSearch && (
                <div style={{ marginRight: "12px", width: "inherit" }}>
                    <p>Search</p>
                    <Input
                        value={searchValue}
                        placeholder={searchPlaceholder}
                        onChange={handleChangeSearch}
                    />
                </div>
            )}
            {onChangeFilter && (
                <div style={{ marginRight: "12px", width: "inherit" }}>
                    <p>Filter locks </p>
                    <Select
                        mode="multiple"
                        allowClear
                        showArrow
                        style={{ width: "100%" }}
                        placeholder={filterPlaceholder}
                        onChange={onChangeFilter}
                        options={filterOptions}
                        value={filterValue}
                    />
                </div>
            )}
            {onChangeSort && (
                <div style={{ width: "inherit" }}>
                    <p>Sort by</p>
                    <Select
                        style={{ display: "flex" }}
                        placeholder={sortPlaceholder}
                        value={sortValue}
                        onChange={handleChangeSort}
                        options={sortOptions}
                    />
                </div>
            )}
        </FilterAreaStyled>
    );
};
