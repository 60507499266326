import React from "react";
import { ContentLayout } from "../../layouts/content-layout/content-layout.component";
import { PageContentContainer, PageContainer } from "../../styles/layouts";
import { useParams } from "react-router-dom";
import ContentHeader from "../../components/content-header/content-header.component";
import { useQuery } from "urql";
import { GET_ORGANIZATION } from "../../graphql/queries/organizations/organizations.query";
import { CodecardPageBreadcrumbs } from "../../constants/breadcrumbs";
import { Button } from "antd";
import { GET_CODECARD } from "../../graphql/queries/codecards/codecard.query";
import { useTranslation } from "react-i18next";
import { CodecardStatus } from "./codecard-status/codecard-status.component";
import { KeyValueLine } from "../../components/key-value-line/key-value-line";
import { CodecardPageStyled } from "./codecard-page.styled";
import { CodecardLink } from "./codecard-link/codecard-link";
import Loader from "../../components/loader/loader.component";
import { LoaderPageWrapper } from "../../styles/loader-wrapper.styled";
import { useOrganizationBreadcrumbs } from "../../hooks/useOrganizationBreadcrumbs";
import { MenuItemsEnum } from "../../components/menu-component/menu.component";
import { PageEnum } from "../../types/PagesEnum";
import { useRouteNavigation } from "../../hooks/useNavigationPatch";

const CodecardPageBody = (props: any) => {
    const { data, organizationId, codecardId } = props;
    const { t } = useTranslation();
    const { navigateToEditCodecard, navigateToCreateCodecard, navigateToLock } =
        useRouteNavigation();
    const { Codecard } = data || {};
    const { active, created_at: createdAt, id, keys } = Codecard || {};

    const handleNavigateToEditPage = () => {
        navigateToEditCodecard(organizationId, codecardId);
    };

    const handleNavigateToCreateCodecard = () => {
        navigateToCreateCodecard(organizationId, codecardId);
    };

    const handleNavigateToLockPage = (lockId: any) => {
        navigateToLock(organizationId, lockId);
    };

    const buttonsBlock = () => {
        return (
            <>
                <Button>Remove</Button>
                <Button onClick={handleNavigateToEditPage}>Edit</Button>
                <Button onClick={handleNavigateToCreateCodecard} type="primary">
                    {t("buttons.create_codecard")}
                </Button>
            </>
        );
    };

    return (
        <>
            <CodecardPageStyled>
                <ContentHeader
                    breadcrumbs={CodecardPageBreadcrumbs(organizationId, id)}
                    label={id}
                    buttonsBlock={buttonsBlock}
                />
                <div style={{ width: "100%" }}>
                    <KeyValueLine Key={<div>Name</div>} Value={<div>{id}</div>} />
                    <KeyValueLine Key={<div>Description</div>} Value={<div>{id}</div>} />
                    <KeyValueLine
                        Key={<div>Lock(s)</div>}
                        Value={
                            <div>
                                {Array.isArray(keys) && keys.length > 0
                                    ? keys.map(({ lock }: any, idx) => {
                                          const separator = keys.length - 1 > idx ? ", " : "";
                                          const displayName = lock?.label
                                              ? lock.label + separator
                                              : lock.type + separator;

                                          return (
                                              <span
                                                  style={{ cursor: "pointer", color: "#1890ff" }}
                                                  onClick={() => handleNavigateToLockPage(lock.id)}
                                              >
                                                  {displayName}
                                              </span>
                                          );
                                      })
                                    : null}
                            </div>
                        }
                    />

                    <CodecardStatus status={active} codecardId={id} />

                    <CodecardLink url={id} />
                    <KeyValueLine Key={<div>Logs</div>} Value={<div>{id}</div>} />
                    <KeyValueLine
                        Key={<div>Created by, at</div>}
                        Value={<div>{new Date(createdAt).toDateString()}</div>}
                    />
                    <KeyValueLine
                        Key={<div>Some other info..</div>}
                        Value={<div>Some other info..</div>}
                    />
                </div>
            </CodecardPageStyled>
        </>
    );
};

export const CodecardPage = () => {
    const { organizationId, codecardId } = useParams() as any;
    const numberOrganization = parseInt(organizationId);
    const { organizationBreadcrumbs } = useOrganizationBreadcrumbs(PageEnum.CODECARD);
    const [resultUnit] = useQuery({
        query: GET_ORGANIZATION,
        variables: { id: numberOrganization },
    });
    const { data: dataOrganization, fetching: fetchingOrganization } = resultUnit;

    const [resultCodecard] = useQuery({
        query: GET_CODECARD,
        variables: { id: codecardId },
    });
    const { data: dataCodecard, fetching: fetchingCodecard } = resultCodecard;

    if (fetchingOrganization || fetchingCodecard) {
        return (
            <LoaderPageWrapper>
                <Loader />
            </LoaderPageWrapper>
        );
    }

    return (
        <PageContainer>
            <PageContentContainer>
                <ContentLayout
                    // @ts-ignore
                    breadcrumbs={organizationBreadcrumbs}
                    label={dataOrganization?.Organisation.name}
                    openKey={MenuItemsEnum.Codecards}
                    isBackPath
                    ContentLayoutBody={
                        <CodecardPageBody
                            data={dataCodecard}
                            organizationId={organizationId}
                            codecardId={codecardId}
                        />
                    }
                />
            </PageContentContainer>
        </PageContainer>
    );
};
