import React, { useState, KeyboardEvent, useEffect, ReactElement } from "react";
import { BranchesOutlined, KeyOutlined, CrownOutlined, CheckOutlined } from "@ant-design/icons";

import { PageContainer, PageContentContainer } from "../../styles/layouts";
import { ContentLayout } from "../../layouts/content-layout/content-layout.component";

import {
    StackTopContainer,
    UserPageContainer,
    StackBottomContainer,
    PermissionContainer,
} from "./user-page.component.styled";
import { useQuery } from "urql";
import { GET_USER } from "../../graphql/queries/users/user.query";
import { UserInterface } from "../../types/User.interface";
import { renderToast } from "../../components/toast/toast";
import Loader from "../../components/loader/loader.component";
import { LoaderPageWrapper } from "../../styles/loader-wrapper.styled";

interface UserPageBodyProps {
    user?: UserInterface;
    edit: boolean;
    setEdit: (modalState: boolean) => void;
}

const UserPageBody = (props: UserPageBodyProps): ReactElement => {
    const { user, edit, setEdit } = props;
    const [email, setEmail] = useState(user?.email);

    const KeyboardKeys = {
        Enter: "Enter",
    };
    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === KeyboardKeys.Enter && email) {
            setEdit(!edit);
        }
    };

    return (
        <UserPageContainer isEdit={edit}>
            <StackTopContainer>{user?.name}</StackTopContainer>
            {user?.email && (
                <>
                    <input
                        disabled={!edit}
                        value={user?.email}
                        onKeyDown={handleKeyPress}
                        onChange={(event: any) => setEmail(event.target.value)}
                    />
                    &nbsp;
                    <CheckOutlined />
                </>
            )}

            <p>2FA Enabled</p>
            <StackBottomContainer>Bankid disabled</StackBottomContainer>
            <PermissionContainer>
                <span>Permissions:</span>
                <p>
                    Fjalen
                    <CrownOutlined style={{ marginLeft: "80px", fontSize: "25px" }} />
                </p>
                <p>
                    Hjemma
                    <BranchesOutlined style={{ marginLeft: "60px", fontSize: "25px" }} />
                    <KeyOutlined style={{ marginLeft: "15px", fontSize: "25px" }} />
                </p>
            </PermissionContainer>
        </UserPageContainer>
    );
};

export const UserPage = () => {
    const [editEmail, setEditEmail] = useState(false);

    // eslint-disable-next-line
    const handleChangeEmail = () => {
        setEditEmail(!editEmail);
    };

    const [result] = useQuery({
        query: GET_USER,
    });
    const { data, fetching, error } = result;

    useEffect(() => {
        if (error) {
            renderToast({
                type: "error",
                message: error?.message,
                description: error?.message,
            });
        }
    }, [error]);

    if (fetching) {
        return (
            <LoaderPageWrapper>
                <Loader />
            </LoaderPageWrapper>
        );
    }

    return (
        <PageContainer>
            <PageContentContainer>
                <ContentLayout
                    isMenu={false}
                    ContentLayoutBody={
                        <UserPageBody user={data?.User} setEdit={setEditEmail} edit={editEmail} />
                    }
                    isBackPath
                    label={data?.User.name}
                    isBreadcrumbExists={false}
                />
            </PageContentContainer>
        </PageContainer>
    );
};
