import React, { ReactElement, useEffect, useState } from "react";

import { EditCodecardForm } from "./edit-codecard-form";
import {
    EditButtonsBlock,
    EditCodecardContentStyled,
    EditCodecardWrap,
} from "./edit-codecard.styled";
import { Button } from "antd";
import { useMutation, useQuery } from "urql";
import { UPDATE_CODECARD } from "../../../graphql/mutations/codecard/codecard.mutations";
import { GET_CODECARD } from "../../../graphql/queries/codecards/codecard.query";
import { useTranslation } from "react-i18next";
import { renderToast } from "../../../components/toast/toast";
import { initialEditCodecardFormValues } from "../../../constants/codecard";
import Loader from "../../../components/loader/loader.component";
import { EditCodecardTypes } from "./edit-codecard-types";
import { LoaderContentWrapper } from "../../../styles/loader-wrapper.styled";
import { useRouteNavigation } from "../../../hooks/useNavigationPatch";

interface EditCodecardProps {
    organizationId: string;
    codecardId: string;
}

export const EditCodecard = (props: EditCodecardProps): ReactElement => {
    const { organizationId, codecardId } = props;
    const [codecard, editCodecard] = useMutation(UPDATE_CODECARD);
    const [formValues, setFormValues] = useState<EditCodecardTypes>(initialEditCodecardFormValues);
    const { navigateToCodecard } = useRouteNavigation();
    const { t } = useTranslation();

    const handleChangeDate = (key: string) => (_: any, dateStrings: string) => {
        setFormValues({
            ...formValues,
            [key]: new Date(dateStrings),
        });
    };

    const handleChangeIsActive = (value: boolean) => {
        setFormValues({
            ...formValues,
            isActive: value,
        });
    };

    const handleCancel = () => {
        setFormValues(initialEditCodecardFormValues);
    };

    const handleSubmitEditCodecardForm = async () => {
        if (formValues) {
            await editCodecard({
                id: codecardId,
                validFrom: formValues.validFrom,
                validTo: formValues.validTo,
                active: formValues.isActive,
            });
        }
    };

    const [resultCodecard] = useQuery({
        query: GET_CODECARD,
        variables: {
            id: codecardId,
        },
    });

    const { data, fetching } = resultCodecard;

    const currentStatus = data?.Codecard?.active;

    useEffect(() => {
        if (data?.Codecard) {
            setFormValues({
                ...formValues,
                validFrom: data.Codecard?.valid_from,
                validTo: data.Codecard?.valid_to,
                isActive: data.Codecard?.active,
            });
        }
    }, [data]);

    useEffect(() => {
        if (codecard?.data?.UpdateCodecard?.id) {
            navigateToCodecard(organizationId, codecardId);
        }
    }, [codecard]);

    useEffect(() => {
        if (!codecard.error && codecard?.data) {
            renderToast({
                type: "success",
                message: "Success",
            });
        }
    }, [codecard?.error, codecard?.data]);
    if (fetching) {
        return (
            <LoaderContentWrapper>
                <Loader />
            </LoaderContentWrapper>
        );
    }

    const isSaveDisabled = !formValues.validTo || !formValues.validFrom ? true : false;

    return (
        <>
            <EditCodecardContentStyled>
                <EditCodecardWrap>
                    <EditCodecardForm
                        currentStatus={currentStatus}
                        formValues={formValues}
                        onChangeDate={handleChangeDate}
                        onChangeActive={handleChangeIsActive}
                    />
                </EditCodecardWrap>
                <EditButtonsBlock>
                    <Button onClick={handleCancel}>{t("buttons.cancel")}</Button>
                    <Button
                        disabled={isSaveDisabled}
                        type="primary"
                        onClick={handleSubmitEditCodecardForm}
                    >
                        Save
                    </Button>
                </EditButtonsBlock>
            </EditCodecardContentStyled>
        </>
    );
};
