import { SupportedDevicesEnum } from "../../types/SupportedDevicesEnum";

export interface LogoAdaptiveConfigInterface {
    logoImg: {
        width: number;
        height: number;
    };
}

export const defaultLogoAdaptiveConfig: LogoAdaptiveConfigInterface = {
    logoImg: {
        width: 32,
        height: 32,
    },
};

export const logoAdaptiveConfig: Record<SupportedDevicesEnum, LogoAdaptiveConfigInterface> = {
    [SupportedDevicesEnum.DESKTOP]: {
        logoImg: {
            width: 32,
            height: 32,
        },
    },
    [SupportedDevicesEnum.TABLET]: {
        logoImg: {
            width: 32,
            height: 32,
        },
    },
    [SupportedDevicesEnum.MOBILE]: {
        logoImg: {
            width: 32,
            height: 32,
        },
    },
};
