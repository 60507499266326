export const GET_ORGANIZATIONS = `
  query { 
      Organisations {
        id,
        name,
        street,
        zip,
        city,
        country,
        org_number,
        phone,
        locks {
            id,
            label,
            battery_change},
        users { 
          id,
          name,
          email
        },
        parent {
          id
        },
        locks {
          id
        },
        users {
          id
        }
      }   
  }
`;

export const GET_ORGANIZATION = `
  query($id: Int!)  { 
      Organisation (id: $id) {
        id,
        name,
        street,
        zip,
        city,
        country,
        org_number,
        phone,
        codecards {
        id,
        active,
        valid_from,
        valid_to,
        created_at,
        updated_at
        }
        locks {
            id,
            label,
            battery_change},
        users { 
          id,
          name,
          email
        },
        parent {
          id
        },
        locks {
          id
        },
        users {
          id
        }

      }   
  }
`;
