import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider as UrqlProvider } from "urql";

import { urqlClient } from "./config/urqlConfig";

import App from "./App";

import "antd/dist/antd.css";
import "./index.scss";

const basename = process?.env?.REACT_APP_BASENAME ?? "/";

ReactDOM.render(
    <React.StrictMode>
        <UrqlProvider value={urqlClient}>
            <BrowserRouter basename={basename}>
                <App />
            </BrowserRouter>
        </UrqlProvider>
    </React.StrictMode>,
    document.getElementById("root"),
);
