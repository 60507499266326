import React, { ReactElement, useEffect, useState } from "react";

import { EditLockForm } from "./edit-lock-form";
import { EditLockButtonsBlock, EditLockContentStyled, EditLockWrap } from "./edit-lock.styled";
import { Button } from "antd";
import { useMutation, useQuery } from "urql";
import { useTranslation } from "react-i18next";
import { useRouteNavigation } from "../../../hooks/useNavigationPatch";
import { renderToast } from "../../../components/toast/toast";
import { UPDATE_LOCK } from "../../../graphql/mutations/lock/locks.mutation";
import { initialEditLockFormValues } from "../../../constants/lock";
import Loader from "../../../components/loader/loader.component";
import { EditLockInterface } from "./edit-lock.types";
import { LoaderContentWrapper } from "../../../styles/loader-wrapper.styled";
import { GET_LOCK } from "../../../graphql/queries/locks/lock.query";

interface EditLockProps {
    organizationId: string;
    lockId: string;
}

export const EditLock = (props: EditLockProps): ReactElement => {
    const { organizationId, lockId } = props;
    const [result, editLock] = useMutation(UPDATE_LOCK);
    const [formValues, setFormValues] = useState<EditLockInterface>(initialEditLockFormValues);
    const { navigateToLock } = useRouteNavigation();
    const { t } = useTranslation();

    const numberLockId = parseInt(lockId as string);

    const [resultLock] = useQuery({
        query: GET_LOCK,
        variables: { id: numberLockId },
    });
    const { data: dataLock, error: errorLock, fetching: fetchingLock } = resultLock || {};

    useEffect(() => {
        if (dataLock?.Lock?.id) {
            setFormValues({
                ...formValues,
                label: dataLock.Lock?.serial_number,
                batteryChange: dataLock.Lock?.battery_change,
            });
        }
    }, [dataLock]);

    const handleChangeTimezone = (value: any) => {
        setFormValues({
            ...formValues,
            timezone: value,
        });
    };

    const handleChangeDate = (_: any, dateStrings: string) => {
        setFormValues({
            ...formValues,
            batteryChange: new Date(dateStrings),
        });
    };

    const handleChangeLabel = (e: any) => {
        setFormValues({
            ...formValues,
            label: e.target.value,
        });
    };

    const handleChangeNotes = (e: any) => {
        setFormValues({
            ...formValues,
            notes: e.target.value,
        });
    };

    const handleCancel = () => {
        setFormValues(initialEditLockFormValues);
    };

    const handleSubmitEditLockForm = async () => {
        if (formValues) {
            await editLock({
                id: lockId,
                organisationId: organizationId,
                // @ts-ignore
                batteryChange: formValues.batteryChange,
                label: formValues.label,
                timezone: formValues.timezone,
                notes: formValues.notes,
            });
        }
    };

    useEffect(() => {
        if (result?.data?.UpdateLock?.id) {
            navigateToLock(organizationId, result.data.UpdateLock.id);
        }
    }, [result]);

    useEffect(() => {
        if (!result.error && result?.data) {
            renderToast({
                type: "success",
                message: "Success",
            });
        } else if (result.error || errorLock) {
            renderToast({
                type: "error",
                message: result.error?.message || errorLock?.message,
            });
        }
    }, [result?.error, result?.data]);

    const disabledSaveButton = !formValues.label || !formValues.batteryChange ? true : false;

    if (result.fetching || fetchingLock) {
        return (
            <LoaderContentWrapper>
                <Loader />
            </LoaderContentWrapper>
        );
    }

    return (
        <>
            <EditLockContentStyled>
                <EditLockWrap>
                    <EditLockForm
                        formValues={formValues}
                        onFormChangeLabel={handleChangeLabel}
                        onFormChangeTimezone={handleChangeTimezone}
                        onFormChangeDate={handleChangeDate}
                        onFormChangeNotes={handleChangeNotes}
                    />
                </EditLockWrap>
                <EditLockButtonsBlock>
                    <Button onClick={handleCancel}>{t("buttons.cancel")}</Button>
                    <Button
                        disabled={disabledSaveButton}
                        type="primary"
                        onClick={handleSubmitEditLockForm}
                    >
                        Save
                    </Button>
                </EditLockButtonsBlock>
            </EditLockContentStyled>
        </>
    );
};
