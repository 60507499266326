import React, { ReactElement } from "react";
import { DatePicker } from "antd";

interface CustomDatePickerProps {
    handleChange: any;
    startDate?: any;
    isDisabled?: boolean;
    placeholder?: any;
}

export const CustomDatePicker = (props: CustomDatePickerProps): ReactElement => {
    const { handleChange, startDate, isDisabled, placeholder } = props;

    const disabledDate = (current: any) => {
        const now = new Date();
        now.setDate(now.getDate() - 1);
        const unavailableDates = startDate && startDate > now ? startDate - 1 : now;

        return current && unavailableDates > current;
    };

    return (
        <>
            {/* @ts-ignore */}
            <DatePicker
                placeholder={placeholder ? `${new Date(placeholder)}` : "Select date"}
                format="YYYY-MM-DD HH:mm:ss"
                disabledDate={disabledDate}
                onChange={handleChange}
                style={{ width: "100%" }}
                disabled={isDisabled}
            />
        </>
    );
};
