import {
    OrganisationInterface,
    OrganisationWithUnitsInterface,
} from "../types/Organisation.interface";

export const convertOrgsToOrgsWithUnits = (organisations: OrganisationInterface[]): any => {
    const createFirsOrganisationLayer = (acc: {}, organisation: OrganisationInterface): {} => {
        const { id, parent } = organisation;

        if (!parent) {
            return { ...acc, [id]: { ...organisation, units: [] } };
        }

        return acc;
    };

    const createOrganisationWithUnits = (
        acc: any,
        organisation: OrganisationWithUnitsInterface,
    ): any => {
        const { parent } = organisation;

        if (!parent) {
            return acc;
        }

        if (parent) {
            const { id: organisationId } = parent;
            const org = acc[organisationId];

            return {
                ...acc,
                [organisationId]: { ...org, units: [organisation] },
            };
        }

        return acc;
    };

    const firstOrganisationLayer = organisations.reduce(createFirsOrganisationLayer, {});
    const organisationWithUnits = organisations.reduce(
        createOrganisationWithUnits,
        firstOrganisationLayer,
    );

    return Object.values(organisationWithUnits);
};

export const flatOrgsToTree = (orgs: any): any => {
    if (!orgs) {
        return [];
    }

    const map = {};
    let node;
    const roots = [];

    for (let i = 0; i < orgs.length; i += 1) {
        //@ts-ignore
        map[orgs[i].id] = i;

        orgs[i].children = [];
        orgs[i].allChildrenIds = [];
    }

    for (let i = 0; i < orgs.length; i += 1) {
        node = orgs[i];

        if (node.parent !== null) {
            //@ts-ignore
            const mapId = map[node.parent.id];

            orgs[mapId].children.push(node);
        } else {
            roots.push(node);
        }
    }

    return roots;
};
