import { SupportedDevicesEnum } from "../types/SupportedDevicesEnum";

const { DESKTOP, TABLET, MOBILE } = SupportedDevicesEnum;

interface ScreenSizeInterface {
    min: number;
    max: number;
}

interface ScreenSizeConfigInterface {
    [DESKTOP]: ScreenSizeInterface;
    [TABLET]: ScreenSizeInterface;
    [MOBILE]: ScreenSizeInterface;
}

const DESKTOP_MIN_SCREEN_SIZE_PX = 992;
const DESKTOP_MAX_SCREEN_SIZE_PX = 3000;

const TABLET_MIN_SCREEN_SIZE_PX = 576;
const TABLET_MAX_SCREEN_SIZE_PX = 991;

const MOBILE_MIN_SCREEN_SIZE_PX = 0;
const MOBILE_MAX_SCREEN_SIZE_PX = 575;

const getScreenSizeStructure = (min: number, max: number): ScreenSizeInterface => {
    return { min, max };
};

export const getScreenSizesConfig = (): ScreenSizeConfigInterface => {
    return {
        [DESKTOP]: getScreenSizeStructure(DESKTOP_MIN_SCREEN_SIZE_PX, DESKTOP_MAX_SCREEN_SIZE_PX),
        [TABLET]: getScreenSizeStructure(TABLET_MIN_SCREEN_SIZE_PX, TABLET_MAX_SCREEN_SIZE_PX),
        [MOBILE]: getScreenSizeStructure(MOBILE_MIN_SCREEN_SIZE_PX, MOBILE_MAX_SCREEN_SIZE_PX),
    };
};
