export const CREATE_LOCK = `
    mutation ($serial: String!, $orgID: Int!){
      RegisterLock(serial: $serial, orgID: $orgID)
      {
        id
      }
    }
`;

export const UPDATE_LOCK = `
    mutation(
        $id: Int!,
        $batteryChange: String,
        $organisationId: Int,
        $label: String,
        $timezone: String,
        $notes: String,
         ){
        UpdateLock(
            id: $id, 
            changes:{
                battery_change: $batteryChange,
                organisation_id: $organisationId,
                label: $label,
                timezone:  $timezone,
                notes:  $notes
            }
          ){
            id,
            battery_change,
            label,
            timezone,
            notes,
          }
    }

`;

export const REMOVE_LOCK = `
    mutation ($id: Int!){
      RemoveLock(id: $id)
      { id }
    }
`;
