import { Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { setLanguageToLocalStorage } from "../../../utils/LocalStorage";
import { LanguageDropdownStyled } from "./language-dropdown.styled";

const langs = [
    { label: "EN", id: 1, value: "en" },
    { label: "SV", id: 2, value: "sv" },
];

export const LanguageDropdown = () => {
    const { i18n } = useTranslation();

    const handleChangeLanguage = (language: string) => {
        setLanguageToLocalStorage(language);

        i18n.changeLanguage(language);
    };

    return (
        <LanguageDropdownStyled style={{ float: "right", marginLeft: "15px" }}>
            <Select
                className={"sc-iCfMLu"}
                style={{ width: 80 }}
                defaultValue={i18n.language}
                onChange={handleChangeLanguage}
                options={langs}
            />
        </LanguageDropdownStyled>
    );
};
