import styled from "styled-components";

export const LabelContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    span {
        font-size: 16px;
    }
`;

export const RequiredIconContainer = styled.div`
    position: absolute;
    color: #f5222d;
    top: 8px;
    left: -10px;
    font-size: 16px;
`;
