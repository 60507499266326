import { BaseDropdownInterface } from "../types/Dropdown.interface";

export const mapLocksToDropdownOption = <T extends BaseDropdownInterface>(
    value: T,
): BaseDropdownInterface => {
    return {
        id: value.id,
        label: value.label ? value.label : value.type ? value.type : "error name",
        value: value.id,
    };
};
