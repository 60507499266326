export const CREATE_CODECARD = `
  mutation (
      $organisation_id: Int!,
      $active: Boolean!,
      $valid_from: String!,
      $valid_to: String,
  ) {
      CreateCodecard(create: {
        organisation_id: $organisation_id,
        active: $active
        valid_from: $valid_from,
        valid_to: $valid_to
      }) {
        id,
        organisation_id {id}
        active,
        valid_from,
        valid_to,
        created_by {id}
        created_at,
        updated_at,
        updated_by{id}
      }
    }
`;

export const UPDATE_STATUS_CODECARD = `
  mutation($id:String!, $active:Boolean){
    UpdateCodecard(
      id:$id, 
      changes:{active:$active}
      ){
        id, active
        }
    }
`;

export const UPDATE_CODECARD = `
  mutation(
    $id:String!,
    $active:Boolean,
    $validTo:String, 
    $validFrom:String
    ){
    UpdateCodecard(
      id:$id, 
      changes:{
        active:$active,
        valid_to:$validTo,
        valid_from:$validFrom
      }
     ){
        id,
        valid_to,
        valid_from
     }
   }
`;
