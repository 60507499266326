export const GET_USERS = `
  query ($organisation_id: Int!) { 
    Users(organisation_id: $organisation_id) {
        id,
        name,
        email,
        staff,
        language,
        created_at,
        updated_at
    }
  }
`;

export const GET_USERS_ID = `
  query ($organisation_id: Int!) { 
    Users (organisation_id: $organisation_id)
    { id }
  }
`;
