import React, { ReactElement } from "react";
import { Menu } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
    getCodecardsPath,
    getLocksPath,
    getMessagePath,
    getNotificationsPath,
    getSettingsPath,
    getSubunitsPath,
    getUsersPath,
} from "../../routes/paths/dynamic-paths";
import { menuItems } from "./menu.constants";
import { RouteParamsInterface } from "../../types/RouteParams.interface";
import { useRouteNavigation } from "../../hooks/useNavigationPatch";

export enum MenuItemsEnum {
    Details = "details",
    Locks = "locks",
    Codecards = "codecards",
    Users = "users",
    Message = "message",
    Subunits = "subunits",
    Notifications = "notifications",
    Settings = "settings",
}

interface MenuComponentProps {
    openKey?: MenuItemsEnum;
}

export const MenuComponent = (props: MenuComponentProps): ReactElement => {
    const { openKey } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { organizationId } = useParams() as RouteParamsInterface;

    const { navigateToDetails } = useRouteNavigation();

    const handleNavigations = (key: string) => {
        if (key === MenuItemsEnum.Details) {
            navigateToDetails(organizationId);
        }

        if (key === MenuItemsEnum.Locks) {
            navigate(getLocksPath(organizationId));
        }

        if (key === MenuItemsEnum.Codecards) {
            navigate(getCodecardsPath(organizationId));
        }

        if (key === MenuItemsEnum.Users) {
            navigate(getUsersPath(organizationId));
        }

        if (key === MenuItemsEnum.Message) {
            navigate(getMessagePath(organizationId));
        }

        if (key === MenuItemsEnum.Subunits) {
            navigate(getSubunitsPath(organizationId));
        }

        if (key === MenuItemsEnum.Notifications) {
            navigate(getNotificationsPath(organizationId));
        }

        if (key === MenuItemsEnum.Settings) {
            navigate(getSettingsPath(organizationId));
        }
    };
    const onMenuClick = (event: any) => {
        handleNavigations(event.key);
    };

    return (
        <Menu
            style={{ width: 256, boxShadow: "inset 0 1px 5px #f0f0f0" }}
            defaultSelectedKeys={[`${openKey}`]}
            mode={"inline"}
            theme={"light"}
            items={menuItems.map((value) => ({
                ...value,
                label: t(value.label),
            }))}
            onClick={onMenuClick}
        />
    );
};
