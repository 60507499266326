import styled from "styled-components";

interface StandardButtonStyledProps {
    width?: number;
    height?: number;
}

export const StandardButtonStyled = styled.button<StandardButtonStyledProps>`
    background: black;
    color: white;
    gap: 12px;
    border: 1px solid gray;
    cursor: pointer;
    border-radius: 3px;
    width: ${({ width = 100 }) => width + "px"};
    height: ${({ height = 30 }) => height + "px"}; ;
`;
