import { Input, PageHeader } from "antd";
import { observer } from "mobx-react-lite";
import { FunctionComponent, ReactElement } from "react";

import { useTranslation } from "react-i18next";
import { HistoryBackButton } from "../../layouts/content-layout/content-layout-header.component";
import { ApplicationStore } from "../../stores/applicationStore";
import { Stores, useStore } from "../../stores/store";
import { OrganisationInterface } from "../../types/Organisation.interface";
import { SupportedDevicesEnum } from "../../types/SupportedDevicesEnum";
import { CustomBreadcrumbs } from "../breadcrumbs/bredcrambs.component";
import { ContentHeaderStyled } from "../content-header/content-header.styled";
import { NavigationSubMenu } from "./navigation-sub-menu";
import { NavigationContainer, SideNavigationContainer, BtnsBlock } from "./navigation.styled";
import { Button } from "antd";
import { useRouteNavigation } from "../../hooks/useNavigationPatch";

interface NavigationProps {
    deviceType?: SupportedDevicesEnum;
    organisations: OrganisationInterface[];
    selectedItem: number;
    onChangeSelectedItem: (value: number) => void;
}

const NavigationMenu = observer((props: any): ReactElement => {
    const { deviceType, organisations, selectedItem, onChangeSelectedItem } = props;

    const { isMobileNavigationOpen, organizationBreadcrumbs, pageLabel, extraHeaderButton } =
        useStore(Stores.APPLICATION_STORE) as ApplicationStore;

    const { t } = useTranslation();

    const { navigateToCreateSubUnit } = useRouteNavigation();

    const isMobile = deviceType !== SupportedDevicesEnum.DESKTOP;

    const handleNavigateToCreateSubUnit = () => {
        navigateToCreateSubUnit();
    };

    return (
        <SideNavigationContainer isMobile={isMobile}>
            <NavigationContainer isMobile={isMobile} isMenuOpen={isMobileNavigationOpen}>
                {isMobile && (
                    <ContentHeaderStyled isMenuOpen={isMobile && isMobileNavigationOpen}>
                        <PageHeader
                            title={<HistoryBackButton label={pageLabel} isBackPath={true} />}
                            className="site-page-header"
                            extra={extraHeaderButton}
                            breadcrumbRender={() => (
                                <CustomBreadcrumbs
                                    breadcrumbs={organizationBreadcrumbs}
                                    isLightColor={isMobile && isMobileNavigationOpen}
                                />
                            )}
                        />
                    </ContentHeaderStyled>
                )}

                <Input placeholder={t`inputs.search`} />

                <div
                    style={{
                        position: "relative",
                    }}
                >
                    <NavigationSubMenu
                        organisations={organisations}
                        selectedItem={selectedItem}
                        onSelectedItem={onChangeSelectedItem}
                        isMobile={isMobile}
                    />
                </div>

                <BtnsBlock>
                    <Button
                        style={{
                            width: "100%",
                        }}
                        type="primary"
                        ghost
                        onClick={handleNavigateToCreateSubUnit}
                    >
                        + Create unit
                    </Button>
                </BtnsBlock>
            </NavigationContainer>
        </SideNavigationContainer>
    );
});

// TODO Move to config
export const navigationAdaptiveConfig: Record<SupportedDevicesEnum, FunctionComponent> = {
    [SupportedDevicesEnum.DESKTOP]: NavigationMenu,
    [SupportedDevicesEnum.TABLET]: NavigationMenu,
    [SupportedDevicesEnum.MOBILE]: NavigationMenu,
};

export const Navigation = (props: NavigationProps): ReactElement => {
    const { deviceType, organisations, selectedItem, onChangeSelectedItem } = props;

    const AdaptiveNavigation = navigationAdaptiveConfig[deviceType as SupportedDevicesEnum];

    return (
        <AdaptiveNavigation
            // @ts-ignore
            deviceType={deviceType}
            organisations={organisations}
            selectedItem={selectedItem}
            onChangeSelectedItem={onChangeSelectedItem}
        />
    );
};
