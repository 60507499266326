import styled from "styled-components";

export const FilterAreaStyled = styled.div`
    box-shadow: inset 0 0 5px #f0f0f0;
    width: 100%;
    display: flex;
    padding: 18px 25px;
    justify-content: space-around;

    p {
        margin-bottom: 10px;
    }
`;
