import { Input } from "antd";
import React, { ReactElement, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { CountriesSelect } from "../../../../components/countries-select/countries-select";
import { DetailsLocationFieldsEnum, DetailsLocationInterface } from "./details-location.types";

interface DetailsLocationFormProps {
    currentLocation: DetailsLocationInterface;
    onChangeLocation: (key: string) => (e: SyntheticEvent) => void;
    onChangeCountry: (value: string) => void;
}

export const DetailsLocationForm = (props: DetailsLocationFormProps): ReactElement => {
    const { currentLocation, onChangeLocation, onChangeCountry } = props;

    const { t } = useTranslation();

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <CountriesSelect
                placeholder={t("details_page.country")}
                value={currentLocation?.country}
                onChange={onChangeCountry}
                style={{ maxWidth: 300, marginBottom: 5 }}
            />
            <Input
                placeholder={t("details_page.city")}
                value={currentLocation?.city}
                onChange={onChangeLocation(DetailsLocationFieldsEnum.city)}
                style={{ maxWidth: 300, marginBottom: 5 }}
            />
            <Input
                placeholder={t("details_page.zip")}
                value={currentLocation?.zip}
                onChange={onChangeLocation(DetailsLocationFieldsEnum.zip)}
                style={{ maxWidth: 300, marginBottom: 5 }}
            />
            <Input
                placeholder={t("details_page.street")}
                value={currentLocation?.street}
                onChange={onChangeLocation(DetailsLocationFieldsEnum.street)}
                style={{ maxWidth: 300, marginBottom: 5 }}
            />
        </div>
    );
};
