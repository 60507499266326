import { ReactElement } from "react";

export enum MenuItemsEnum {
    Details = "details",
    Locks = "locks",
    Codecards = "codecards",
    Users = "users",
    Message = "message",
    Subunits = "subunits",
    Notifications = "notifications",
    Settings = "settings",
}

export interface MenuItemInterface {
    label: string;
    key: MenuItemsEnum;
    icon: ReactElement;
}
