export const GET_LOCKS = `
    query ($organisation_id: Int!) {
        Locks (organisation_id: $organisation_id)  {
            id,
            type,
            label,
            serial_number,
            timezone,
            notes,
            battery_change         
        }
    }
`;

export const GET_LOCKS_ASC_BY_LABEL = `
    query ($organisation_id: Int!, ) {
        Locks (
            organisation_id: $organisation_id,
            sort: { 
                order: asc, 
                col: label
                
            }
        )  {
            id,
            type,
            label,
            serial_number,
            timezone,
            notes,
            battery_change         
        }
    }
`;

export const GET_LOCKS_DESC_BY_LABEL = `
    query ($organisation_id: Int!) {
        Locks (
            organisation_id: $organisation_id,
            sort: { 
                order: desc, 
                col: label
            }
        )  {
            id,
            type,
            label,
            serial_number,
            timezone,
            notes,
            battery_change         
        }
    }
`;
