import { useNavigate } from "react-router-dom";
import {
    getAddLockPath,
    getAddUserPath,
    getCodecardEditPath,
    getCodecardPath,
    getCodecardSendPath,
    getCodecardsLockPath,
    getCodecardsPath,
    getCreateCodecardPath,
    getCreateCodecardsPath,
    getDetailsPath,
    getDynamicBaseRoute,
    getEditLockPath,
    getInfoLockPath,
    getLocksPath,
    getMessagePath,
    getNotificationsPath,
    getSettingsPath,
    getSubunitsPath,
    getUsersPath,
    getOrganizationUserPath,
    getCreateSubUnitPath,
} from "../routes/paths/dynamic-paths";
import { UseRouteNavigationInterface } from "../types/hooks-types/UseRouteNavigate.interface";

export const useRouteNavigation = (): UseRouteNavigationInterface => {
    const navigate = useNavigate();

    const navigateToOrganisationRoot = (organizationId: string) => {
        const path = getDynamicBaseRoute(organizationId);
        navigate(path);
    };

    const navigateToCreateSubUnit = (): void => {
        const path = getCreateSubUnitPath();
        navigate(path);
    };

    const navigateToDetails = (organizationId: string) => {
        const path = getDetailsPath(organizationId);
        navigate(path);
    };

    const navigateToLocks = (organizationId: string): void => {
        const path = getLocksPath(organizationId);
        navigate(path);
    };

    const navigateToLock = (organizationId: string, lockId: string): void => {
        const path = getInfoLockPath(organizationId, lockId);
        navigate(path);
    };

    const navigateToEditLock = (organizationId: string, lockId: string): void => {
        const path = getEditLockPath(organizationId, lockId);
        navigate(path);
    };

    const navigateToCreateLock = (organizationId: string): void => {
        const path = getAddLockPath(organizationId);
        navigate(path);
    };

    const navigateToCodecards = (organizationId: string): void => {
        const path = getCodecardsPath(organizationId);
        navigate(path);
    };
    const navigateToCodecardsLock = (organizationId: string, lockId: string): void => {
        const path = getCodecardsLockPath(organizationId, lockId);
        navigate(path);
    };

    const navigateToCodecard = (organizationId: string, codecardId: string): void => {
        const path = getCodecardPath(organizationId, codecardId);
        navigate(path);
    };

    const navigateToEditCodecard = (organizationId: string, codecardId: string): void => {
        const path = getCodecardEditPath(organizationId, codecardId);
        navigate(path);
    };

    const navigateToCreateCodecards = (organizationId: string): void => {
        const path = getCreateCodecardsPath(organizationId);
        navigate(path);
    };

    const navigateToCreateCodecard = (organizationId: string, codecardId: string): void => {
        const path = getCreateCodecardPath(organizationId, codecardId);
        navigate(path);
    };

    const navigateToSendCodecard = (organizationId: string, codecardId: string): void => {
        const path = getCodecardSendPath(organizationId, codecardId);
        navigate(path);
    };

    const navigateToMessages = (organizationId: string): void => {
        const path = getMessagePath(organizationId);
        navigate(path);
    };

    const navigateToNotifications = (organizationId: string): void => {
        const path = getNotificationsPath(organizationId);
        navigate(path);
    };

    const navigateToSettings = (organizationId: string): void => {
        const path = getSettingsPath(organizationId);
        navigate(path);
    };

    const navigateToSubunits = (organizationId: string): void => {
        const path = getSubunitsPath(organizationId);
        navigate(path);
    };

    const navigateToUsers = (organizationId: string): void => {
        const path = getUsersPath(organizationId);
        navigate(path);
    };

    const navigateToCreateUser = (organizationId: string): void => {
        const path = getAddUserPath(organizationId);
        navigate(path);
    };

    const navigateToOrganizationUser = (organizationId: string, userId: number): void => {
        const path = getOrganizationUserPath(organizationId, userId);
        navigate(path);
    };

    return {
        navigateToLocks,
        navigateToLock,
        navigateToEditLock,
        navigateToCreateLock,
        navigateToCodecards,
        navigateToCodecardsLock,
        navigateToCodecard,
        navigateToEditCodecard,
        navigateToCreateCodecards,
        navigateToCreateCodecard,
        navigateToSendCodecard,
        navigateToOrganisationRoot,
        navigateToDetails,
        navigateToMessages,
        navigateToNotifications,
        navigateToSettings,
        navigateToSubunits,
        navigateToUsers,
        navigateToCreateUser,
        navigateToOrganizationUser,
        navigateToCreateSubUnit,
    };
};
