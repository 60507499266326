import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { ReactElement, useEffect, useState } from "react";
import { useRouteNavigation } from "../../hooks/useNavigationPatch";
import { ApplicationStore } from "../../stores/applicationStore";
import { Stores, useStore } from "../../stores/store";
import { NavigationSubMenu, NavigationSubMenuProps } from "./navigation-sub-menu";
import { MenuNavigationItemStyled } from "./navigation.styled";
import { isIdInChildren } from "./navigation.utils";

interface ListItemProps extends NavigationSubMenuProps {
    item: any;
}

export const NavigationListItem = observer((props: ListItemProps): ReactElement => {
    const { item, layer, onSelectedItem, selectedItem, isMobile } = props;
    const isNestedExists = Boolean(item?.children.length);
    const [isExpand, setIsExpand] = useState(false);
    const { navigateToOrganisationRoot, navigateToDetails } = useRouteNavigation();
    const { setIsMobileNavigationOpen } = useStore(Stores.APPLICATION_STORE) as ApplicationStore;

    const isSelected = selectedItem === item.id;

    useEffect(() => {
        const isChildExpanded = isIdInChildren(item, selectedItem);

        setIsExpand(isChildExpanded);
    }, []);

    const handleMenuItemClick = (): void => {
        if (isMobile) {
            navigateToOrganisationRoot(item.id);
            setIsMobileNavigationOpen(false);

            return;
        }

        navigateToDetails(item.id);
    };

    return (
        <>
            <MenuNavigationItemStyled
                key={item.id}
                // @ts-ignore
                layer={layer}
                isSelected={isSelected}
            >
                {isNestedExists ? (
                    <span
                        onClick={() => setIsExpand(!isExpand)}
                        style={{
                            width: "25px",
                        }}
                    >
                        {isExpand ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
                    </span>
                ) : (
                    <span
                        style={{
                            marginRight: "25px",
                        }}
                    />
                )}

                <span onClick={handleMenuItemClick}>{item.name}</span>
            </MenuNavigationItemStyled>

            {isNestedExists && (
                <div style={{ display: `${isExpand ? "block" : "none"}` }}>
                    <NavigationSubMenu
                        // @ts-ignore
                        organisations={item.children}
                        layer={layer! + 1}
                        onSelectedItem={onSelectedItem}
                        selectedItem={selectedItem}
                        isMobile={isMobile}
                    />
                </div>
            )}
        </>
    );
});
