import React from "react";
import { CreateCodecardBtns, CreateCodecardContentStyled } from "./create-codecard.styled";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { CreateCodecardForm } from "./create-codecard-form";
import { useCreateCodecardForm } from "./useCreateCodecardForm";
import { useCreateCodecardFormValidation } from "./useCreateCodeCardFormValidation";

interface CreateCodecardProps {}

export const CreateCodecard: React.FC<CreateCodecardProps> = () => {
    const { t } = useTranslation();

    const {
        formValues,
        handleChangeDate,
        handleChangeValue,
        handleFormChangeTargetValue,
        handleSubmitCreateCodecardForm,
    } = useCreateCodecardForm();

    const { validatedFields, isFormValid } = useCreateCodecardFormValidation(formValues);

    return (
        <>
            <CreateCodecardContentStyled>
                <CreateCodecardForm
                    onFormChange={handleFormChangeTargetValue}
                    onChangeDate={handleChangeDate}
                    onChangeValue={handleChangeValue}
                    formValues={formValues}
                    validatedFields={validatedFields}
                />

                <CreateCodecardBtns>
                    <Button>{t("buttons.cancel")}</Button>
                    <Button
                        onClick={handleSubmitCreateCodecardForm}
                        type="primary"
                        disabled={!isFormValid}
                    >
                        {t("buttons.create")}
                    </Button>
                </CreateCodecardBtns>
            </CreateCodecardContentStyled>
        </>
    );
};
