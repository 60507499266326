import React from "react";
import { TopBarContainer } from "../../layouts/main-layout/main-layout.styled";
import { withAdaptiveContainer, WithAdaptiveProps } from "../../HOC/withAdaptiveContainer";
import { TelkeyLogo } from "../telkey-logo/telkey-logo.component";
import { topNavigationBarAdaptiveConfig } from "./top-navigation-bar-adaptive.config";
import { SupportedDevicesEnum } from "../../types/SupportedDevicesEnum";

interface TopNavigationBarProps extends WithAdaptiveProps {}

const TopNavigationBar = (props: TopNavigationBarProps) => {
    const { deviceType } = props;

    const TopNavigationBarAdaptiveContent =
        topNavigationBarAdaptiveConfig[deviceType as SupportedDevicesEnum];

    return (
        <TopBarContainer>
            <TelkeyLogo deviceType={deviceType} />

            <TopNavigationBarAdaptiveContent />
        </TopBarContainer>
    );
};

const TopNavigationBarAdaptiveContainer = withAdaptiveContainer(TopNavigationBar);

export { TopNavigationBarAdaptiveContainer };
