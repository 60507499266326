import { PageContainer, PageContentContainer } from "../../styles/layouts";
import { ContentLayout } from "../../layouts/content-layout/content-layout.component";
import { getEditCodecardPageBreadcrumbs } from "../../constants/breadcrumbs";
import React, { ReactElement } from "react";
import { useOrganization } from "../../hooks/useOrganization";
import { useParams } from "react-router-dom";
import { EditCodecard } from "./edit-codecard/edit-codecard";
import ContentHeader from "../../components/content-header/content-header.component";
import Loader from "../../components/loader/loader.component";
import { LoaderPageWrapper } from "../../styles/loader-wrapper.styled";
import { useOrganizationBreadcrumbs } from "../../hooks/useOrganizationBreadcrumbs";
import { MenuItemsEnum } from "../../components/menu-component/menu.component";
import { PageEnum } from "../../types/PagesEnum";

interface CodecardEditPageBodyProps {
    organizationId: string;
    codecardId: string;
}

const EditCodecardPageBody = (props: CodecardEditPageBodyProps): ReactElement => {
    const { organizationId, codecardId } = props;

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <ContentHeader
                label={"Edit codecard"}
                breadcrumbs={getEditCodecardPageBreadcrumbs(organizationId, codecardId, codecardId)}
            />
            <EditCodecard organizationId={organizationId} codecardId={codecardId} />
        </div>
    );
};

interface RouteParams {
    organizationId: string;
    codecardId: string;
}

export const EditCodecardPage = () => {
    const { organizationId, codecardId } = useParams() as RouteParams;
    const { organizationBreadcrumbs } = useOrganizationBreadcrumbs(PageEnum.EDIT_CODECARD);
    const { organization, fetching } = useOrganization();
    const { name: organizationName } = organization || {};

    if (fetching) {
        return (
            <LoaderPageWrapper>
                <Loader />
            </LoaderPageWrapper>
        );
    }

    return (
        <PageContainer>
            <PageContentContainer>
                <ContentLayout
                    breadcrumbs={organizationBreadcrumbs}
                    label={organizationName || ""}
                    openKey={MenuItemsEnum.Codecards}
                    extra
                    isBackPath
                    ContentLayoutBody={
                        <EditCodecardPageBody
                            organizationId={organizationId}
                            codecardId={codecardId}
                        />
                    }
                />
            </PageContentContainer>
        </PageContainer>
    );
};
