import styled from "styled-components";

export const PageContentContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const PageContainer = styled.div`
    width: 100%;
    height: calc(100vh - 64px);
    justify-content: space-between;
    overflow-x: auto;
    display: flex;
    background: white;
`;
