import styled from "styled-components";

export const AddUserContainer = styled.div`
    margin: 0 0 0 50px;
    width: 80%;

    h3 {
        margin: 20px 0 20px 0;
        font-size: 24px;
    }
`;

export const FieldsIcon = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    span {
        padding-left: 10px;
    }
`;

export const CheckboxContainer = styled.div`
    margin-bottom: 20px;

    p {
        margin-top: 15px;
    }
`;

export const PermissionsContainer = styled.div`
    margin-top: 10px;
`;

export const FormWithLabelsContainer = styled.div`
    display: flex;
    max-width: 500px;
`;

export const FieldsContainer = styled.div`
    width: 85%;
    padding-left: 30px;

    .ant-input {
        margin-bottom: 20px;
    }
`;

export const LabelsContainer = styled.div`
    width: 25%;

    div {
        min-height: 32px;
        margin-bottom: 20px;
    }
`;

export const FormWithLabelsButtonsContainer = styled.div`
    display: flex;
    margin-top: 25px;
`;
