import React, { ReactElement } from "react";
import { NoItemMessageEnum } from "../../../utils/enums/message.enum";

interface NoItemMessageProps {
    type: NoItemMessageEnum;
}

export const NoItemMessage = (props: NoItemMessageProps): ReactElement => {
    const { type } = props;
    if (type === NoItemMessageEnum.CODECARDS) {
        return <>{"No codecards"}</>;
    }

    if (type === NoItemMessageEnum.LOCKS) {
        return <>{"No locks"}</>;
    } else {
        return <></>;
    }
};
