import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface UseSelectedMenuItemInterface {
    selectedItem: number;
    setSelectedItem: (value: number) => void;
}

export const useSelectedMenuItem = (): UseSelectedMenuItemInterface => {
    const { pathname } = useLocation();
    const [selectedItem, setSelectedItem] = useState<number>(0);

    useEffect(() => {
        if (!pathname) {
            return;
        }

        setSelectedItem(parseInt(pathname.split("/")[3]));
    }, [pathname]);

    return {
        selectedItem,
        setSelectedItem,
    };
};
