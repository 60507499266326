import { LoaderAnimatedStyled } from "./loader.styled";
import LOGO from "../../images/logo.png";
import React from "react";

const Loader = () => (
    <LoaderAnimatedStyled>
        <img src={LOGO} alt={"Logo"} width={50} height={48} />
    </LoaderAnimatedStyled>
);

export default Loader;
