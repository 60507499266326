import styled from "styled-components";

export const SendCodecardBodyStyled = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
export const SendCodecardContentStyled = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
`;
export const SendCodecardPageStyled = styled.div`
    width: 48%;
    height: 420px;
    border: 1px solid #d9d9d9;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
`;

export const SendCodecardFormHeader = styled.div`
    height: 55px;
    background: #f5f5f5;
    border-bottom: 1px solid #d9d9d9;
    align-items: center;
    display: flex;
    justify-content: center;
`;
export const SendCodecardFormContent = styled.textarea`
    border: 1px dashed #d9d9d9;
    display: flex;
    height: 100%;
    margin: 24px;
    padding: 24px;
`;

export const SendCodecardNotificationStyled = styled.div`
    padding: 10px 10px;
    height: 62px;
    width: 400px;
    margin: 24px;
    display: flex;
    background: #e6f7ff;
    border: 1px solid #91d5ff;
`;
