import {
    ADD,
    BASE_STATIC_ROUTE,
    CODECARD,
    CODECARDS,
    CREATE,
    DETAILS,
    EDIT,
    LOCK,
    LOCKS,
    MESSAGE,
    NOTIFICATIONS,
    SEND,
    SETTINGS,
    SUBUNITS,
    USER,
    USERS,
} from "../routes.constants";

export const getStaticOrganisationRootPagePath = (): string => {
    return BASE_STATIC_ROUTE;
};

export const getStaticDetailsPath = (): string => {
    return `${BASE_STATIC_ROUTE}/${DETAILS}`;
};

export const getStaticCreateSubUnitPath = (): string => {
    return `/manager/organizations/${CREATE}`;
};

export const getStaticLocksPath = (): string => {
    return `${BASE_STATIC_ROUTE}/${LOCKS}`;
};

export const getStaticUserPath = (): string => {
    return "/manager";
};

export const getStaticAddUserPath = (): string => {
    return `${BASE_STATIC_ROUTE}/${USERS}/${USER}/${ADD}`;
};

export const getStaticOrganizationUserPath = (): string => {
    return `${BASE_STATIC_ROUTE}/${USERS}/${USER}/:userId`;
};

export const getStaticAddLockPath = (): string => {
    return `${BASE_STATIC_ROUTE}/${LOCKS}/${ADD}`;
};

export const getStaticInfoLockPath = (): string => {
    return `${BASE_STATIC_ROUTE}/${LOCKS}/${LOCK}/:lockId`;
};

export const getStaticEditLockPath = (): string => {
    return `${BASE_STATIC_ROUTE}/${LOCKS}/${LOCK}/:lockId/${EDIT}`;
};

export const getStaticCodecardPath = (): string => {
    return `${BASE_STATIC_ROUTE}/${CODECARDS}/${CODECARD}/:codecardId`;
};

export const getStaticCodecardsPath = (): string => {
    return `${BASE_STATIC_ROUTE}/${CODECARDS}/`;
};

export const getStaticCodecardsLockPath = (): string => {
    return `${BASE_STATIC_ROUTE}/${CODECARDS}/${LOCK}/:lockId`;
};

export const getStaticCreateCodecardsPath = () => {
    return `${BASE_STATIC_ROUTE}/${CODECARDS}/${CREATE}`;
};

export const getStaticCreateCodecardPath = (): string => {
    return `${BASE_STATIC_ROUTE}/${CODECARDS}/${CODECARD}/:lockId/${CREATE}/`;
};

export const getStaticCodecardSendPath = (): string => {
    return `${BASE_STATIC_ROUTE}/${CODECARDS}/${CODECARD}/:codecardId/${SEND}`;
};

export const getStaticCodecardEditPath = (): string => {
    return `${BASE_STATIC_ROUTE}/${CODECARDS}/${CODECARD}/:codecardId/${EDIT}`;
};

export const getStaticUsersPatch = (): string => {
    return `${BASE_STATIC_ROUTE}/${USERS}/`;
};

export const getStaticMessagePath = (): string => {
    return `${BASE_STATIC_ROUTE}/${MESSAGE}/`;
};

export const getStaticSubunitsPath = (): string => {
    return `${BASE_STATIC_ROUTE}/${SUBUNITS}/`;
};

export const getStaticNotificationsPath = (): string => {
    return `${BASE_STATIC_ROUTE}/${NOTIFICATIONS}/`;
};

export const getStaticSettingsPath = (): string => {
    return `${BASE_STATIC_ROUTE}/${SETTINGS}/`;
};
