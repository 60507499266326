import { ReactElement, useState } from "react";

import { Button } from "antd";
import ContentHeader from "../../../components/content-header/content-header.component";
import { CreateSubUnitBtns, CreateSubUnitStyled } from "../create-sub-unit-page.styled";
import { t } from "i18next";
import { CreateSubUnitType } from "./create-sub-unit.type";
import { useMutation } from "urql";
import { CREATE_ORGANIZATION } from "../../../graphql/mutations/organization/organization.mutations";
import { initialCreateSubUnitFormValues } from "../../../constants/sub-unit";
import { Stores, useStore } from "../../../stores/store";
import { OrganisationsStore } from "../../../stores/organisationsStore";
import { useNavigate } from "react-router-dom";
import { CreateSubUnitForm } from "./create-sub-unit-form";
import { renderToast } from "../../../components/toast/toast";
import { useRouteNavigation } from "../../../hooks/useNavigationPatch";

const { ORGANISATION_STORE } = Stores;

export const CreateSubUnit = (): ReactElement => {
    // eslint-disable-next-line
    const [organization, createOrganization] = useMutation(CREATE_ORGANIZATION);
    const [formValues, setFormValues] = useState<CreateSubUnitType>(initialCreateSubUnitFormValues);
    const { organisations } = useStore(ORGANISATION_STORE) as OrganisationsStore;
    const { navigateToOrganisationRoot } = useRouteNavigation();
    const navigate = useNavigate();

    const handleChangeParent = (value: number) => {
        setFormValues({
            ...formValues,
            parent: value,
        });
    };

    const handleChangeCountry = (value: string) => {
        setFormValues({
            ...formValues,
            country: value,
        });
    };

    const handleChangeValue = (key: string, event: any) => {
        setFormValues({
            ...formValues,
            [key]: event.target.value,
        });
    };

    const handleChangeNotification = (key: string, value: boolean) => {
        setFormValues({
            ...formValues,
            [key]: value,
        });
    };

    const handleSubmitCreateSubUnitForm = async () => {
        const createSubUnit = await createOrganization({
            parent: formValues.parent,
            name: formValues.name,
            orgNumber: formValues.orgNumber,
            street: formValues.street,
            postcode: formValues.postcode,
            city: formValues.city,
            country: formValues.country,
            notes: formValues.notes,
            keyCreateNotificationEmail: formValues.isCreateNotificationEmail,
            keyCreateNotificationSms: false,
        });
        const { data } = createSubUnit || {};

        if (!data?.error && data?.CreateOrganisation?.id) {
            await renderToast({
                type: "success",
                message: "Sub unit create",
            });
        }

        if (createSubUnit.data?.CreateOrganisation?.id) {
            navigateToOrganisationRoot(createSubUnit.data?.CreateOrganisation?.id);
        }
    };

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                }}
            >
                <CreateSubUnitStyled>
                    <ContentHeader label={"Create sub organization"} />
                    <CreateSubUnitForm
                        organisations={organisations}
                        formValues={formValues}
                        onChangeParent={handleChangeParent}
                        onChangeValue={handleChangeValue}
                        onChangeNotification={handleChangeNotification}
                        onChangeCountry={handleChangeCountry}
                    />
                </CreateSubUnitStyled>
            </div>
            <CreateSubUnitBtns>
                <Button
                    onClick={() => {
                        navigate(-1);
                    }}
                    type="text"
                >
                    {t("buttons.cancel")}
                </Button>
                <Button
                    style={{
                        marginRight: "10px",
                    }}
                    type="primary"
                    onClick={handleSubmitCreateSubUnitForm}
                >
                    {t("buttons.create")}
                </Button>
            </CreateSubUnitBtns>
        </>
    );
};
