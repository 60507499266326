import React, { ReactElement } from "react";
import { BatteryComponent } from "../../../components/battery/battery.component";
import { Params, useNavigate, useParams } from "react-router-dom";
import { Stores, useStore } from "../../../stores/store";
import { OrganisationsStore } from "../../../stores/organisationsStore";
import { LockInterface } from "../../../types/Lock.interface";
import { LoaderContentWrapper } from "../../../styles/loader-wrapper.styled";
import Loader from "../../../components/loader/loader.component";
import { getInfoLockPath } from "../../../routes/paths/dynamic-paths";
import { LocksItemStyled, LocksItemTopStyled } from "../locks-page.styled";

const LocksWrapper = (props: any) => {
    const { lock, organizationId } = props;
    const { battery_change, id: lockId, label, notes, type } = lock;
    const navigate = useNavigate();
    const backPatch = getInfoLockPath(organizationId, lockId);
    const handleNavigate = () => {
        if (backPatch) {
            navigate(backPatch);
        }
    };

    return (
        <LocksItemStyled onClick={handleNavigate}>
            <LocksItemTopStyled>
                <div>
                    {label} &nbsp;
                    <span>({type})</span>
                </div>
                <BatteryComponent batteryChange={battery_change} />
            </LocksItemTopStyled>
            <p>{notes}</p>
        </LocksItemStyled>
    );
};

interface LocksProps {
    lock: LockInterface;
}

export const Locks = (props: LocksProps): ReactElement => {
    const { lock } = props;
    const { organizationId }: Params = useParams();

    const { organisations } = useStore(Stores.ORGANISATION_STORE) as OrganisationsStore;

    if (!organisations) {
        return (
            <LoaderContentWrapper>
                <Loader />
            </LoaderContentWrapper>
        );
    }

    return <LocksWrapper lock={lock} organizationId={organizationId!} />;
};
