import { countriesList } from "../constants/counties-list";

export interface CountriesOptionInterface {
    label: string;
    value: string;
}

export const getCountriesOptions = (): CountriesOptionInterface[] => {
    const countriesListOptions: CountriesOptionInterface[] = [];

    for (const country in countriesList) {
        const countryCode = countriesList[country];
        const countryOption = {
            label: country,
            value: countryCode,
        };

        countriesListOptions.push(countryOption);
    }

    return countriesListOptions;
};

export const getCountryLabelByCode = (countryCodeToFind: string): string => {
    let countryLabel = "";

    for (const country in countriesList) {
        const countryCode = countriesList[country];

        if (countryCode === countryCodeToFind) {
            countryLabel = country;
        }
    }

    return countryLabel;
};
