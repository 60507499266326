import styled from "styled-components";

interface UserPageContainerProps {
    isEdit?: boolean;
}

export const UserPageContainer = styled.div<UserPageContainerProps>`
    min-width: 320px;
    font-size: 24px;
    margin: 50px 0 25px 30px;
    font-weight: 400;

    p {
        margin-top: 15px;
    }
    input {
        padding-left: 15px;
        background-color: ${({ isEdit = false }) => (isEdit ? "white" : "none")};
    }
`;

export const StackTopContainer = styled.div`
    width: 320px;
    border-bottom: 1px solid #000;
    font-size: 24px;
    margin-bottom: 10px;
`;

export const StackBottomContainer = styled.div`
    width: 320px;
    border-bottom: 1px solid #000;
    padding-bottom: 50px;
`;

export const PermissionContainer = styled.div`
    width: 100%;
    font-size: 24px;
    margin-top: 5px;

    p {
        margin-top: 10px;
        margin-left: 20px;
    }
`;
