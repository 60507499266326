import React from "react";

import { NavigationAdaptiveContainer } from "../../components/navigation/navigation-adaptive.container";
import { TopNavigationBarAdaptiveContainer } from "../../components/top-navigation-bar/top-navigation-bar-adaptive.container";

import { TelkeyRoutes } from "../../routes/TelkeyRoutes";

import { MainLayoutContainer, ContentContainer } from "./main-layout.styled";

export const MainLayout = () => {
    return (
        <MainLayoutContainer>
            <TopNavigationBarAdaptiveContainer />

            <ContentContainer>
                <NavigationAdaptiveContainer />

                <TelkeyRoutes />
            </ContentContainer>
        </MainLayoutContainer>
    );
};
