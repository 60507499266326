import { notification } from "antd";
import { ArgsProps } from "antd/lib/notification";

interface RenderToastProps extends ArgsProps {}

export const renderToast = (props: RenderToastProps) => {
    const { type = "error", message, description } = props;

    notification[type]({
        placement: "top",
        message: message,
        description: description,
    });
};
