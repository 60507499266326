import React, { ReactElement, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../components/loader/loader.component";
import { MenuItemsEnum } from "../../components/menu-component/menu.types";
import { MobileMenu } from "../../components/menu-component/mobile-menu/mobile-menu.component";
import { withAdaptiveContainer, WithAdaptiveProps } from "../../HOC/withAdaptiveContainer";
import { useRouteNavigation } from "../../hooks/useNavigationPatch";
import { useOrganization } from "../../hooks/useOrganization";
import { useOrganizationBreadcrumbs } from "../../hooks/useOrganizationBreadcrumbs";
import { ContentLayout } from "../../layouts/content-layout/content-layout.component";
import { PageContainer, PageContentContainer } from "../../styles/layouts";
import { LoaderPageWrapper } from "../../styles/loader-wrapper.styled";
import { PageEnum } from "../../types/PagesEnum";
import { RouteParamsInterface } from "../../types/RouteParams.interface";
import { SupportedDevicesEnum } from "../../types/SupportedDevicesEnum";

const RootOrganisationPageAdaptiveContainer = withAdaptiveContainer(
    (props: WithAdaptiveProps): ReactElement => {
        const { deviceType } = props;
        const { organizationId } = useParams() as RouteParamsInterface;

        const { navigateToLocks } = useRouteNavigation();

        useEffect(() => {
            if (deviceType === SupportedDevicesEnum.DESKTOP) {
                navigateToLocks(organizationId);
            }
        }, [deviceType]);

        const { organization, fetching } = useOrganization();
        const { organizationBreadcrumbs } = useOrganizationBreadcrumbs(PageEnum.ORGANISATION_ROOT);

        const { name: organizationName } = organization || {};

        if (fetching) {
            return (
                <LoaderPageWrapper>
                    <Loader />
                </LoaderPageWrapper>
            );
        }

        return (
            <PageContainer>
                <PageContentContainer>
                    <ContentLayout
                        breadcrumbs={organizationBreadcrumbs}
                        label={organizationName || ""}
                        openKey={MenuItemsEnum.Details}
                        ContentLayoutBody={<MobileMenu />}
                        isBackPath
                    />
                </PageContentContainer>
            </PageContainer>
        );
    },
);

export const RootOrganisationPage = (): ReactElement => {
    return <RootOrganisationPageAdaptiveContainer />;
};
