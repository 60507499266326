export const GET_CODECARDS = `
	query ($organisation: Int!) {
		Codecards (organisation: $organisation) {
			id,
			organisation_id {
				id
			},
			active,
			valid_from,
			valid_to,			
			created_at,
			updated_at,
			keys{
			    id,
			    codecard{
			        id
			    },
			    lock{
			        id, 
			        type, 
			        label
			    }
			}
		}
  	}
`;

export const GET_SORT_CODECARDS = `
	query ($organisation: Int!, $col:CodecardSortByCols!) {
		Codecards (organisation: $organisation, sort: { 
                order: asc, 
                col: $col           
            }) {
			id,
			organisation_id {
				id
			},
			active,
			valid_from,
			valid_to,			
			created_at,
			updated_at,
			keys{
			    id,
			    codecard{
			        id
			    },
			    lock{
			        id, 
			        type, 
			        label
			    }
			}
		}
  	}
`;
