import styled from "styled-components";

export const BreadcrumbsStyled = styled.div`
    display: flex;
    align-items: center;
    min-height: 22px;
`;

export const SingleBreadcrumbStyled = styled.div`
    display: flex;
    align-items: center;
`;

export const BreadcrumbsSeparatorStyled = styled.div`
    font-size: 22px;
    color: #8c8c8c;
`;
