import React from "react";
import {
    CreateCodecardField,
    CreateCodecardItem,
    CreateCodecardLabel,
    CreateCodecardWrap,
} from "./create-codecard.styled";
import { Select, Switch } from "antd";
import { SelectCodecard } from "./select-codecard";
import { CustomDatePicker } from "../../../components/date-picker/date-picker.component";
import { ValidToDropdownItems } from "../../../constants/codecard";
import TextArea from "antd/lib/input/TextArea";
import { useTranslation } from "react-i18next";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { CodecardFormEnum } from "../../../utils/enums/form.enum";
import { useParams } from "react-router-dom";
import { RouteParamsInterface } from "../../../types/RouteParams.interface";
import { InputWithValidation } from "../../../components/input-with-validation/input-with-validation";

interface CreateCodecardFormProps {
    onFormChange: (key: string) => (event: any) => void;
    formValues: any;
    validatedFields: any;
    onChangeDate: (key: string) => (_: any, dateString: string) => void;
    onChangeValue: (key: string) => (value: string | boolean) => void;
}

export const CreateCodecardForm = (props: CreateCodecardFormProps) => {
    const { onFormChange, formValues, validatedFields, onChangeDate, onChangeValue } = props;

    const { lockId } = useParams() as RouteParamsInterface;

    const { t } = useTranslation();

    return (
        <>
            <CreateCodecardWrap>
                <CreateCodecardItem>
                    <CreateCodecardLabel>{t("create_codecard.codecard_name")}</CreateCodecardLabel>
                    <CreateCodecardField>
                        <InputWithValidation
                            placeholder="E.g. “Carpenter”"
                            onChange={onFormChange(CodecardFormEnum.NAME)}
                            isValid={validatedFields?.[CodecardFormEnum.NAME]?.isValid}
                            errorMessage={validatedFields?.[CodecardFormEnum.NAME]?.message}
                        />
                    </CreateCodecardField>
                </CreateCodecardItem>

                <CreateCodecardItem>
                    <CreateCodecardLabel>{t("create_codecard.lock_or_locks")}</CreateCodecardLabel>
                    <CreateCodecardField>
                        <SelectCodecard
                            lockId={lockId}
                            onFormChange={onChangeValue}
                            formValues={formValues?.locks}
                        />
                    </CreateCodecardField>
                </CreateCodecardItem>

                <CreateCodecardItem>
                    <CreateCodecardLabel>{t("create_codecard.valid_from")}</CreateCodecardLabel>
                    <CreateCodecardField>
                        <CustomDatePicker
                            handleChange={onChangeDate(CodecardFormEnum.VALID_FROM)}
                        />
                    </CreateCodecardField>
                </CreateCodecardItem>

                {formValues.validFrom && (
                    <CreateCodecardItem>
                        <CreateCodecardLabel>{t("create_codecard.valid_for")}</CreateCodecardLabel>
                        <CreateCodecardField>
                            <Select
                                style={{ width: "100%" }}
                                onChange={onChangeValue(CodecardFormEnum.VALID_FOR)}
                                options={ValidToDropdownItems}
                            />
                        </CreateCodecardField>
                    </CreateCodecardItem>
                )}

                {formValues.validFor === "other" && (
                    <CreateCodecardItem>
                        <CreateCodecardLabel>{t("create_codecard.valid_to")}</CreateCodecardLabel>

                        <CreateCodecardField>
                            <CustomDatePicker
                                startDate={formValues.validFrom}
                                handleChange={onChangeDate(CodecardFormEnum.VALID_TO)}
                            />
                        </CreateCodecardField>
                    </CreateCodecardItem>
                )}

                <CreateCodecardItem>
                    <CreateCodecardLabel>{t("create_codecard.notes")}</CreateCodecardLabel>
                    <CreateCodecardField>
                        <TextArea
                            rows={4}
                            style={{ width: "100%" }}
                            onChange={onFormChange(CodecardFormEnum.NOTES)}
                        />
                    </CreateCodecardField>
                </CreateCodecardItem>

                <CreateCodecardItem>
                    <CreateCodecardLabel>{t("create_codecard.active")}</CreateCodecardLabel>
                    <CreateCodecardField>
                        <Switch
                            onChange={onChangeValue(CodecardFormEnum.ACTIVE)}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked={false}
                        />
                    </CreateCodecardField>
                </CreateCodecardItem>
            </CreateCodecardWrap>
        </>
    );
};
