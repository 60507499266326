import {
    BellOutlined,
    BranchesOutlined,
    CreditCardOutlined,
    InfoCircleOutlined,
    LockOutlined,
    MessageOutlined,
    SettingOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { MenuItemInterface, MenuItemsEnum } from "./menu.types";

export const menuItems: MenuItemInterface[] = [
    {
        label: "menu.details",
        key: MenuItemsEnum.Details,
        icon: <InfoCircleOutlined />,
    },
    {
        label: "menu.locks",
        key: MenuItemsEnum.Locks,
        icon: <LockOutlined />,
    },
    {
        label: "menu.codecards",
        key: MenuItemsEnum.Codecards,
        icon: <CreditCardOutlined />,
    },
    {
        label: "menu.users",
        key: MenuItemsEnum.Users,
        icon: <UserOutlined />,
    },
    {
        label: "menu.message",
        key: MenuItemsEnum.Message,
        icon: <MessageOutlined />,
    },
    {
        label: "menu.sub_units",
        key: MenuItemsEnum.Subunits,
        icon: <BranchesOutlined />,
    },
    {
        label: "menu.notifications",
        key: MenuItemsEnum.Notifications,
        icon: <BellOutlined />,
    },
    {
        label: "menu.settings",
        key: MenuItemsEnum.Settings,
        icon: <SettingOutlined />,
    },
];
