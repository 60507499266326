export const ADD_USER = `
  mutation ( 
    $name: String!
    $surname: String
    $email: String!
    $staff: Boolean!
    $language: String!
    $organisation:Int!
  ) {
      addUser (input: {
        name: $name,
        surname: $surname,
        email: $email,
        staff: $staff,
        language: $language,
        organisation:$organisation
      }) {
        id,
      }
  }
`;
