import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const CreateCodecardContentStyled = styled.div`
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
`;

export const CreateCodecardWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 27px 16px;
    /*  border-bottom: 1px solid #f0f0f0;*/
`;

export const CreateCodecardItem = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
`;

export const CreateCodecardLabel = styled.div`
    padding-right: 8px;
    text-align: right;
    padding-top: 5px;
    width: 115px;
`;

export const CreateCodecardField = styled.div`
    width: 332px;
`;

export const CreateCodecardMessage = styled.div`
    color: ${colors.graySeven};
`;

export const CreateCodecardBtns = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;
    padding-right: 24px;
    margin: 24px;
    button + button {
        margin-left: 12px;
    }
`;
