import React, { ReactElement } from "react";

import {
    getStaticAddLockPath,
    getStaticAddUserPath,
    getStaticCodecardEditPath,
    getStaticCodecardPath,
    getStaticCodecardSendPath,
    getStaticCodecardsLockPath,
    getStaticCodecardsPath,
    getStaticCreateCodecardPath,
    getStaticCreateCodecardsPath,
    getStaticDetailsPath,
    getStaticEditLockPath,
    getStaticInfoLockPath,
    getStaticLocksPath,
    getStaticMessagePath,
    getStaticNotificationsPath,
    getStaticOrganisationRootPagePath,
    getStaticOrganizationUserPath,
    getStaticSettingsPath,
    getStaticSubunitsPath,
    getStaticUserPath,
    getStaticUsersPatch,
    getStaticCreateSubUnitPath,
} from "./paths/static-paths";

import { Route, Routes } from "react-router-dom";
import { AddLockPage } from "../pages/add-lock-page/add-lock-page.component";
import { AddUserPage } from "../pages/add-user-page/add-user-page.component";
import { CodecardPage } from "../pages/codecard-page/codecard-page";
import { CodecardsPage } from "../pages/codecards-page/codecards-page";
import { CreateCodecardPage } from "../pages/create-codecard-page/create-codecard.page";
import { DetailsPage } from "../pages/details-page/details-page";
import { EditCodecardPage } from "../pages/edit-codecard-page/edit-codecard-page";
import { EditLockPage } from "../pages/edit-lock-page/edit-lock-page";
import { LockPage } from "../pages/lock-page/lock-page.component";
import { LocksPage } from "../pages/locks-page/locks-page";
import { MessageTemplatesPage } from "../pages/message-templates-page/message-temrlates-page";
import { NotificationsPage } from "../pages/notifications-page/notifications-page";
import { SendCodecardPage } from "../pages/send-codecard-page/send-codecard-page";
import { SettingsPage } from "../pages/settings-page/settings-page";
import { SubunitsPage } from "../pages/subunits-page/subunits-page";
import { UserPage } from "../pages/user-page/user-page.component";
import { UsersPage } from "../pages/users-page/users-page";
import { RootOrganisationPage } from "../pages/root-organisation-page/root-organisation-page";
import { OrganizationUserPage } from "../pages/organization-user-page/organization-user-page";
import { CreateSubUnitPage } from "../pages/create-sub-unit-page/create-sub-unit-page";

export const TelkeyRoutes = (): ReactElement => {
    return (
        <Routes>
            <Route path={getStaticUsersPatch()} element={<UsersPage />} />
            <Route path={getStaticUserPath()} element={<UserPage />} />
            <Route path={getStaticAddUserPath()} element={<AddUserPage />} />
            <Route path={getStaticOrganizationUserPath()} element={<OrganizationUserPage />} />
            <Route path={getStaticCreateSubUnitPath()} element={<CreateSubUnitPage />} />

            <Route path={getStaticOrganisationRootPagePath()} element={<RootOrganisationPage />} />

            <Route path={getStaticLocksPath()} element={<LocksPage />} />
            <Route path={getStaticInfoLockPath()} element={<LockPage />} />
            <Route path={getStaticEditLockPath()} element={<EditLockPage />} />
            <Route path={getStaticAddLockPath()} element={<AddLockPage />} />

            <Route path={getStaticCodecardsPath()} element={<CodecardsPage />} />
            <Route path={getStaticCodecardsLockPath()} element={<CodecardsPage />} />
            <Route path={getStaticCodecardPath()} element={<CodecardPage />} />
            <Route path={getStaticCreateCodecardsPath()} element={<CreateCodecardPage />} />
            <Route path={getStaticCreateCodecardPath()} element={<CreateCodecardPage />} />
            <Route path={getStaticCodecardSendPath()} element={<SendCodecardPage />} />
            <Route path={getStaticCodecardEditPath()} element={<EditCodecardPage />} />

            <Route path={getStaticDetailsPath()} element={<DetailsPage />} />

            <Route path={getStaticMessagePath()} element={<MessageTemplatesPage />} />
            <Route path={getStaticSubunitsPath()} element={<SubunitsPage />} />
            <Route path={getStaticNotificationsPath()} element={<NotificationsPage />} />
            <Route path={getStaticSettingsPath()} element={<SettingsPage />} />
        </Routes>
    );
};
