import { RightOutlined } from "@ant-design/icons";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRouteNavigation } from "../../../hooks/useNavigationPatch";
import { RouteParamsInterface } from "../../../types/RouteParams.interface";
import { menuItems } from "../menu.constants";
import { MenuItemInterface, MenuItemsEnum } from "../menu.types";
import { MobileMenuItemStyledContainer, MobileMenuStyledContainer } from "./mobile-menu.styled";

export const MobileMenu = (): ReactElement => {
    const { t } = useTranslation();

    const { organizationId } = useParams() as RouteParamsInterface;

    const {
        navigateToDetails,
        navigateToCodecards,
        navigateToLocks,
        navigateToMessages,
        navigateToNotifications,
        navigateToSettings,
        navigateToSubunits,
    } = useRouteNavigation();

    const handleNavigationClick = (menuItem: MenuItemsEnum): void => {
        if (menuItem === MenuItemsEnum.Details) {
            navigateToDetails(organizationId);
        }

        if (menuItem === MenuItemsEnum.Codecards) {
            navigateToCodecards(organizationId);
        }

        if (menuItem === MenuItemsEnum.Locks) {
            navigateToLocks(organizationId);
        }

        if (menuItem === MenuItemsEnum.Message) {
            navigateToMessages(organizationId);
        }

        if (menuItem === MenuItemsEnum.Notifications) {
            navigateToNotifications(organizationId);
        }

        if (menuItem === MenuItemsEnum.Settings) {
            navigateToSettings(organizationId);
        }

        if (menuItem === MenuItemsEnum.Subunits) {
            navigateToSubunits(organizationId);
        }

        if (menuItem === MenuItemsEnum.Users) {
            navigateToSubunits(organizationId);
        }
    };

    return (
        <MobileMenuStyledContainer>
            {menuItems.map(({ label, key, icon }: MenuItemInterface) => (
                <MobileMenuItemStyledContainer key={key} onClick={() => handleNavigationClick(key)}>
                    <div className="menu-item-details">
                        {icon}
                        <span>{t(label)}</span>
                    </div>

                    <RightOutlined />
                </MobileMenuItemStyledContainer>
            ))}
        </MobileMenuStyledContainer>
    );
};
