import styled from "styled-components";
import { colors } from "../../styles/colors";

export const FilterNotificationContainer = styled.div`
    margin: 24px;
    padding: 16px;
    margin-top: 70px;
    border: solid 2px ${colors.calendulaGoldThree};
    background: ${colors.calendulaGoldOne};
    div {
        display: flex;
        justify-content: space-between;
    }
    h2 {
        font-waight: 400;
        font-size: 16px;
        line-height: 24px;
        color: ${colors.grayNine};
    }
    p {
        font-waight: 400;
        font-size: 14px;
        line-height: 22px;
        color: ${colors.grayNine};
    }
    button {
        font-waight: 400;
        font-size: 12px;
        line-height: 20px;
        color: ${colors.graySeven};
        cursor: pointer;
        :hover {
            text-decoration: underline;
        }
    }
`;
