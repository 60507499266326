import { ReactElement } from "react";

import { ContentLayout } from "../../layouts/content-layout/content-layout.component";
import { PageContainer, PageContentContainer } from "../../styles/layouts";
import { CreateSubUnit } from "./create-sub-unit/create-sub-unit.component";

export const CreateSubUnitPageBody = (): ReactElement => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
            }}
        >
            <CreateSubUnit />
        </div>
    );
};

export const CreateSubUnitPage = () => {
    return (
        <PageContainer>
            <PageContentContainer>
                <ContentLayout
                    label={"Create sub organization"}
                    ContentLayoutBody={<CreateSubUnitPageBody />}
                />
            </PageContentContainer>
        </PageContainer>
    );
};
