import React, { useState, ReactElement } from "react";
import { Input, InputProps } from "antd";

interface InputWithValidationProps extends InputProps {
    isValid: boolean;
    errorMessage: string;
}

export const InputWithValidation = (props: InputWithValidationProps): ReactElement => {
    const { isValid, errorMessage } = props;
    const [isTouched, setIsTouched] = useState(false);

    const handleTouch = (): void => {
        if (isTouched) {
            return;
        }

        setIsTouched(true);
    };

    return (
        <>
            <Input {...props} onBlur={handleTouch} />

            <div>{!isValid && isTouched && <div>{errorMessage}</div>}</div>
        </>
    );
};
