import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const RoomWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    color: #262626;
    width: 100%;
`;

export const RoomLeftCol = styled.div`
    width: 50%;
    padding: 16px 24px;
    background: #f5f5f5;
    border: 1px solid #f0f0f0;
    margin-bottom: -1px;
`;
export const RoomRightCol = styled.div`
    width: 50%;
    padding: 16px 24px;
    border: 1px solid #f0f0f0;
    margin-left: -1px;
    margin-bottom: -1px;
`;

interface LocksStateProps {
    state?: boolean;
}
export const LocksState = styled.div<LocksStateProps>`
    margin-right: 10px;
    height: 8px;
    width: 8px;
    border-radius: 8px;
    opacity: 0.7;
    background: ${({ state }) => (state ? colors.activeGreen : colors.red)};
`;
