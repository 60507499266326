import React from "react";
import { LabelContainer, RequiredIconContainer } from "./label.styled";

interface LabelProps {
    label: string;
    description?: string;
    isRequired?: boolean;
}

export const Label: React.FC<LabelProps> = (props) => {
    const { label, isRequired } = props;

    return (
        <LabelContainer>
            {isRequired && <RequiredIconContainer>*</RequiredIconContainer>}
            <span>{label}</span>
        </LabelContainer>
    );
};
