import { useEffect } from "react";
import { useQuery } from "urql";

import { OrganisationsStore } from "../stores/organisationsStore";
import { Stores, useStore } from "../stores/store";
import { UsersStore } from "../stores/usersStore";

import { GET_ORGANIZATIONS } from "../graphql/queries/organizations/organizations.query";
import { GET_USER } from "../graphql/queries/users/user.query";
import { flatOrgsToTree } from "../utils/createSubOrganisations";
import { getLanguageFromLocalStorage } from "../utils/LocalStorage";
import i18n from "i18next";

interface UseInitAppReturnValue {
    isInitializing: boolean;
}

export const useInitApp = (): UseInitAppReturnValue => {
    const { setOrganisations, setFlatOrganisations } = useStore(
        Stores.ORGANISATION_STORE,
    ) as OrganisationsStore;
    const [organisationQueryResponse] = useQuery({
        query: GET_ORGANIZATIONS,
    });

    const { setUsers } = useStore(Stores.USERS_STORE) as UsersStore;
    const [userQueryResponse] = useQuery({
        query: GET_USER,
    });

    const { data: organisationsQueryData, fetching: isOrganisationsLoading } =
        organisationQueryResponse;

    const { data: myUsersQueryData, fetching: isUserLoading } = userQueryResponse;

    useEffect(() => {
        if (myUsersQueryData) {
            setUsers(myUsersQueryData);
        }
    }, [userQueryResponse]);

    useEffect(() => {
        if (organisationsQueryData && Array.isArray(organisationsQueryData?.Organisations)) {
            const { Organisations } = organisationsQueryData;

            const orgsTree = flatOrgsToTree(Organisations);

            if (Array.isArray(orgsTree)) {
                setOrganisations(orgsTree);
                setFlatOrganisations(Organisations);
            }
        }
    }, [organisationsQueryData]);

    useEffect(() => {
        const language = getLanguageFromLocalStorage() || "en";
        i18n.changeLanguage(language);
    }, []);

    return { isInitializing: isOrganisationsLoading || isUserLoading };
};
