export const GET_USER = `
  query getMe{ 
    User{
        id,
        name,
        email,
        staff,
        language,
        created_at,
        updated_at,
    }   
  }
`;

export const GET_USER_ID = `
  query getMe { 
    User { 
      id
    }
  }
`;

export const GET_USER_BY_ID = `
  query ($id: Int){ 
    User(id: $id){
        id,
        name,
        email,
        staff,
        language,
        created_at,
        updated_at,
    }   
  }
`;
