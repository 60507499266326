import { makeAutoObservable } from "mobx";
import { UserInterface } from "../types/User.interface";

export class UsersStore {
    public users: UserInterface | undefined;

    constructor() {
        this.setUsers = this.setUsers.bind(this);

        makeAutoObservable(this);
    }

    setUsers(users: any): void {
        this.users = users;
    }
}
