import React, { ReactElement } from "react";
import {
    FormContainer,
    FormValueLineContainer,
    FormValueContainer,
    FormOptionalContainer,
} from "./form-value-line.styled";

interface FormValueLineProps {
    Label: ReactElement;
    Value: ReactElement;
    Optional?: string;
    alignItems?: "center" | "flex-start" | "flex-end";
}

export const FormValueLine = (props: FormValueLineProps): ReactElement => {
    const { Label, Value, Optional, alignItems } = props;

    return (
        <FormValueLineContainer alignItems={alignItems}>
            <FormContainer>{Label}</FormContainer>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <FormValueContainer>{Value}</FormValueContainer>
                {Optional && <FormOptionalContainer>{Optional}</FormOptionalContainer>}
            </div>
        </FormValueLineContainer>
    );
};
