import React, { ReactElement, useEffect, useState } from "react";
import { Button, Input } from "antd";
import { ContentLayout } from "../../layouts/content-layout/content-layout.component";
import { useNavigate, useParams } from "react-router-dom";
import { PageContainer, PageContentContainer } from "../../styles/layouts";
import ContentHeader from "../../components/content-header/content-header.component";
import {
    CreateCodecardBtns,
    CreateCodecardContentStyled,
    CreateCodecardField,
    CreateCodecardItem,
    CreateCodecardLabel,
    CreateCodecardWrap,
} from "../create-codecard-page/create-codecard/create-codecard.styled";
import { CreateLockPageBreadcrumbs } from "../../constants/breadcrumbs";
import { useOrganization } from "../../hooks/useOrganization";
import { useMutation } from "urql";
import { CREATE_LOCK } from "../../graphql/mutations/lock/locks.mutation";
import { useTranslation } from "react-i18next";
import Loader from "../../components/loader/loader.component";
import { OrganisationInterface } from "../../types/Organisation.interface";
import { LoaderContentWrapper } from "../../styles/loader-wrapper.styled";
import { useOrganizationBreadcrumbs } from "../../hooks/useOrganizationBreadcrumbs";
import { MenuItemsEnum } from "../../components/menu-component/menu.component";
import { PageEnum } from "../../types/PagesEnum";
import { useRouteNavigation } from "../../hooks/useNavigationPatch";
import { RouteParamsInterface } from "../../types/RouteParams.interface";
import { renderToast } from "../../components/toast/toast";

interface AddLockPageBodyProps {
    organization: OrganisationInterface;
}

const AddLockPageBody = (props: AddLockPageBodyProps): ReactElement => {
    const { organization } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { navigateToLock } = useRouteNavigation();

    const { organizationId } = useParams() as RouteParamsInterface;
    const [serialLock, setSerialLock] = useState("");
    // eslint-disable-next-line
    const [result, CreateLock] = useMutation(CREATE_LOCK);

    const numberOrganizationId = parseInt(organizationId as string);

    const handleChoseSerial = (event: any): void => {
        const { value } = event.target;
        setSerialLock(value);
    };
    const handleAddLock = async (): Promise<void> => {
        await CreateLock({ serial: serialLock, orgID: numberOrganizationId });
    };

    useEffect(() => {
        if (result.data?.RegisterLock?.id) {
            renderToast({
                type: "success",
                message: "Lock created",
            });
        }
    }, [result]);

    useEffect(() => {
        if (result.data?.RegisterLock?.id) {
            navigateToLock(organizationId, result.data?.RegisterLock?.id);
        }
    }, [result]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
            }}
        >
            <CreateCodecardContentStyled>
                <ContentHeader
                    label={"Create lock"}
                    breadcrumbs={CreateLockPageBreadcrumbs(organizationId!)}
                />
                <CreateCodecardWrap>
                    <CreateCodecardItem>
                        <CreateCodecardLabel>Serial</CreateCodecardLabel>
                        <CreateCodecardField>
                            <Input
                                value={serialLock}
                                onChange={handleChoseSerial}
                                placeholder="Serial"
                            />
                        </CreateCodecardField>
                    </CreateCodecardItem>
                    <CreateCodecardItem>
                        <CreateCodecardLabel>Organization</CreateCodecardLabel>
                        <CreateCodecardField>
                            <Input value={organization?.name!} placeholder="Serial" />
                        </CreateCodecardField>
                    </CreateCodecardItem>
                </CreateCodecardWrap>
            </CreateCodecardContentStyled>
            <CreateCodecardBtns>
                <Button
                    onClick={() => {
                        navigate(-1);
                    }}
                    type="text"
                >
                    {t("buttons.cancel")}
                </Button>
                <Button
                    onClick={handleAddLock}
                    style={{
                        marginRight: "10px",
                    }}
                    type="primary"
                >
                    {t("buttons.create")}
                </Button>
            </CreateCodecardBtns>
        </div>
    );
};

export const AddLockPage = () => {
    const { organization, fetching } = useOrganization();
    const { organizationBreadcrumbs } = useOrganizationBreadcrumbs(PageEnum.ADD_LOCK);

    if (fetching) {
        return (
            <LoaderContentWrapper>
                <Loader />
            </LoaderContentWrapper>
        );
    }

    return (
        <PageContainer>
            <PageContentContainer>
                <ContentLayout
                    // @ts-ignore
                    breadcrumbs={organizationBreadcrumbs}
                    label={organization?.name!}
                    openKey={MenuItemsEnum.Locks}
                    ContentLayoutBody={<AddLockPageBody organization={organization} />}
                    isBackPath
                />
            </PageContentContainer>
        </PageContainer>
    );
};
