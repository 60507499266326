import { makeAutoObservable } from "mobx";
import { SupportedDevicesEnum } from "../types/SupportedDevicesEnum";

import { WindowDimensionsInterface } from "../types/WindowDimensions.interface";
import { getWindowDimensions } from "../utils/getWindowDimensions";

export class WindowDimensionsStore {
    public windowDimensions: WindowDimensionsInterface | null = getWindowDimensions();
    public deviceType: SupportedDevicesEnum = SupportedDevicesEnum.DESKTOP;

    constructor() {
        this.setWindowDimensions = this.setWindowDimensions.bind(this);
        this.setDeviceType = this.setDeviceType.bind(this);

        makeAutoObservable(this);
    }

    setWindowDimensions(windowDimensions: WindowDimensionsInterface): void {
        this.windowDimensions = windowDimensions;
    }

    setDeviceType(deviceType: SupportedDevicesEnum): void {
        this.deviceType = deviceType;
    }
}
