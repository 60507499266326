export const GET_LOCK = `
	query ($id: Int!) {
	  Lock (id: $id) {
	    id,
	    created_at,
	    installed_at,
	    type,
	    serial_number,
	    battery_change,
	    registered_at,
	    updated_at,
	    organisation {
	      id,
	      name
	    },
	    label,
	    timezone,
	    notes,
	    manufacturer_serial,
	  }
	}
`;
