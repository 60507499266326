import { createClient } from "urql";
import { urls } from "./urls";

export const urqlClient = createClient({
    url: urls.GRAPH_QL_URL,
    requestPolicy: "cache-and-network",
    fetchOptions: {
        credentials: "include",
    },
});
