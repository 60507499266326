import styled from "styled-components";

export const TelkeyLogoContainer = styled.div`
    color: #fff;
    display: flex;
    align-items: center;
    float: left;
    cursor: default;
    span {
        padding-left: 15px;
        font-size: 24px;
        margin-left: 5px;
        font-weight: 700;
    }
`;
