import React, { useEffect, useState } from "react";
import { PageContainer, PageContentContainer } from "../../styles/layouts";
import { ContentLayout } from "../../layouts/content-layout/content-layout.component";
import { useQuery } from "urql";
import { useParams } from "react-router-dom";
import { GET_ORGANIZATION } from "../../graphql/queries/organizations/organizations.query";
import { GET_CODECARDS, GET_SORT_CODECARDS } from "../../graphql/queries/codecards/codecards.query";
import ContentHeader from "../../components/content-header/content-header.component";
import { FilterNotification } from "../../components/filter-notification/filter-notification.component";
import { GET_LOCKS } from "../../graphql/queries/locks/locks.query";
import { mapLocksToDropdownOption } from "../../utils/array";
import { CodecardsFilters } from "./codecards-filters/codecards-filters";
import { useSearch } from "../../hooks/useSearch";
import Loader from "../../components/loader/loader.component";
import {
    TableContentStyled,
    TableHeaderContentStyled,
    TableNoItemsMessageStyled,
} from "../../styles/table";
import { Codecards } from "./codecards/codecards.component";
import { NoItemMessage } from "../../components/messages/no-item-message/no-item-message.component";
import { NoItemMessageEnum } from "../../utils/enums/message.enum";
import { LoaderContentWrapper, LoaderPageWrapper } from "../../styles/loader-wrapper.styled";
import { useOrganizationBreadcrumbs } from "../../hooks/useOrganizationBreadcrumbs";
import { MenuItemsEnum } from "../../components/menu-component/menu.component";
import { PageEnum } from "../../types/PagesEnum";
import { RouteParamsInterface } from "../../types/RouteParams.interface";

const defaultFilterValues = { search: "", sort: "", filter: [] };

const getCodecardsGqlBySort = (sort: string) => {
    return sort ? GET_SORT_CODECARDS : GET_CODECARDS;
};

const getVariablesCodecards = (sort: string, numberOrganization: number) => {
    return sort
        ? { organisation: numberOrganization, col: sort }
        : { organisation: numberOrganization };
};

const CodecardsLayoutBody = () => {
    const { organizationId } = useParams() as RouteParamsInterface;
    const [codecards, setCodecards] = useState([]);
    const [filtredCodecards, setFiltredCodecards] = useState([]);
    const [locks, setLocks] = useState([]);
    const [filterValues, setFilterValues] = useState<any>(defaultFilterValues);
    const [isNotification, setIsNotification] = useState(false);

    const numberOrganization = parseInt(organizationId);

    const [resultCodecarads] = useQuery({
        query: getCodecardsGqlBySort(filterValues.sort),
        variables: getVariablesCodecards(filterValues.sort, numberOrganization),
    });

    const { data, fetching } = resultCodecarads || {};

    const [resultLocks] = useQuery({
        query: GET_LOCKS,
        variables: { organisation_id: numberOrganization },
    });
    const { data: dataLocks } = resultLocks || {};

    useEffect(() => {
        if (dataLocks?.Locks && Array.isArray(dataLocks?.Locks)) {
            const { Locks } = dataLocks;
            const locks = Locks?.map(mapLocksToDropdownOption);
            setLocks(locks);
        }
    }, [dataLocks]);

    useEffect(() => {
        if (data && Array.isArray(data.Codecards)) {
            const { Codecards } = data;
            setCodecards(Codecards);
        }
    }, [data]);

    useEffect(() => {
        // @ts-ignore
        !Object.values(filterValues).some((filter) => filter.length > 0)
            ? setIsNotification(false)
            : setIsNotification(true);
    }, [filterValues]);

    const handleClear = () => {
        setFilterValues(defaultFilterValues);
    };

    useEffect(() => {
        if (filterValues?.filter?.length > 0) {
            const filter = codecards.filter(({ keys }) =>
                // @ts-ignore
                keys?.some(({ lock }: any) => filterValues.filter.includes(lock.id)),
            );
            setFiltredCodecards(filter);
        } else {
            setFiltredCodecards(codecards);
        }
    }, [codecards, filterValues]);

    const { searchResult: codcardSearchResult } = useSearch(
        filtredCodecards,
        "id",
        filterValues.search,
    );

    const handleFilterChange = (data: any) => (value: any) => {
        setFilterValues({ ...filterValues, [data]: value });
    };

    if (fetching) {
        return (
            <LoaderContentWrapper>
                <Loader />
            </LoaderContentWrapper>
        );
    }

    return (
        <div style={{ width: "100%" }}>
            <ContentHeader label={"Codecards"} />
            <CodecardsFilters
                onChange={handleFilterChange}
                filterValues={filterValues}
                locks={locks}
            />
            <TableContentStyled>
                <thead>
                    <TableHeaderContentStyled>
                        <th>Name</th>
                        <th style={{ textAlign: "left" }}>Locks</th>
                        <th>Valid period</th>
                        <th></th>
                    </TableHeaderContentStyled>
                </thead>
                <tbody>
                    {codcardSearchResult?.length > 0 ? (
                        codcardSearchResult.map((item: any, idx: number) => {
                            return <Codecards key={idx} codecard={item} />;
                        })
                    ) : (
                        <tr>
                            <TableNoItemsMessageStyled>
                                <NoItemMessage type={NoItemMessageEnum.CODECARDS} />
                            </TableNoItemsMessageStyled>
                        </tr>
                    )}
                </tbody>
            </TableContentStyled>

            {isNotification && <FilterNotification handleClear={handleClear} />}
        </div>
    );
};

export const CodecardsPage = () => {
    const { organizationId } = useParams() as any;
    const { organizationBreadcrumbs } = useOrganizationBreadcrumbs(PageEnum.CODECARDS);
    const numberOrganization = parseInt(organizationId);
    const [resultUnit] = useQuery({
        query: GET_ORGANIZATION,
        variables: { id: numberOrganization },
    });

    const { data, fetching } = resultUnit || {};

    if (fetching) {
        return (
            <LoaderPageWrapper>
                <Loader />
            </LoaderPageWrapper>
        );
    }

    return (
        <PageContainer>
            <PageContentContainer>
                <ContentLayout
                    breadcrumbs={organizationBreadcrumbs}
                    label={data?.Organisation?.name}
                    openKey={MenuItemsEnum.Codecards}
                    ContentLayoutBody={<CodecardsLayoutBody />}
                    isBackPath
                />
            </PageContentContainer>
        </PageContainer>
    );
};
