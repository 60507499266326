import { ReactElement } from "react";
import { Modal } from "antd";

interface AcceptModalInterface {
    isOpen: boolean;
    onAccept: () => void;
    onCancel: () => void;
    title: string;
    description?: string;
}
export const AcceptModal = (props: AcceptModalInterface): ReactElement => {
    const { isOpen, onAccept, onCancel, title, description } = props;

    return (
        <Modal
            visible={isOpen}
            title={title}
            onOk={onAccept}
            onCancel={onCancel}
            okText="Accept"
            cancelText="Cancel"
        >
            {description}
        </Modal>
    );
};
