import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { FilterNotificationContainer } from "./filter-notification.styled";

interface FilterNotificationProps {
    handleClear: () => void;
}

export const FilterNotification = (props: FilterNotificationProps): ReactElement => {
    const { handleClear } = props;
    const { t } = useTranslation();

    return (
        <FilterNotificationContainer>
            <div>
                <h2>{t("filter_notification.title")}</h2>
                <button onClick={handleClear}>{t("filter_notification.button")}</button>
            </div>
            <p>{t("filter_notification.message")}</p>
        </FilterNotificationContainer>
    );
};
