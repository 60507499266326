import { useEffect } from "react";
import { renderToast } from "../components/toast/toast";

export const useRenderToast = (result: any): void => {
    useEffect(() => {
        const { error } = result;

        if (error) {
            renderToast({
                // TODO Move to enum (ex. ToastEventTypeEnum)
                type: "error",
                message: error?.message,
                description: error?.message,
            });

            return;
        }
    }, [result]);
};
