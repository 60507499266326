import { KeyValueLine } from "../../../components/key-value-line/key-value-line";
import { Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { CodecardLinkContainer } from "./codecard-link.styled";

interface CodecardLinkProps {
    url: string;
}

export const CodecardLink = (props: CodecardLinkProps): ReactElement => {
    const { url } = props;
    const { t } = useTranslation();
    const handleCopyCodecardUrl = () => {
        navigator.clipboard.writeText(url);
    };

    return (
        <>
            <KeyValueLine
                Key={<div>Codecard URL</div>}
                Value={
                    <CodecardLinkContainer>
                        <a href="#">{url}</a>
                        <Button onClick={handleCopyCodecardUrl}>
                            <CopyOutlined />
                            {t("buttons.copy")}
                        </Button>
                    </CodecardLinkContainer>
                }
            />
        </>
    );
};
