import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "urql";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

import { MenuItemsEnum } from "../../components/menu-component/menu.component";
import { ContentLayout } from "../../layouts/content-layout/content-layout.component";
import ContentHeader from "../../components/content-header/content-header.component";
import { SendCodecardEmailComponent } from "./send-codecard/send-codecard-email/send-codecard-email.component";
import { SendCodecardSMSComponent } from "./send-codecard/send-codecard-sms/send-codecard-sms.component";
import Loader from "../../components/loader/loader.component";

import { GET_CODECARD } from "../../graphql/queries/codecards/codecard.query";
import { GET_ORGANIZATION } from "../../graphql/queries/organizations/organizations.query";
import { SendCodecardPageBreadcrumbs } from "../../constants/breadcrumbs";
import { PageEnum } from "../../types/PagesEnum";
import { useOrganizationBreadcrumbs } from "../../hooks/useOrganizationBreadcrumbs";

import { LoaderPageWrapper } from "../../styles/loader-wrapper.styled";
import { PageContainer, PageContentContainer } from "../../styles/layouts";
import {
    SendCodecardBodyStyled,
    SendCodecardNotificationStyled,
} from "./send-codecard-page.styled";
import {
    SendCodecardButtons,
    SendCodecardStyled,
    SendCodecardWrapperStyled,
} from "./send-codecard/send-codecard.styled";

enum SendTypesEnum {
    SMS = "sms",
    EMAIL = "email",
}

const SendCodecardPageBody = (props: any) => {
    const [openTab, setOpenTab] = useState(SendTypesEnum.EMAIL);
    const { breadcrumbs } = props;
    const { codecardId } = useParams() as any;
    const { t } = useTranslation();

    const [resultCodecarad] = useQuery({
        query: GET_CODECARD,
        variables: { id: codecardId },
    });

    const { data } = resultCodecarad;

    const handleChangeEmailTab = () => {
        setOpenTab(SendTypesEnum.EMAIL);
    };

    const handleChangeSMSTab = () => {
        setOpenTab(SendTypesEnum.SMS);
    };

    return (
        <SendCodecardBodyStyled>
            <ContentHeader label={"Send codecard"} breadcrumbs={breadcrumbs} />
            <SendCodecardWrapperStyled>
                <SendCodecardStyled>
                    <div style={{ display: "flex", marginBottom: "15px" }}>
                        <Button
                            style={{
                                border:
                                    openTab === SendTypesEnum.EMAIL
                                        ? "1px solid #40a9ff"
                                        : "1px solid #d9d9d9",
                            }}
                            onClick={handleChangeEmailTab}
                        >
                            E-mail
                        </Button>
                        <Button
                            style={{
                                border:
                                    openTab !== SendTypesEnum.EMAIL
                                        ? "1px solid #40a9ff"
                                        : "1px solid #d9d9d9",
                            }}
                            onClick={handleChangeSMSTab}
                        >
                            SMS
                        </Button>
                    </div>
                    {openTab === SendTypesEnum.EMAIL ? (
                        <SendCodecardEmailComponent data={data} />
                    ) : (
                        <SendCodecardSMSComponent />
                    )}
                </SendCodecardStyled>
            </SendCodecardWrapperStyled>

            <SendCodecardButtons style={{ justifyContent: " space-between" }}>
                <SendCodecardNotificationStyled>
                    {openTab === SendTypesEnum.EMAIL && (
                        <>
                            <ExclamationCircleOutlined
                                style={{
                                    color: "white",
                                    background: "#1890FF",
                                    marginRight: "10px",
                                    width: "14px",
                                    height: "14px",
                                    borderRadius: "10px",
                                }}
                            />
                            <p>E-mail is not always encrypted.</p>
                        </>
                    )}
                    {openTab === SendTypesEnum.SMS && (
                        <>
                            <ExclamationCircleOutlined
                                style={{
                                    color: "white",
                                    background: "#1890FF",
                                    marginRight: "10px",
                                    width: "14px",
                                    height: "14px",
                                    borderRadius: "10px",
                                }}
                            />
                            <p>
                                SMS cost: 0.96 SEK. A SMS is 160 characters long. Longer messages
                                will be charged as several SMSs.
                            </p>
                        </>
                    )}
                </SendCodecardNotificationStyled>
                <div style={{ display: "flex" }}>
                    <Button>{t("buttons.cancel")}</Button>
                    <Button type="primary">{t("buttons.send")}</Button>
                </div>
            </SendCodecardButtons>
        </SendCodecardBodyStyled>
    );
};

export const SendCodecardPage = () => {
    const { organizationId, codecardId } = useParams() as any;
    const { organizationBreadcrumbs } = useOrganizationBreadcrumbs(PageEnum.CODECARD_SEND);
    const numberOrganization = parseInt(organizationId);

    const [resultUnit] = useQuery({
        query: GET_ORGANIZATION,
        variables: { id: numberOrganization },
    });
    const { data: dataOrganization, fetching: fetchingOrganization } = resultUnit;

    const [resultCodecard] = useQuery({
        query: GET_CODECARD,
        variables: { id: codecardId },
    });
    const { data: dataCodecard, fetching: fetchingCodecard } = resultCodecard;

    if (fetchingOrganization || fetchingCodecard) {
        return (
            <LoaderPageWrapper>
                <Loader />
            </LoaderPageWrapper>
        );
    }

    return (
        <PageContainer>
            <PageContentContainer>
                <ContentLayout
                    breadcrumbs={organizationBreadcrumbs}
                    label={dataOrganization?.Organisation?.name}
                    openKey={MenuItemsEnum.Codecards}
                    isBackPath
                    ContentLayoutBody={
                        <SendCodecardPageBody
                            breadcrumbs={SendCodecardPageBreadcrumbs(
                                organizationId,
                                codecardId,
                                codecardId,
                            )}
                            data={dataCodecard}
                            organizationId={organizationId}
                        />
                    }
                />
            </PageContentContainer>
        </PageContainer>
    );
};
