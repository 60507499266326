import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "urql";
import { renderToast } from "../../../components/toast/toast";
import { initialCreateCodecardFormValues } from "../../../constants/codecard";
import { CREATE_CODECARD } from "../../../graphql/mutations/codecard/codecard.mutations";
import { CREATE_KEY } from "../../../graphql/mutations/key/key.mutations";
import { useRouteNavigation } from "../../../hooks/useNavigationPatch";
import { RouteParamsInterface } from "../../../types/RouteParams.interface";
import { getRoundingDateTime } from "../../../utils/date";
import { CodecardFormEnum } from "../../../utils/enums/form.enum";
import { CreateCodecardType } from "./create-codecard.type";
import { useTranslation } from "react-i18next";

export const useCreateCodecardForm = (): any => {
    const [formValues, setFormValues] = useState<CreateCodecardType>(
        initialCreateCodecardFormValues,
    );

    const { t } = useTranslation();
    const [key, createKey] = useMutation(CREATE_KEY);
    const [codecard, createCodecard] = useMutation(CREATE_CODECARD);
    const { organizationId } = useParams() as RouteParamsInterface;

    const { navigateToCodecard } = useRouteNavigation();

    const handleFormChangeTargetValue = (key: string) => (event: any) => {
        setFormValues({
            ...formValues,
            [key]: event.target.value,
        });
    };

    const handleChangeDate = (key: string) => (_: any, dateStrings: string | Date) => {
        setFormValues({
            ...formValues,
            [key]: new Date(dateStrings),
        });
    };

    const handleChangeValue = (key: string) => (value: string | boolean) => {
        setFormValues({
            ...formValues,
            [key]: value,
        });
    };

    const handleSubmitCreateCodecardForm = async () => {
        const createCard = await createCodecard({
            organisation_id: parseInt(organizationId),
            name: formValues.name,
            valid_from: getRoundingDateTime(formValues.validFrom).toISOString(),
            valid_to: getRoundingDateTime(formValues.validTo).toISOString(),
            active: formValues.isActive,
        });

        if (createCard.data?.CreateCodecard?.id) {
            const prom = formValues.locks.map((lock: any) => {
                const lockId = Number.isInteger(lock) ? lock : lock.value;

                return createKey({
                    lock_id: lockId,
                    label: formValues.name,
                    valid_from: getRoundingDateTime(formValues.validFrom).toISOString(),
                    valid_to: getRoundingDateTime(formValues.validTo).toISOString(),
                    codecard_id: createCard.data?.CreateCodecard?.id,
                    notes: formValues.notes,
                });
            });
            await Promise.all(prom);
            if (!createCard.error && createCard?.data && !key?.error) {
                await renderToast({
                    type: "success",
                    message: `${t("create_codecard.success_created")}`,
                });
            }
        }
    };

    useEffect(() => {
        if (formValues.validFor !== "others") {
            const newDate = new Date(formValues.validFrom!);
            newDate.setMilliseconds((formValues.validFor! as number) * 60 * 60 * 1000);
            handleChangeDate(CodecardFormEnum.VALID_TO)(null, newDate);
        }
    }, [formValues?.validFor]);

    useEffect(() => {
        if (codecard.data?.CreateCodecard?.id) {
            navigateToCodecard(organizationId, codecard.data?.CreateCodecard?.id);
        }
    }, [codecard.data?.CreateCodecard?.id]);

    return {
        formValues,
        handleChangeDate,
        handleChangeValue,
        handleFormChangeTargetValue,
        handleSubmitCreateCodecardForm,
    };
};
