import { ReactElement } from "react";

import { t } from "i18next";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Input, TreeSelect, Switch } from "antd";
import TextArea from "antd/lib/input/TextArea";

import { CountriesSelect } from "../../../components/countries-select/countries-select";
import { FormValueLine } from "../../../components/form-value-line/form-value-line";

import { CreateSubUnitType } from "./create-sub-unit.type";
import { SubUnitFormEnum } from "../../../utils/enums/form.enum";
import { OrganisationInterface } from "../../../types/Organisation.interface";

import { CreateSubUnitWrap } from "../create-sub-unit-page.styled";

interface CreateSubUnitFormProps {
    formValues: CreateSubUnitType;
    onChangeValue: (key: string, value: any) => void;
    onChangeParent: (event: number) => void;
    onChangeNotification: (key: string, value: boolean) => void;
    onChangeCountry: (event: string) => void;
    organisations: OrganisationInterface[];
}

const getTreeNode = (title: string, value: string | number): any => {
    return { title, value };
};
const getNestedTreeNode = (title: string, value: string | number): any => {
    return {
        ...getTreeNode(title, value),
        children: [],
    };
};
const generateTreeStructure = (organisations: OrganisationInterface[], root?: any): any => {
    const treeStructure: any = root || [];

    organisations.forEach((org: OrganisationInterface, index: number) => {
        const { id, name, children } = org || {};

        if (!children?.length) {
            treeStructure.push(getTreeNode(name || "", id));
        } else {
            treeStructure.push(getNestedTreeNode(name || "", id));

            generateTreeStructure(children, treeStructure[index]?.children);
        }
    });

    return treeStructure;
};

export const CreateSubUnitForm = (props: CreateSubUnitFormProps): ReactElement => {
    const {
        organisations,
        formValues,
        onChangeValue,
        onChangeNotification,
        onChangeParent,
        onChangeCountry,
    } = props;

    const handleChangeParentUnit = (id: any): void => {
        onChangeParent(id);
    };

    return (
        <CreateSubUnitWrap>
            <FormValueLine
                Label={<div>{t("create_sub_unit.parent_unit")}</div>}
                Value={
                    <TreeSelect
                        style={{ width: "100%" }}
                        value={formValues?.parent || ""}
                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                        treeData={generateTreeStructure(organisations)}
                        placeholder="Please select"
                        treeDefaultExpandAll
                        onChange={handleChangeParentUnit}
                    />
                }
            />
            <FormValueLine
                Label={<div>{t("create_sub_unit.name")}</div>}
                Value={
                    <Input
                        value={formValues.name}
                        onChange={(event) => {
                            onChangeValue(SubUnitFormEnum.NAME, event);
                        }}
                    />
                }
            />
            <FormValueLine
                Label={<div>{t("create_sub_unit.address")}</div>}
                Value={
                    <Input
                        value={formValues.street}
                        onChange={(event) => {
                            onChangeValue(SubUnitFormEnum.STREET, event);
                        }}
                    />
                }
                Optional={"Optional"}
            />
            <FormValueLine
                Label={<div>{t("create_sub_unit.postcode")}</div>}
                Value={
                    <Input
                        value={formValues.postcode}
                        onChange={(event) => {
                            onChangeValue(SubUnitFormEnum.POSTCODE, event);
                        }}
                    />
                }
                Optional={"Optional"}
            />
            <FormValueLine
                Label={<div>{t("create_sub_unit.city")}</div>}
                Value={
                    <Input
                        value={formValues.city}
                        onChange={(event) => {
                            onChangeValue(SubUnitFormEnum.CITY, event);
                        }}
                    />
                }
                Optional={"Optional"}
            />
            <FormValueLine
                Label={<div>{t("create_sub_unit.country")}</div>}
                Value={
                    <CountriesSelect
                        key="country"
                        value={formValues?.country}
                        onChange={onChangeCountry}
                        style={{ width: "100%", marginBottom: 5 }}
                    />
                }
                Optional={"Optional"}
            />
            <FormValueLine
                Label={<div>{t("create_sub_unit.org_number")}</div>}
                Value={
                    <Input
                        value={formValues.orgNumber!}
                        onChange={(event) => {
                            onChangeValue(SubUnitFormEnum.ORG_NUMBER, event);
                        }}
                    />
                }
                Optional={"Optional"}
            />
            <FormValueLine
                Label={<div>{t("create_sub_unit.notes")}</div>}
                Value={
                    <TextArea
                        rows={4}
                        style={{ width: "100%" }}
                        value={formValues.notes}
                        onChange={(event) => {
                            onChangeValue(SubUnitFormEnum.NOTES, event);
                        }}
                    />
                }
                Optional={"Optional"}
            />
            <FormValueLine
                alignItems="center"
                Label={<div>{t("create_sub_unit.notification_email")}</div>}
                Value={
                    <Switch
                        onChange={(value) => {
                            onChangeNotification(SubUnitFormEnum.IS_NOTIFICATION_EMAIL, value);
                        }}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked={formValues.isCreateNotificationEmail}
                    />
                }
            />
            <FormValueLine
                alignItems="center"
                Label={<div>{t("create_sub_unit.notification_sms")}</div>}
                Value={
                    <Switch
                        onChange={(value) => {
                            onChangeNotification(SubUnitFormEnum.IS_NOTIFICATION_SMS, value);
                        }}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked={formValues.isCreateNotificationSms}
                    />
                }
            />
        </CreateSubUnitWrap>
    );
};
