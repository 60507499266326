import { FunctionComponent } from "react";

import { DesktopTopNavigationBar } from "./desktop-top-navigation-bar/desktop-top-navigation-bar.component";
import { MobileTopNavigationBar } from "./mobile-top-navigation-bar/mobile-top-navigation-bar.component";
import { TabletTopNavigationBar } from "./tablet-top-navigation-bar/tablet-top-navigation-bar.component";

import { SupportedDevicesEnum } from "../../types/SupportedDevicesEnum";

// TODO Move to types
export const topNavigationBarAdaptiveConfig: Record<SupportedDevicesEnum, FunctionComponent> = {
    [SupportedDevicesEnum.DESKTOP]: DesktopTopNavigationBar,
    [SupportedDevicesEnum.TABLET]: TabletTopNavigationBar,
    [SupportedDevicesEnum.MOBILE]: MobileTopNavigationBar,
};
