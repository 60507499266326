import styled from "styled-components";
import { colors } from "./colors";

export const TableContentStyled = styled.table`
    width: 100%;
    position: relative;
`;

export const TableHeaderContentStyled = styled.tr`
    th {
        padding: 12px 5px 15px 5px;
        text-align: center;
        justify-content: center;
        &:first-child {
            padding-left: 24px !important;
            text-align: left;
        }
        &:last-child {
            padding-right: 24px !important;
            text-align: right;
        }
    }
`;

export const TableBodyContentStyled = styled.tr`
    border: 1px solid #f0f0f0;
    color: #1890ff;
    font-size: 16px;
    line-height: 24px;
    cursor: default;
    p {
        color: ${colors.graySeven};
        font-size: 12px;
        line-height: 21px;
    }
    button {
        padding: 2px 10px;
        color: #1890ff;
        font-size: 14px;
        border: 1px solid #1890ff;
        border-radius: 3px;
        z-index: 100;
        :hover {
            cursor: pointer;
            box-shadow: 0 0 2px #1890ff;
        }
    }
    td {
        padding: 15px 5px 15px 5px;
        text-align: center;
        justify-content: center;
    }
    td {
        &:first-child {
            padding-left: 24px !important;
            text-align: left !important;
        }
        &:last-child {
            padding-right: 24px !important;
            text-align: right !important;
        }
    }
`;

export const TableNoItemsMessageStyled = styled.div`
    position: absolute;
    left: 50%;
    font-size: 20px;
    margin-top: 20px;
    text-align: center;
    transform: translate(-50%, 20px);
`;
