export interface DetailsLocationInterface {
    country: string;
    zip: string;
    city: string;
    street: string;
}

export enum DetailsLocationFieldsEnum {
    country = "country",
    zip = "zip",
    city = "city",
    street = "street",
}
