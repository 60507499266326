export const initialCreateSubUnitFormValues = {
    parent: null,
    name: "",
    orgNumber: "" || null,
    street: "",
    postcode: "",
    city: "",
    country: "",
    notes: "",
    isCreateNotificationEmail: true,
    isCreateNotificationSms: true,
};
