import { observer } from "mobx-react-lite";
import React, { FC, ReactElement } from "react";
import { Stores, useStore } from "../stores/store";
import { WindowDimensionsStore } from "../stores/windowDimensionsStore";
import { SupportedDevicesEnum } from "../types/SupportedDevicesEnum";
import { WindowDimensionsInterface } from "../types/WindowDimensions.interface";

export interface WithAdaptiveProps {
    windowDimensions?: WindowDimensionsInterface;
    deviceType?: SupportedDevicesEnum;
}

export const withAdaptiveContainer = <T extends WithAdaptiveProps = WithAdaptiveProps>(
    WrappedComponent: React.FC<T>,
): FC => {
    return observer((props: any): ReactElement => {
        const { windowDimensions, deviceType } = useStore(
            Stores.WINDOW_DIMENSIONS_STORE,
        ) as WindowDimensionsStore;

        return (
            <WrappedComponent
                {...props}
                deviceType={deviceType}
                windowDimensions={windowDimensions}
            />
        );
    });
};
