import { ReactElement } from "react";

import { useParams } from "react-router-dom";
import { useQuery } from "urql";
import { t } from "i18next";

import { useOrganizationBreadcrumbs } from "../../hooks/useOrganizationBreadcrumbs";
import { getOrganizationUserPageBreadcrumbs } from "../../constants/breadcrumbs";

import ContentHeader from "../../components/content-header/content-header.component";
import Loader from "../../components/loader/loader.component";
import { KeyValueLine } from "../../components/key-value-line/key-value-line";

import { GET_USER_BY_ID } from "../../graphql/queries/users/user.query";

import { MenuItemsEnum } from "../../components/menu-component/menu.types";
import { PageEnum } from "../../types/PagesEnum";
import { RouteParamsInterface } from "../../types/RouteParams.interface";
import { UserInterface } from "../../types/User.interface";

import { PageContainer, PageContentContainer } from "../../styles/layouts";
import { ContentLayout } from "../../layouts/content-layout/content-layout.component";

import { LoaderPageWrapper } from "../../styles/loader-wrapper.styled";
import { LocksState } from "../lock-page/info/info.styled";

interface OrganizationUserPageBodyProps {
    user?: UserInterface;
    organizationId: string;
}

const OrganizationUserPageBody = (props: OrganizationUserPageBodyProps): ReactElement => {
    const { user, organizationId } = props;
    const createdAt = new Date(user?.created_at!).toDateString();
    const updatedAt = new Date(user?.updated_at!).toDateString();

    return (
        <div style={{ width: "100%" }}>
            <ContentHeader
                breadcrumbs={getOrganizationUserPageBreadcrumbs(organizationId, user?.name!)}
                label={user?.name!}
            />

            <div style={{ width: "100%" }}>
                <KeyValueLine
                    Key={<div>{t("user_page.name")}</div>}
                    Value={<div>{user?.name}</div>}
                />

                <KeyValueLine
                    Key={<div>{t("user_page.email")}</div>}
                    Value={<div>{user?.email}</div>}
                />

                <KeyValueLine
                    Key={<div>{t("user_page.staff")}</div>}
                    Value={
                        <div>
                            <LocksState state={user?.staff} />
                        </div>
                    }
                />

                <KeyValueLine
                    Key={<div>{t("user_page.language")}</div>}
                    Value={<div>{user?.language}</div>}
                />

                <KeyValueLine
                    Key={<div>{t("user_page.createdAt")}</div>}
                    Value={<div>{createdAt}</div>}
                />

                <KeyValueLine
                    Key={<div>{t("user_page.updatedAt")}</div>}
                    Value={<div>{updatedAt}</div>}
                />
            </div>
        </div>
    );
};

export const OrganizationUserPage = () => {
    const { organizationBreadcrumbs } = useOrganizationBreadcrumbs(PageEnum.USERS);
    const { organizationId, userId } = useParams() as RouteParamsInterface;
    const numberUserId = parseInt(userId!);

    const [result] = useQuery({
        query: GET_USER_BY_ID,
        variables: {
            id: numberUserId,
        },
    });

    const { data, fetching } = result || {};

    if (fetching) {
        return (
            <LoaderPageWrapper>
                <Loader />
            </LoaderPageWrapper>
        );
    }

    return (
        <PageContainer>
            <PageContentContainer>
                <ContentLayout
                    label={data?.User.name}
                    openKey={MenuItemsEnum.Users}
                    isBackPath
                    breadcrumbs={organizationBreadcrumbs}
                    ContentLayoutBody={
                        <OrganizationUserPageBody
                            user={data?.User}
                            organizationId={organizationId}
                        />
                    }
                />
            </PageContentContainer>
        </PageContainer>
    );
};
