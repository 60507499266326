import React, { ReactElement } from "react";
import { ContentLayout } from "../../layouts/content-layout/content-layout.component";
import { PageContentContainer, PageContainer } from "../../styles/layouts";
import { useParams } from "react-router-dom";
import ContentHeader from "../../components/content-header/content-header.component";
import { useQuery } from "urql";
import { GET_ORGANIZATION } from "../../graphql/queries/organizations/organizations.query";
import { CreateCodecardPageBreadcrumbs } from "../../constants/breadcrumbs";
import { CreateCodecard } from "./create-codecard/create-codecard.component";
import Loader from "../../components/loader/loader.component";
import { LoaderPageWrapper } from "../../styles/loader-wrapper.styled";
import { useOrganizationBreadcrumbs } from "../../hooks/useOrganizationBreadcrumbs";
import { MenuItemsEnum } from "../../components/menu-component/menu.component";
import { PageEnum } from "../../types/PagesEnum";

interface CreateCodecardPageBody {
    organizationId: string;
}

const CreateCodecardPageBody = (props: CreateCodecardPageBody): ReactElement => {
    const { organizationId } = props;

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <ContentHeader
                label={"Create codecard"}
                breadcrumbs={CreateCodecardPageBreadcrumbs(organizationId)}
            />

            <CreateCodecard />
        </div>
    );
};
interface CreateCodecardPageProps {
    isLock?: boolean;
}

export const CreateCodecardPage: React.FC<CreateCodecardPageProps> = () => {
    const { organizationId } = useParams() as any;
    const { organizationBreadcrumbs } = useOrganizationBreadcrumbs(PageEnum.CREATE_CODECARD);
    const numberOrganization = parseInt(organizationId);

    const [resultUnit] = useQuery({
        query: GET_ORGANIZATION,
        variables: { id: numberOrganization },
    });
    const { data: dataOrganization, fetching: fetchingOrganization } = resultUnit;

    if (fetchingOrganization) {
        return (
            <LoaderPageWrapper>
                <Loader />
            </LoaderPageWrapper>
        );
    }

    return (
        <PageContainer>
            <PageContentContainer>
                <ContentLayout
                    breadcrumbs={organizationBreadcrumbs}
                    label={dataOrganization?.Organisation?.name}
                    openKey={MenuItemsEnum.Codecards}
                    isBackPath
                    ContentLayoutBody={<CreateCodecardPageBody organizationId={organizationId} />}
                />
            </PageContentContainer>
        </PageContainer>
    );
};
