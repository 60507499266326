import React, { ReactElement } from "react";

import { ContentLayout } from "../../layouts/content-layout/content-layout.component";
import Loader from "../../components/loader/loader.component";
import { useOrganizationBreadcrumbs } from "../../hooks/useOrganizationBreadcrumbs";
import { MenuItemsEnum } from "../../components/menu-component/menu.component";
import { InDevelopmentMessage } from "../../components/messages/in-development/in-development-message.component";

import { PageEnum } from "../../types/PagesEnum";
import { useOrganization } from "../../hooks/useOrganization";

import { PageContainer, PageContentContainer } from "../../styles/layouts";
import { LoaderPageWrapper } from "../../styles/loader-wrapper.styled";

const SettingsLayoutBody = (): ReactElement => {
    return (
        <div style={{ width: "100%" }}>
            <div>
                <InDevelopmentMessage />
            </div>
        </div>
    );
};

export const SettingsPage = (): ReactElement => {
    const { fetching } = useOrganization();
    const { organizationBreadcrumbs } = useOrganizationBreadcrumbs(PageEnum.SETTINGS);

    if (fetching) {
        return (
            <LoaderPageWrapper>
                <Loader />
            </LoaderPageWrapper>
        );
    }

    return (
        <PageContainer>
            <PageContentContainer>
                <ContentLayout
                    breadcrumbs={organizationBreadcrumbs}
                    openKey={MenuItemsEnum.Settings}
                    ContentLayoutBody={<SettingsLayoutBody />}
                    isBackPath
                />
            </PageContentContainer>
        </PageContainer>
    );
};
