import React, { useEffect, useState } from "react";
import { ContentLayout } from "../../layouts/content-layout/content-layout.component";
import { PageContentContainer, PageContainer } from "../../styles/layouts";
import { useParams } from "react-router-dom";
import { useRouteNavigation } from "../../hooks/useNavigationPatch";
import { InfoComponent } from "./info/info.component";
import ContentHeader from "../../components/content-header/content-header.component";
import { useMutation, useQuery } from "urql";
import { GET_ORGANIZATION } from "../../graphql/queries/organizations/organizations.query";
import { GET_LOCK } from "../../graphql/queries/locks/lock.query";
import { LockPageBreadcrumbs } from "../../constants/breadcrumbs";
import { Button } from "antd";
import { RouteParamsInterface } from "../../types/RouteParams.interface";
import Loader from "../../components/loader/loader.component";
import { LoaderContentWrapper } from "../../styles/loader-wrapper.styled";
import { useOrganizationBreadcrumbs } from "../../hooks/useOrganizationBreadcrumbs";
import { MenuItemsEnum } from "../../components/menu-component/menu.component";
import { PageEnum } from "../../types/PagesEnum";
import { AcceptModal } from "../../components/modals/accept-modal/accept-modal.component";
import { REMOVE_LOCK } from "../../graphql/mutations/lock/locks.mutation";
import { renderToast } from "../../components/toast/toast";

const LockPageBody = (props: any) => {
    const { organizationId, lockId, data } = props || {};
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [removableLock, RemoveLock] = useMutation(REMOVE_LOCK);
    const { navigateToCreateCodecard, navigateToEditLock, navigateToLocks } = useRouteNavigation();
    const numberLock = parseInt(lockId);

    const handleNavigateToCreateCodecard = () => {
        navigateToCreateCodecard(organizationId, lockId);
    };

    const handleNavigateToEditLock = () => {
        navigateToEditLock(organizationId, lockId);
    };

    const handleOpenModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleDeleteLock = async (): Promise<void> => {
        await RemoveLock({ id: numberLock });
    };

    useEffect(() => {
        if (removableLock.data?.RemoveLock?.id) {
            handleOpenModal();
            navigateToLocks(organizationId);
        }
    }, [removableLock]);

    useEffect(() => {
        if (!removableLock.error && removableLock?.data) {
            renderToast({
                type: "success",
                message: "Lock removed",
            });
        }
    }, [removableLock?.error, removableLock?.data]);

    const buttonsBlock = () => {
        return (
            <>
                <Button onClick={handleOpenModal}>Remove</Button>
                <Button onClick={handleNavigateToEditLock}>Edit</Button>
                <div onClick={handleNavigateToCreateCodecard}>
                    <Button type="primary">Create codecard</Button>
                </div>
            </>
        );
    };

    const { Lock: lock } = data || {};

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
            }}
        >
            <ContentHeader
                breadcrumbs={LockPageBreadcrumbs(
                    organizationId,
                    lock?.label ? lock?.label : lock?.type,
                )}
                label={lock?.label ? lock?.label : lock?.type}
                buttonsBlock={buttonsBlock}
            />
            <InfoComponent data={lock} organizationId={organizationId} />
            <AcceptModal
                title="Do you really want to delete lock?"
                isOpen={isModalOpen}
                description="It will be impossible to undo the action"
                onAccept={handleDeleteLock}
                onCancel={handleOpenModal}
            />
        </div>
    );
};

export const LockPage = () => {
    const { organizationId, lockId } = useParams() as RouteParamsInterface;
    const { organizationBreadcrumbs } = useOrganizationBreadcrumbs(PageEnum.LOCKS);
    const numberOrganization = parseInt(organizationId);
    const numberLockId = parseInt(lockId as string);

    const [resultUnit] = useQuery({
        query: GET_ORGANIZATION,
        variables: { id: numberOrganization },
    });
    const { data: dataOrganization, fetching: fetchingOrganization } = resultUnit;

    const [resultLock] = useQuery({
        query: GET_LOCK,
        variables: { id: numberLockId },
    });
    const { data: dataLock, fetching: fetchingLock } = resultLock;

    if (fetchingOrganization || fetchingLock) {
        return (
            <LoaderContentWrapper>
                <Loader />
            </LoaderContentWrapper>
        );
    }

    return (
        <PageContainer>
            <PageContentContainer>
                <ContentLayout
                    label={dataOrganization?.Organisation.name}
                    openKey={MenuItemsEnum.Locks}
                    isBackPath
                    breadcrumbs={organizationBreadcrumbs}
                    ContentLayoutBody={
                        <LockPageBody
                            data={dataLock}
                            organizationId={organizationId}
                            lockId={lockId}
                        />
                    }
                />
            </PageContentContainer>
        </PageContainer>
    );
};
