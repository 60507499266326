interface ColorsInterface {
    white: string;
    dark: string;
    gray: string;
    borderGray: string;
    green: string;
    lightGreen: string;
    backgroundColor: string;
    cardBackgroundColor: string;
    activeGreen: string;
    calendulaGoldThree: string;
    calendulaGoldOne: string;
    grayNine: string;
    graySeven: string;
    red: string;
    lightGray: string;
    grayEight: string;
}

export const colors: ColorsInterface = {
    white: "#fff",
    gray: "#F5F5F5",
    borderGray: "#F0F0F0",
    dark: "#000000",
    green: "#42DE80",
    lightGreen: "#E5FDDC",
    backgroundColor: "#E1E1E1",
    cardBackgroundColor: "#FAFAFA",
    activeGreen: "#52C41A",
    calendulaGoldThree: "#FFE58F",
    calendulaGoldOne: "#FFFBE6",
    grayNine: "#262626",
    graySeven: "#8C8C8C",
    red: "#FF0000",
    lightGray: "#BFBFBF",
    grayEight: "#595959",
};
