import React from "react";
import { SendDataContainer } from "../send-codecard.styled";
import { Input, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useTranslation } from "react-i18next";

export const SendCodecardSMSComponent = () => {
    const { t } = useTranslation();
    const { Option } = Select;
    const children: React.ReactNode[] = [];

    for (let i = 10; i < 36; i++) {
        children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
    }

    const handleChange = () => {
        // ...
    };

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <SendDataContainer style={{ marginBottom: "0" }}>
                    {t("send_codecard.labels.codecard")}
                    <Input placeholder="E.g. “Carpenter”" />
                </SendDataContainer>
                <p> {t("Type to search for another codecard")}</p>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
                <SendDataContainer style={{ marginBottom: "0" }}>
                    {t("send_codecard.labels.codecard")}
                    <Input />
                </SendDataContainer>
                <p> {t("Including country code (ex +4670123456)")}</p>
            </div>

            <SendDataContainer>
                {t("Use message template:")}
                <Select
                    showArrow
                    mode="tags"
                    style={{ width: "355px", marginLeft: "10px" }}
                    onChange={handleChange}
                    placeholder="Select a template"
                    tokenSeparators={[","]}
                >
                    {children}
                </Select>
            </SendDataContainer>

            <SendDataContainer>
                {t("send_codecard.labels.content")}
                <TextArea rows={4} style={{ width: "355px", marginLeft: "10px" }} />
            </SendDataContainer>
        </>
    );
};
