import { makeAutoObservable } from "mobx";
import { BreadcrumbsInterface } from "../types/Breadcrumbs.interface";

export class ApplicationStore {
    public isMobileNavigationOpen = false;
    public organizationBreadcrumbs: BreadcrumbsInterface[] = [];
    public pageLabel = "";
    public extraHeaderButton: any = null;

    constructor() {
        this.setIsMobileNavigationOpen = this.setIsMobileNavigationOpen.bind(this);
        this.setOrganizationBreadcrumbs = this.setOrganizationBreadcrumbs.bind(this);
        this.setCurrentPageLabel = this.setCurrentPageLabel.bind(this);
        this.setExtraHeaderButton = this.setExtraHeaderButton.bind(this);

        makeAutoObservable(this);
    }

    setIsMobileNavigationOpen(isMobileNavigationOpen: boolean): void {
        this.isMobileNavigationOpen = isMobileNavigationOpen;
    }

    setOrganizationBreadcrumbs(organizationBreadcrumbs: BreadcrumbsInterface[] = []): void {
        this.organizationBreadcrumbs = organizationBreadcrumbs;
    }

    setCurrentPageLabel(label: string): void {
        this.pageLabel = label;
    }

    setExtraHeaderButton(extraHeaderButton: any = null): void {
        this.extraHeaderButton = extraHeaderButton;
    }
}
