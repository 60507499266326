export const CREATE_ORGANIZATION = `
    mutation (
      $parent: Int,
      $name: String!,
      $email: String,
      $orgNumber: String,
      $phone: String,
      $street: String,
      $zip: String,
      $city: String,
      $contactName: String,
      $contactPhone: String,
      $contactEmail: String,
      $notes: String,
      $keyCreateNotificationEmail: Boolean!,
      $keyCreateNotificationSms: Boolean!,
      ){
        CreateOrganisation (create :{
          parent: $parent,
          name: $name,
          email: $email,
          org_number: $orgNumber,
          phone: $phone,
          street: $street,
          zip: $zip,
          city: $city,
          contact_name: $contactName,
          contact_phone: $contactPhone,
          contact_email: $contactEmail,
          notes: $notes,
          key_create_notification_email: $keyCreateNotificationEmail,
          key_create_notification_sms: $keyCreateNotificationSms,
        }) {   
            id,
            email,
        }
    }
`;

export const UPDATE_ORGANIZATION_old = `
    mutation ($id: Int!, $changes: OrganisationChanges!) {
        UpdateOrganisation (
          id: $id,
          changes: {
            parent: $parent,
            name: $name,
            email: $email,
            org_number: $org_number,
            phone: $phone,
            street: $street,
            zip: $zip,
            city: $city,
            country: $country,
            contact_name: $contact_name,
            contact_phone: $contact_phone,
            contact_email: $contact_email,
            notes: $notes,
            key_create_notification_email: $key_create_notification_email,
            key_create_notification_sms: $key_create_notification_sms
        }
      ) {
            id,
            parent {
                id,
                parent {
                    id,
                    name
                },
                name
            },
            name,
            street,
            zip,
            city,
            country,
            org_number,
            email,
            created_by {
				id,
				name,
				email,
				oldEmail,
				staff,
				language,
				created_at,
				updated_at,
			},
            created_at,
            updated_at,
            updated_by {
				id,
				name,
				email,
				oldEmail,
				staff,
				language,
				created_at,
				updated_at,
			},
            notes,
            phone,
            key_create_notification_email,
            key_create_notification_sms,
            sirvoy_property_id,
            users {
              id,
              name,
              email,
              oldEmail,
              staff,
              language,
              created_at,
              updated_at,
            },
            locks {
              id,
              serial_number,
              battery_change,
            },
            codecards {
              id,
              organisation_id {
                id
              }
            },
            messagepresets {
                id,
                new_id {
                  id
                },
                label,
                header,
                text,
                organisation_id {
                  id
                },
                created_by {
                  id,
                  name,
                  email,
                  oldEmail,
                  staff,
                  language,
                  created_at,
                  updated_at,
                },
                created_at,
                updated_by {
                  id,
                  name,
                  email,
                  oldEmail,
                  staff,
                  language,
                  created_at,
                  updated_at,
                },
                updated_at
            }
        }
    }

`;

export const UPDATE_ORGANIZATION_NAME = `
    mutation (
      $id: Int!,
      $name: String!
    ) {
        UpdateOrganisation (
          id: $id,
          changes: {
            name: $name,
          }
      ) {
            id,
            parent {
                id,
                name
            },
            name,
            street,
            zip,
            city,
            country,
            org_number,
            email,
            created_at,
            updated_at,
            notes,
         
        }
}`;

export const UPDATE_ORGANIZATION_LOCATION = `
  mutation (
    $id: Int!,
    $zip: String,
    $street: String,
    $city: String
  ) {
      UpdateOrganisation (
        id: $id,
        changes: {
          zip: $zip,
          city: $city,
          street: $street,
          country: null
        }
    ) {
          id,
          parent {
              id,
              name
          },
          name,
          street,
          zip,
          city,
          country,
          org_number,
          email,
          created_at,
          updated_at,
          notes,
      }
}`;

export const UPDATE_ORGANIZATION_LOCATION_WITH_COUNTRY = (country: string): string => `
  mutation (
    $id: Int!,
    $zip: String,
    $street: String,
    $city: String
  ) {
      UpdateOrganisation (
        id: $id,
        changes: {
          zip: $zip,
          city: $city,
          street: $street,
          country: ${country}
        }
    ) {
          id,
          parent {
              id,
              name
          },
          name,
          street,
          zip,
          city,
          country,
          org_number,
          email,
          created_at,
          updated_at,
          notes,
      }
}`;

export const REMOVE_ORGANIZATION = `
    mutation ($id: Int) {
        RemoveOrganisation(id: $id) 
    }
`;
