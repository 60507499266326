import React, { useState } from "react";
import { Button, Checkbox, Input, Select } from "antd";
import { BranchesOutlined, KeyOutlined } from "@ant-design/icons";
import { Params, useParams } from "react-router-dom";

import { PageContainer, PageContentContainer } from "../../styles/layouts";
import { ContentLayout } from "../../layouts/content-layout/content-layout.component";
import { Label } from "./label/label.component";

import {
    AddUserContainer,
    CheckboxContainer,
    FieldsIcon,
    PermissionsContainer,
    FieldsContainer,
    FormWithLabelsButtonsContainer,
    FormWithLabelsContainer,
    LabelsContainer,
} from "./add-user-page.styled";
import { useMutation } from "urql";
import { ADD_USER } from "../../graphql/mutations/users/users.mutations";
import { ADD_PERMISSION } from "../../graphql/mutations/permission/permission.mutation";
import { useRouteNavigation } from "../../hooks/useNavigationPatch";
import { renderToast } from "../../components/toast/toast";

interface FormAddUserValues {
    name: string;
    surname: string;
    email: string;
    language: string;
    staff: boolean;
    permission: number[];
}

export const AddLockPageBody = () => {
    // eslint-disable-next-line
    const [user, addUser] = useMutation(ADD_USER);
    const [permission, addPermission] = useMutation(ADD_PERMISSION);
    const [formValues, setFormValues] = useState<FormAddUserValues>({
        name: "",
        surname: "",
        email: "",
        language: "",
        staff: false,
        permission: [],
    });
    const { organizationId }: Params = useParams();
    const { navigateToOrganizationUser } = useRouteNavigation();

    const languages = [
        { label: "EN", value: "en" },
        { label: "SV", value: "sv" },
    ];

    const handleFormChange = (controlName: keyof FormAddUserValues) => (value: any) => {
        setFormValues({
            ...formValues,
            [controlName]: value,
        });
    };

    const generatePermission = (isChecked: boolean, permissionType: number): number[] => {
        let value = [];
        if (isChecked) {
            value = [...formValues.permission, permissionType];
        } else {
            value = formValues.permission.filter((permission) => permission !== permissionType);
        }

        return value;
    };

    const handleSubmitAddUserForm = async () => {
        const createUser = await addUser({
            name: formValues.name,
            surname: formValues.surname,
            email: formValues.email,
            language: formValues.language,
            organisation: parseInt(organizationId as string),
            staff: false,
        });

        const { data } = createUser || {};

        const createPermission = formValues.permission.map((permission: number) => {
            return addPermission({
                organisation_id: parseInt(organizationId as string),
                user_id: data?.addUser?.id,
                type: permission,
            });
        });
        await Promise.all(createPermission);

        if (!createUser.error && createUser?.data && !permission?.error) {
            await renderToast({
                type: "success",
                message: "User created",
            });
        }

        if (createUser.data?.addUser?.id) {
            // eslint-disable-next-line
            navigateToOrganizationUser(organizationId!, createUser.data.addUser.id);
        }
    };

    return (
        <AddUserContainer>
            <FormWithLabelsContainer>
                <LabelsContainer>
                    <Label label="Name:" />
                    <Label label="Surname:" />
                    <Label label="Email:" />
                    <Label label="Language:" />
                </LabelsContainer>
                <FieldsContainer>
                    <Input
                        value={formValues.name}
                        onChange={(e) => handleFormChange("name")(e.target.value)}
                    />
                    <Input
                        value={formValues.surname}
                        onChange={(e) => handleFormChange("surname")(e.target.value)}
                    />
                    <Input
                        value={formValues.email}
                        onChange={(e) => handleFormChange("email")(e.target.value)}
                    />
                    <Select
                        defaultValue="EN"
                        value={formValues.language}
                        style={{ width: 220 }}
                        onChange={handleFormChange("language")}
                        options={languages}
                    ></Select>
                    <PermissionsContainer>
                        <FieldsIcon>
                            <KeyOutlined style={{ paddingLeft: "0px", fontSize: "20px" }} />
                            <span> Keys:</span>
                        </FieldsIcon>
                        <CheckboxContainer>
                            <Checkbox
                                onChange={(e) => {
                                    const value = generatePermission(e.target.checked, 41);
                                    handleFormChange("permission")(value);
                                }}
                            >
                                Create
                            </Checkbox>
                            <Checkbox
                                onChange={(e) => {
                                    const value = generatePermission(e.target.checked, 42);
                                    handleFormChange("permission")(value);
                                }}
                            >
                                Update
                            </Checkbox>
                            <p>
                                <Checkbox
                                    onChange={(e) => {
                                        const value = generatePermission(e.target.checked, 43);
                                        handleFormChange("permission")(value);
                                    }}
                                >
                                    Remove
                                </Checkbox>
                            </p>
                        </CheckboxContainer>
                        <FieldsIcon>
                            <BranchesOutlined style={{ paddingLeft: "0px", fontSize: "20px" }} />
                            <span> Organizations:</span>
                        </FieldsIcon>
                        <CheckboxContainer>
                            <Checkbox onChange={() => {}}>Create</Checkbox>
                            <Checkbox onChange={() => {}}>Update</Checkbox>
                            <p>
                                <Checkbox onChange={() => {}}>Remove</Checkbox>
                            </p>
                        </CheckboxContainer>
                    </PermissionsContainer>

                    <FormWithLabelsButtonsContainer>
                        <Button htmlType="submit" type="primary" onClick={handleSubmitAddUserForm}>
                            Add
                        </Button>
                        <Button type="text">Cancel</Button>
                    </FormWithLabelsButtonsContainer>
                </FieldsContainer>
            </FormWithLabelsContainer>
        </AddUserContainer>
    );
};

export const AddUserPage = () => {
    return (
        <PageContainer>
            <PageContentContainer>
                {/* @ts-ignore */}
                <ContentLayout ContentLayoutBody={<AddLockPageBody />} />
            </PageContentContainer>
        </PageContainer>
    );
};
