import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const MobileMenuStyledContainer = styled.div`
    width: 100%;

    &:first-child {
        border-top: 1px solid ${colors.borderGray};
    }
`;

export const MobileMenuItemStyledContainer = styled.div`
    padding: 13px 17px;
    min-width: 320px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.borderGray};
    width: 100%;
    background-color: ${colors.white};

    .menu-item-details svg {
        font-size: 20px;
        margin-right: 10px;
        margin-bottom: -2px;
    }
`;
