import styled from "styled-components";
import { colors } from "../../styles/colors";

export const ContentLayoutBodyStyled = styled.div`
    display: flex;
    margin: 0 10px 100px;
    box-shadow: inset 0 0 5px #f0f0f0;
`;
export const ContentLayoutWrapper = styled.div`
    width: 100%;
`;

export const ContentLayoutHeaderStyledContainer = styled.div<{ isMobile: boolean }>`
    min-width: 100%;
    display: flex;
    background: ${({ isMobile }) => (isMobile ? colors.dark : "none")};
`;
