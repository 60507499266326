export const isIdInChildren = (item: any, id: number): boolean => {
    const extractChildren = (element: any) => [...element.children];

    const flatten = (children: any, extractChildren: any) => {
        return Array.prototype.concat.apply(
            children,
            children.map((element: any) =>
                flatten(extractChildren(element) || [], extractChildren),
            ),
        );
    };

    const flat = flatten(extractChildren(item), extractChildren).map(({ id }) => id);

    return flat.includes(id);
};
