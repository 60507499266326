import { observer } from "mobx-react-lite";
import { ReactElement } from "react";
import { useQuery } from "urql";
import { GET_USER } from "../../graphql/queries/users/user.query";
import { withAdaptiveContainer, WithAdaptiveProps } from "../../HOC/withAdaptiveContainer";
import { useRenderToast } from "../../hooks/useRenderToast";

import { OrganisationsStore } from "../../stores/organisationsStore";
import { Stores, useStore } from "../../stores/store";
import { LoaderNavigateWrapper } from "../../styles/loader-wrapper.styled";
import Loader from "../loader/loader.component";
import { Navigation } from "./navigation.component";
import { useSelectedMenuItem } from "./useSelectedMenuItem";

const { ORGANISATION_STORE } = Stores;

const NavigationContainer = observer((props: WithAdaptiveProps): ReactElement => {
    const { deviceType } = props;
    const { organisations = [] } = useStore(ORGANISATION_STORE) as OrganisationsStore;
    const { selectedItem, setSelectedItem } = useSelectedMenuItem();

    const [result] = useQuery({ query: GET_USER });
    const { fetching } = result;

    useRenderToast(result);

    if (fetching) {
        return (
            <LoaderNavigateWrapper>
                <Loader />
            </LoaderNavigateWrapper>
        );
    }

    if (!organisations) {
        return <></>;
    }

    return (
        <Navigation
            deviceType={deviceType}
            organisations={organisations}
            selectedItem={selectedItem}
            onChangeSelectedItem={setSelectedItem}
        />
    );
});

const NavigationAdaptiveContainer = withAdaptiveContainer(NavigationContainer);

export { NavigationAdaptiveContainer };
