import styled from "styled-components";

export const UserContainer = styled.div`
    display: flex;
    max-width: 578px;
    align-items: center;
    float: right;
    justify-content: flex-end;
    color: white;
`;
export const UserInfo = styled.div`
    display: flex;
    justify-content: space-around;
    max-width: 80%;
    align-items: center;
    color: white;
    float: right;
    h3 {
        font-size: 16px;
        color: white;
    }
    p {
        font-size: 12px;
    }
`;
export const AvatarWrapper = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 40px;
`;
