import styled from "styled-components";
import { colors } from "../../styles/colors";

export const TitleContainer = styled.div`
    margin: 30px 0 20px 40px;
    font-size: 24px;
`;

export const ItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    padding: 0 20px 30px 20px;
`;

export const LocksItemStyled = styled.div`
    padding: 16px 24px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    &:before {
        border-bottom: 1px solid ${colors.borderGray};
        position: absolute;
        content: "";
        left: 24px;
        right: 24px;
        bottom: 0;
    }
    p {
        color: ${colors.grayEight};
    }
`;

export const LocksItemTopStyled = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    span {
        color: ${colors.graySeven};
    }
`;
