import React, { useState, useEffect, ReactElement } from "react";

import { Input } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation } from "urql";
import { UPDATE_ORGANIZATION_NAME } from "../../../../graphql/mutations/organization/organization.mutations";
import { DetailsConfirmButtons } from "../details-confirm-buttons/details-confirm-buttons";
import { DetailsNameContainer } from "./details-name.styled";

interface DetailsNameProps {
    id: string | number;
    name: string;
}

export const DetailsName = (props: DetailsNameProps): ReactElement => {
    const { id, name } = props;
    const { t } = useTranslation();

    const [currentName, setCurrentName] = useState(name);
    const [isEditMode, setIsEditMode] = useState(false);

    // eslint-disable-next-line
    const [result, UpdateOrganisation] = useMutation(UPDATE_ORGANIZATION_NAME);

    useEffect(() => {
        setCurrentName(name);
    }, [name, isEditMode]);

    const handleChangeMode = (): void => {
        setIsEditMode(!isEditMode);
    };

    const handleSaveName = async (): Promise<void> => {
        await UpdateOrganisation({
            id: Number(id),
            name: currentName,
        });

        setIsEditMode(!isEditMode);

        handleChangeMode();
    };

    if (!id) {
        return <></>;
    }

    return (
        <DetailsNameContainer>
            {isEditMode ? (
                <Input
                    placeholder={t("details_page.name")}
                    value={currentName}
                    onChange={(e: any) => setCurrentName(e.target.value)}
                    style={{
                        maxWidth: 300,
                        marginBottom: 5,
                    }}
                />
            ) : (
                <div>{name}</div>
            )}

            <DetailsConfirmButtons
                isEditMode={isEditMode}
                onChangeMode={handleChangeMode}
                onSave={handleSaveName}
            />
        </DetailsNameContainer>
    );
};
