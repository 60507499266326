export const ValidToDropdownItems = [
    { value: 1, label: "1h" },
    { value: 3, label: "3h" },
    { value: 24, label: "24h" },
    { value: 168, label: "1w" },
    { value: 504, label: "3w" },
    { value: 8760, label: "1y" },
    { value: "other", label: "Other" },
];

export const initialCreateCodecardFormValues = {
    name: "",
    locks: [],
    validFrom: "",
    validFor: "",
    validTo: "",
    notes: "",
    isActive: false,
};

export const initialEditCodecardFormValues = {
    isActive: false,
    validFrom: "",
    validTo: "",
};
