import { useEffect } from "react";
import { getScreenSizesConfig } from "../config/screenSizesConfig";
import { Stores, useStore } from "../stores/store";
import { WindowDimensionsStore } from "../stores/windowDimensionsStore";
import { SupportedDevicesEnum } from "../types/SupportedDevicesEnum";
import { getWindowDimensions } from "../utils/getWindowDimensions";

const RESIZE_EVENT = "resize";

const getDeviceType = (screenWidth: number): SupportedDevicesEnum => {
    const screenSizesConfig = getScreenSizesConfig();
    let deviceType: SupportedDevicesEnum = SupportedDevicesEnum.DESKTOP;

    for (const screenSize in screenSizesConfig) {
        const screenSizeType = screenSize as SupportedDevicesEnum;
        const { min, max } = screenSizesConfig[screenSizeType];

        const isDeviceDetected = screenWidth >= min && screenWidth <= max;

        if (isDeviceDetected) {
            deviceType = screenSizeType;

            break;
        }
    }

    return deviceType;
};

export const useWindowDimensions = (): void => {
    const { setDeviceType, setWindowDimensions } = useStore(
        Stores.WINDOW_DIMENSIONS_STORE,
    ) as WindowDimensionsStore;

    const handleWindowResize = (): void => {
        const { width, height } = getWindowDimensions();
        const deviceType = getDeviceType(width);

        setDeviceType(deviceType);
        setWindowDimensions({ width, height });
    };

    useEffect(() => {
        window.addEventListener(RESIZE_EVENT, handleWindowResize);

        handleWindowResize();

        return () => {
            window.removeEventListener(RESIZE_EVENT, handleWindowResize);
        };
    }, []);
};
