import styled from "styled-components";
import { colors } from "../../styles/colors";

export const FormValueLineContainer = styled.div<{
    alignItems?: "center" | "flex-start" | "flex-end";
}>`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    ${({ alignItems }) => (alignItems ? "align-items:" + alignItems : "align-items: flex-start")}
`;

export const FormContainer = styled.div`
    padding-right: 8px;
    text-align: right;
    padding-top: 5px;
    width: 115px;
`;

export const FormValueContainer = styled.div`
    width: 332px;
`;

export const FormOptionalContainer = styled.div`
    color: ${colors.graySeven};
`;
