import {
    getAddLockPath,
    getCodecardPath,
    getCodecardsPath,
    getDetailsPath,
    getInfoLockPath,
    getLocksPath,
    getUsersPath,
} from "../routes/paths/dynamic-paths";

export const LockPageBreadcrumbs = (organizationId: string, label: string) => [
    {
        path: getLocksPath(organizationId),
        breadcrumbName: "Locks",
    },
    { path: "", breadcrumbName: `${label}` },
];

export const EditLockPageBreadcrumbs = (organizationId: string, label: string, lockId: string) => [
    {
        path: getLocksPath(organizationId),
        breadcrumbName: "Locks",
    },
    {
        path: getInfoLockPath(organizationId, lockId),
        breadcrumbName: `${label}`,
    },
    { path: "", breadcrumbName: "Edit lock" },
];

export const CreateLockPageBreadcrumbs = (organizationId: string) => [
    {
        path: getAddLockPath(organizationId),
        breadcrumbName: "Locks",
    },
    { path: "", breadcrumbName: "Create lock" },
];

export const CodecardPageBreadcrumbs = (organizationId: string, label: string) => [
    {
        path: getCodecardsPath(organizationId),
        breadcrumbName: "Codecards",
    },
    {
        path: "",
        breadcrumbName: `${label}`,
    },
];

export const CreateCodecardPageBreadcrumbs = (organizationId: string) => [
    {
        path: getCodecardsPath(organizationId),
        breadcrumbName: "Codecards",
    },
    { path: "", breadcrumbName: "Create codecard" },
];

export const SendCodecardPageBreadcrumbs = (
    organizationId: string,
    label: string,
    codecardId: string,
) => [
    {
        path: getCodecardsPath(organizationId),
        breadcrumbName: "Codecards",
    },
    {
        path: getCodecardPath(organizationId, codecardId),
        breadcrumbName: `${label}`,
    },
    { path: "", breadcrumbName: "Send codecard" },
];

export const getEditCodecardPageBreadcrumbs = (
    organizationId: string,
    label: string,
    codecardId: string,
) => [
    {
        path: getCodecardsPath(organizationId),
        breadcrumbName: "Codecards",
    },
    {
        path: getCodecardPath(organizationId, codecardId),
        breadcrumbName: `${label}`,
    },
    { path: "", breadcrumbName: "Edit codecard" },
];

export const getDetailsPageBreadcrumbs = (organizationId: string, label: string) => [
    {
        path: getDetailsPath(organizationId),
        breadcrumbName: "Details",
    },
    { path: "", breadcrumbName: label },
];

export const getOrganizationUserPageBreadcrumbs = (organizationId: string, label: string) => [
    {
        path: getUsersPath(organizationId),
        breadcrumbName: "Users",
    },
    { path: "", breadcrumbName: label },
];
