import React, { ReactElement } from "react";

import { ContentHeaderStyled } from "../../components/content-header/content-header.styled";
import { PageHeader } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { CustomBreadcrumbs } from "../../components/breadcrumbs/bredcrambs.component";
import { withAdaptiveContainer, WithAdaptiveProps } from "../../HOC/withAdaptiveContainer";
import { SupportedDevicesEnum } from "../../types/SupportedDevicesEnum";
import { observer } from "mobx-react-lite";
import { Stores, useStore } from "../../stores/store";
import { ApplicationStore } from "../../stores/applicationStore";
import { ContentLayoutProps } from "./content-layout.component";
import { ContentLayoutHeaderStyledContainer } from "./content-layout.styled";

interface ContentLayoutHeaderProps extends ContentLayoutProps {}
type ContentLayoutHeaderAdaptiveProps = ContentLayoutHeaderProps & WithAdaptiveProps;

interface HistoryBackButtonProps {
    label?: string;
    isBackPath?: boolean;
}
export const HistoryBackButton = (props: HistoryBackButtonProps): ReactElement => {
    const { label, isBackPath } = props;

    const handleGoBack = () => {
        if (isBackPath) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            window.history.state.idx === 0 ? null : window.history.back();
        }
    };

    if (!label) {
        return <></>;
    }

    return (
        <button className="button" onClick={handleGoBack}>
            <ArrowLeftOutlined style={{ fontSize: "17px" }} /> &nbsp;
            {label}
        </button>
    );
};

const ContentLayoutHeader = observer((props: ContentLayoutHeaderAdaptiveProps) => {
    const { isBackPath, label, breadcrumbs, extra, deviceType } = props;

    const { isMobileNavigationOpen } = useStore(Stores.APPLICATION_STORE) as ApplicationStore;

    const isMobile = deviceType !== SupportedDevicesEnum.DESKTOP;

    return (
        <ContentLayoutHeaderStyledContainer isMobile={isMobile && isMobileNavigationOpen}>
            <ContentHeaderStyled isMenuOpen={isMobile && isMobileNavigationOpen}>
                <PageHeader
                    title={<HistoryBackButton label={label} isBackPath={isBackPath} />}
                    className="site-page-header"
                    extra={[extra]}
                    breadcrumbRender={() => (
                        <CustomBreadcrumbs
                            key={label}
                            breadcrumbs={breadcrumbs}
                            isLightColor={isMobile && isMobileNavigationOpen}
                        />
                    )}
                />
            </ContentHeaderStyled>
        </ContentLayoutHeaderStyledContainer>
    );
});

export const ContentLayoutHeaderAdaptiveContainer = withAdaptiveContainer(ContentLayoutHeader);
