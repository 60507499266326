import { useEffect, useState } from "react";
import { LockInterface } from "../types/Lock.interface";

interface UseSearchInterface {
    searchResult: LockInterface[];
}

const filterByKey = (values: object[], searchFieldKey: string, searchValue: string) => {
    return values.filter((value: any) => !value?.[searchFieldKey]?.indexOf(searchValue));
};

export const useSearch = (
    values: object[],
    searchFieldKey: string,
    searchValue: string,
): UseSearchInterface => {
    const [searchResult, setSearchResult] = useState<any>();

    useEffect(() => {
        if (!searchValue) {
            setSearchResult(values);

            return;
        }
        const searchResult = filterByKey(values, searchFieldKey, searchValue);
        setSearchResult(searchResult);
    }, [values, searchFieldKey, searchValue]);

    return { searchResult };
};
