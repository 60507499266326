import { getEditCodecardPageBreadcrumbs } from "../../constants/breadcrumbs";
import { PageEnum } from "../../types/PagesEnum";
import {
    ADD,
    BASE_ROUTE,
    CODECARD,
    CODECARDS,
    CREATE,
    DETAILS,
    EDIT,
    LOCK,
    LOCKS,
    MESSAGE,
    NOTIFICATIONS,
    SEND,
    SETTINGS,
    SUBUNITS,
    USER,
    USERS,
} from "../routes.constants";

export const getDynamicBaseRoute = (organizationId: string): string => {
    return `${BASE_ROUTE}/${organizationId}`;
};

export const getLocksPath = (organizationId: string): string => {
    return `${getDynamicBaseRoute(organizationId)}/${LOCKS}`;
};

export const getDetailsPath = (organizationId: string): string => {
    return `${getDynamicBaseRoute(organizationId)}/${DETAILS}`;
};

export const getCreateSubUnitPath = (): string => {
    return `/manager/organizations/${CREATE}`;
};

export const getAddUserPath = (organizationId: string): string => {
    return `${getDynamicBaseRoute(organizationId)}/${USERS}/${USER}/${ADD}`;
};

export const getOrganizationUserPath = (organizationId: string, userId: number): string => {
    return `${getDynamicBaseRoute(organizationId)}/${USERS}/${USER}/${userId}`;
};

export const getAddLockPath = (organizationId: string): string => {
    return `${getDynamicBaseRoute(organizationId)}/${LOCKS}/${ADD}`;
};

export const getInfoLockPath = (organizationId: string, lockId: string): string => {
    return `${getDynamicBaseRoute(organizationId)}/${LOCKS}/${LOCK}/${lockId}`;
};

export const getEditLockPath = (organizationId: string, lockId: string): string => {
    return `${getDynamicBaseRoute(organizationId)}/${LOCKS}/${LOCK}/${lockId}/${EDIT}`;
};

export const getCodecardPath = (organizationId: string, codecardId: string) => {
    return `${getDynamicBaseRoute(organizationId)}/${CODECARDS}/${CODECARD}/${codecardId}`;
};

export const getCodecardsPath = (organizationId: string): string => {
    return `${getDynamicBaseRoute(organizationId)}/${CODECARDS}/`;
};

export const getCodecardsLockPath = (organizationId: string, lockId: string): string => {
    return `${getDynamicBaseRoute(organizationId)}/${CODECARDS}/${LOCK}/${lockId}`;
};

export const getCreateCodecardsPath = (organizationId: string) => {
    return `${getDynamicBaseRoute(organizationId)}/${CODECARDS}/${CREATE}`;
};

export const getCreateCodecardPath = (organizationId: string, lockId: string): string => {
    return `${getDynamicBaseRoute(organizationId)}/${CODECARDS}/${CODECARD}/${lockId}/${CREATE}`;
};

export const getCodecardSendPath = (organizationId: string, codecardId: string): string => {
    return `${getDynamicBaseRoute(organizationId)}/${CODECARDS}/${CODECARD}/${codecardId}/${SEND}`;
};

export const getCodecardEditPath = (organizationId: string, codecardId: string): string => {
    return `${getDynamicBaseRoute(organizationId)}/${CODECARDS}/${CODECARD}/${codecardId}/${EDIT}`;
};

export const getUsersPath = (organizationId: string): string => {
    return `${getDynamicBaseRoute(organizationId)}/${USERS}/`;
};

export const getMessagePath = (organizationId: string): string => {
    return `${getDynamicBaseRoute(organizationId)}/${MESSAGE}/`;
};

export const getSubunitsPath = (organizationId: string): string => {
    return `${getDynamicBaseRoute(organizationId)}/${SUBUNITS}/`;
};

export const getNotificationsPath = (organizationId: string): string => {
    return `${getDynamicBaseRoute(organizationId)}/${NOTIFICATIONS}/`;
};

export const getSettingsPath = (organizationId: string): string => {
    return `${getDynamicBaseRoute(organizationId)}/${SETTINGS}/`;
};

export const getPathByPageType = (pageType: PageEnum): any => {
    const pageByPath = {
        [PageEnum.ADD_LOCK]: getAddLockPath,
        [PageEnum.ADD_USER]: getAddUserPath,
        [PageEnum.CREATE_SUB_UNIT]: getCreateSubUnitPath,
        [PageEnum.CODECARD]: getCodecardPath,
        [PageEnum.CODECARDS]: getCodecardsPath,
        [PageEnum.CODECARD_SEND]: getCodecardSendPath,
        [PageEnum.CREATE_CODECARD]: getCreateCodecardPath,
        [PageEnum.CREATE_CODECARDS]: getCreateCodecardsPath,
        [PageEnum.DETAILS]: getDetailsPath,
        [PageEnum.EDIT_CODECARD]: getEditCodecardPageBreadcrumbs,
        [PageEnum.EDIT_LOCK]: getEditLockPath,
        [PageEnum.INFO_LOCK]: getInfoLockPath,
        [PageEnum.LOCKS]: getLocksPath,
        [PageEnum.MESSAGES]: getMessagePath,
        [PageEnum.SETTINGS]: getSettingsPath,
        [PageEnum.SUBUNITS]: getSubunitsPath,
        [PageEnum.USERS]: getUsersPath,
        [PageEnum.USER]: getOrganizationUserPath,
        [PageEnum.NOTIFICATIONS]: getNotificationsPath,
        [PageEnum.ORGANISATION_ROOT]: getDynamicBaseRoute,
    };

    return pageByPath[pageType];
};
