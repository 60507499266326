import React, { ReactElement } from "react";
import { PageContainer, PageContentContainer } from "../../styles/layouts";
import { ContentLayout } from "../../layouts/content-layout/content-layout.component";
import { useOrganization } from "../../hooks/useOrganization";
import Loader from "../../components/loader/loader.component";
import { LoaderContentWrapper, LoaderPageWrapper } from "../../styles/loader-wrapper.styled";
import { useOrganizationBreadcrumbs } from "../../hooks/useOrganizationBreadcrumbs";
import { MenuItemsEnum } from "../../components/menu-component/menu.component";
import { PageEnum } from "../../types/PagesEnum";
import ContentHeader from "../../components/content-header/content-header.component";
import { useParams } from "react-router-dom";
import { RouteParamsInterface } from "../../types/RouteParams.interface";
import { useQuery } from "urql";
import { GET_USERS } from "../../graphql/queries/users/users.query";
import { TableContentStyled, TableHeaderContentStyled } from "../../styles/table";
import { UserRow } from "./users.component";
import { UserInterface } from "../../types/User.interface";
import { Button } from "antd";
import { useRouteNavigation } from "../../hooks/useNavigationPatch";
import { InDevelopmentMessage } from "../../components/messages/in-development/in-development-message.component";

const UsersLayoutBody = (): ReactElement => {
    const { organizationId } = useParams() as RouteParamsInterface;
    const { navigateToCreateUser } = useRouteNavigation();
    const numberOrganizationId = parseInt(organizationId);
    const [resultUsers] = useQuery({
        query: GET_USERS,
        variables: {
            organisation_id: numberOrganizationId,
        },
    });
    const { data, fetching } = resultUsers || {};
    const { Users: users } = data || {};

    const handleNavigateToAddLock = () => {
        navigateToCreateUser(organizationId);
    };

    const buttonsBlock = () => {
        return (
            <Button type="primary" onClick={handleNavigateToAddLock} key={organizationId}>
                Add User
            </Button>
        );
    };

    if (fetching) {
        return (
            <LoaderContentWrapper>
                <Loader />
            </LoaderContentWrapper>
        );
    }

    return (
        <div style={{ width: "100%" }}>
            <InDevelopmentMessage />
            {false && (
                <>
                    <ContentHeader label={"Users"} buttonsBlock={buttonsBlock} />
                    <TableContentStyled>
                        <thead>
                            <TableHeaderContentStyled>
                                <th>Name</th>
                                <th>Staff</th>
                                <th>Email</th>
                                <th>Language</th>
                            </TableHeaderContentStyled>
                        </thead>
                        <tbody>
                            {users?.map((user: UserInterface) => {
                                return (
                                    <UserRow
                                        data={user}
                                        organizationId={organizationId}
                                        key={user.id}
                                    />
                                );
                            })}
                        </tbody>
                    </TableContentStyled>
                </>
            )}
        </div>
    );
};

export const UsersPage = (): ReactElement => {
    const { fetching, organization } = useOrganization();
    const { organizationBreadcrumbs } = useOrganizationBreadcrumbs(PageEnum.USERS);

    if (fetching) {
        return (
            <LoaderPageWrapper>
                <Loader />
            </LoaderPageWrapper>
        );
    }

    return (
        <PageContainer>
            <PageContentContainer>
                <ContentLayout
                    breadcrumbs={organizationBreadcrumbs}
                    label={organization?.name}
                    openKey={MenuItemsEnum.Users}
                    ContentLayoutBody={<UsersLayoutBody />}
                    isBackPath
                />
            </PageContentContainer>
        </PageContainer>
    );
};
