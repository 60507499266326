import React, { ReactElement } from "react";

import { OrganisationInterface } from "../../types/Organisation.interface";
import { NavigationListItem } from "./navigation-list-item";

export interface NavigationSubMenuProps {
    organisations?: OrganisationInterface[];
    layer?: number;
    selectedItem: number;
    onSelectedItem: (value: number) => any;
    isMobile: boolean;
}

export const NavigationSubMenu = (props: NavigationSubMenuProps): ReactElement => {
    const { organisations = [], layer, selectedItem, isMobile, onSelectedItem } = props;
    const layerStart = layer || 1;

    return (
        <div>
            {organisations.map((item: OrganisationInterface, idx: number) => {
                return (
                    <NavigationListItem
                        key={idx}
                        item={item}
                        layer={layerStart}
                        onSelectedItem={onSelectedItem}
                        selectedItem={selectedItem}
                        isMobile={isMobile}
                    />
                );
            })}
        </div>
    );
};
