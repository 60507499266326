export enum PageEnum {
    LOCKS = "LOCKS",
    ADD_LOCK = "ADD_LOCK",
    INFO_LOCK = "INFO_LOCK",
    EDIT_LOCK = "EDIT_LOCK",

    DETAILS = "DETAILS",

    ADD_USER = "ADD_USER",
    USERS = "USERS",
    USER = "USER",
    CREATE_SUB_UNIT = "CREATE_SUB_UNIT",

    CODECARD = "CODECARD",
    CODECARDS = "CODECARDS",
    CREATE_CODECARD = "CREATE_CODECARD",
    CREATE_CODECARDS = "CREATE_CODECARDS",
    EDIT_CODECARD = "EDIT_CODECARD",
    CODECARD_SEND = "CODECARD_SEND",

    MESSAGES = "MESSAGES",

    SUBUNITS = "SUBUNITS",

    SETTINGS = "SETTINGS",

    NOTIFICATIONS = "NOTIFICATIONS",

    ORGANISATION_ROOT = "ORGANISATION_ROOT",
}
