import { Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
    EditCodecardField,
    EditCodecardItemStyle,
    EditCodecardLabel,
} from "./edit-codecard.styled";
import { CustomDatePicker } from "../../../components/date-picker/date-picker.component";
import { useTranslation } from "react-i18next";
import { EditCodecardTypes } from "./edit-codecard-types";
import { ReactElement } from "react";
import { CodecardFormEnum } from "../../../utils/enums/form.enum";

interface EditCodecardFormProps {
    formValues: EditCodecardTypes;
    onChangeDate: (key: string) => (_: any, dateString: string) => void;
    onChangeActive: (status: boolean) => void;
    currentStatus: boolean;
}

export const EditCodecardForm = (props: EditCodecardFormProps): ReactElement => {
    const { formValues, onChangeDate, onChangeActive, currentStatus } = props;
    const { t } = useTranslation();
    const isDisabledValidTo = formValues.validFrom < new Date().toISOString();

    return (
        <>
            <EditCodecardItemStyle>
                <EditCodecardLabel>{t("create_codecard.valid_from")}</EditCodecardLabel>
                <EditCodecardField>
                    <CustomDatePicker
                        placeholder={formValues.validFrom}
                        handleChange={onChangeDate(CodecardFormEnum.VALID_FROM)}
                    />
                </EditCodecardField>
            </EditCodecardItemStyle>
            <EditCodecardItemStyle>
                <EditCodecardLabel>{t("create_codecard.valid_to")}</EditCodecardLabel>
                <EditCodecardField>
                    <CustomDatePicker
                        placeholder={formValues.validTo}
                        handleChange={onChangeDate(CodecardFormEnum.VALID_TO)}
                        startDate={formValues.validFrom}
                        isDisabled={isDisabledValidTo}
                    />
                </EditCodecardField>
            </EditCodecardItemStyle>
            <EditCodecardItemStyle>
                <EditCodecardLabel>Active :</EditCodecardLabel>
                <EditCodecardField>
                    <div style={{ margin: "5px 0 0 10px" }} />
                    <Switch
                        onChange={onChangeActive}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked={currentStatus}
                    />
                </EditCodecardField>
            </EditCodecardItemStyle>
        </>
    );
};
