import { LocksState } from "./info.styled";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import React from "react";
import { useQuery } from "urql";
import { GET_KEYS } from "../../../graphql/queries/keys/keys.query";
import { LoaderContentWrapper } from "../../../styles/loader-wrapper.styled";
import Loader from "../../../components/loader/loader.component";
import { useRouteNavigation } from "../../../hooks/useNavigationPatch";
import { KeyValueLine } from "../../../components/key-value-line/key-value-line";

export const InfoComponent = (props: any) => {
    const { data, organizationId } = props;
    const { label, type, notes, battery_change: batteryChange, id: lockId } = data || {};
    const { navigateToCodecard } = useRouteNavigation();

    const { t } = useTranslation();

    const currentDate = Date.parse(new Date().toDateString());
    const days = (currentDate - Date.parse(batteryChange)) / 86400000;

    const percentChange = days <= 365 ? Math.round(100 - (100 / 365) * days) : 0;

    const [resultKeys] = useQuery({
        query: GET_KEYS,
        variables: { lock_id: lockId },
    });
    const { data: dataKeys, fetching } = resultKeys || {};

    const getCodecards = () => {
        if (Array.isArray(dataKeys?.Keys)) {
            const { Keys: keys } = dataKeys;
            const codecard = keys?.map(({ codecard }: any, idx: number) => {
                const separator = keys.length - 1 > idx ? ", " : "";
                const displayName = codecard.id + separator;

                return (
                    <span
                        style={{ cursor: "pointer", color: "#1890ff" }}
                        key={codecard.id}
                        onClick={() => navigateToCodecard(organizationId, codecard.id)}
                    >
                        {displayName}
                    </span>
                );
            });

            return codecard;
        }
    };

    if (fetching) {
        return (
            <LoaderContentWrapper>
                <Loader />
            </LoaderContentWrapper>
        );
    }

    return (
        <div>
            <KeyValueLine Key={<div>Name</div>} Value={<div>{label ? label : type}</div>} />
            <KeyValueLine Key={<div>Description</div>} Value={<div>{notes}</div>} />
            <KeyValueLine Key={<div>Type</div>} Value={<div>{type}</div>} />
            <KeyValueLine
                Key={<div>Codecards containing this lock</div>}
                Value={<div>{getCodecards()}</div>}
            />
            <KeyValueLine
                Key={<div>State</div>}
                Value={
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "5px",
                                marginRight: "5px",
                            }}
                        >
                            <LocksState state />
                            Active
                        </div>
                        <Button>{t("buttons.deactivate")}</Button>
                    </div>
                }
            />
            <KeyValueLine Key={<div>Added by, at</div>} Value={<div></div>} />
            <KeyValueLine Key={<div>Logs</div>} Value={<div></div>} />
            <KeyValueLine
                Key={<div>Battery</div>}
                Value={
                    <div>
                        {percentChange}%. Last replacement: {new Date(batteryChange).toDateString()}
                    </div>
                }
            />
        </div>
    );
};
