import React, { ReactElement } from "react";

import { KeyValueLineContainer, KeyContainer, ValueContainer } from "./key-value-line.styled";

interface KeyValueLineProps {
    Key: ReactElement;
    Value: ReactElement;
}

export const KeyValueLine = (props: KeyValueLineProps): ReactElement => {
    const { Key, Value } = props;

    return (
        <KeyValueLineContainer>
            <KeyContainer>{Key}</KeyContainer>
            <ValueContainer>{Value}</ValueContainer>
        </KeyValueLineContainer>
    );
};
