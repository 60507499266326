import styled from "styled-components";

export const EditCodecardContentStyled = styled.div`
    width: 100%;
`;

export const EditCodecardWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 27px 16px;
    border-bottom: 1px solid #f0f0f0;
`;

export const EditCodecardItemStyle = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
`;

export const EditCodecardLabel = styled.div`
    padding-right: 8px;
    text-align: right;
    padding-top: 5px;
    width: 115px;
`;

export const EditCodecardField = styled.div`
    width: 332px;
`;

export const EditButtonsBlock = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 24px;
    margin: 32px 0;
    button + button {
        margin-left: 12px;
        margin-right: 25px;
    }
`;
