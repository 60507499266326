import { FilterArea } from "../../../components/filter-area/filter-area.component";
import React from "react";

const defaultCodecardsSortrOptions = [
    { label: "Organization", id: 1, value: "organisation_id" },
    { label: "Activity ", id: 2, value: "active" },
    { label: "Valid from", id: 3, value: "valid_from" },
    { label: "Created by", id: 4, value: "created_by" },
    { label: "Created at", id: 5, value: "created_at" },
    { label: "Updated at", id: 6, value: "updated_at" },
    { label: "Updated by", id: 7, value: "updated_by" },
];

interface CodecardsFiltersProps {
    filterValues: { search: string; sort: string; filter: any[] };
    onChange: (key: string) => (event: any) => void;
    locks: any[];
}

export const CodecardsFilters = (props: CodecardsFiltersProps) => {
    const { filterValues, onChange, locks } = props;

    return (
        <>
            <FilterArea
                searchValue={filterValues.search}
                onChangeSearch={(value) => onChange("search")(value)}
                filterOptions={locks}
                filterValue={filterValues.filter}
                onChangeFilter={(value) => onChange("filter")(value)}
                sortOptions={defaultCodecardsSortrOptions}
                sortValue={filterValues.sort}
                onChangeSort={(value) => onChange("sort")(value)}
            />
        </>
    );
};
