import { makeAutoObservable } from "mobx";

export class OrganisationsStore {
    organisations = [];
    flatOrganisations = [];

    constructor() {
        this.setOrganisations = this.setOrganisations.bind(this);
        this.setFlatOrganisations = this.setFlatOrganisations.bind(this);

        makeAutoObservable(this);
    }

    setOrganisations(organisations: any): void {
        this.organisations = organisations;
    }

    setFlatOrganisations(organisations: any): void {
        this.flatOrganisations = organisations;
    }
}
