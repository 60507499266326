import { ReactElement } from "react";
import { colors } from "../../styles/colors";

interface BatteryIconProps {
    charge: number;
}

export const BatteryIcon = (props: BatteryIconProps): ReactElement => {
    const { charge } = props;
    const levelCharge = (2745 / 100) * charge;
    const batteryColor = colors.graySeven;

    return (
        <>
            <svg width="40" height="20" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <rect x="-1" y="-1" width="48" height="22" id="canvas_background" fill="none" />
                </g>
                <g>
                    <g id="svg_1">
                        <g
                            transform="matrix(0, -0.00548676, -0.00810916, 0, 55.8346, 72.0234)"
                            id="svg_2"
                        >
                            <path
                                d="m10990.255165,6458.757923c-39,-19 -65,-65 -60,
                                -103l0,0l4,-28l-345,-4c-331,-4 -348,-5 -403,-27c-81,
                                -31 -159,-106 -200,-189l0,0l-33,-67l0,-1710l0,-1710l33,
                                -67c40,-82 119,-157 200,-190l0,0l57,-23l1100,0l1100,0l58,
                                23c81,33 166,114 203,194l0,0l29,63l0,1710l0,1710l-33,
                                67c-41,83 -120,158 -200,189c-54,22 -73,23 -373,27l0,0l-316,
                                4l5,23c6,36 -25,91 -63,109c-50,24 -714,23 -763,-1zm1445,-344c40,
                                -15 73,-48 94,-94c18,-38 19,-107 19,-1688c0,-1791 3,-1685 -53,
                                -1745c-51,-55 8,-52 -1152,-52c-1180,0 -1105,-4 -1158,60c-15,17
                                -31,47 -37,68c-14,51 -14,3283 0,3334c13,47 55,97 95,114c39,17
                                2146,21 2192,3z"
                                fill={batteryColor}
                                fillRule="nonzero"
                                id="svg_3"
                            />
                            <path
                                d={`m10318.255165,3120.757923l0,
                                    -${levelCharge === 0 ? 0 : 345}l1025,
                                    0l1025,0l0,
                                    ${levelCharge}l0,
                                    ${levelCharge === 0 ? 0 : 345}l-1025,0l-1025,0l0,
                                    -${levelCharge}z`}
                                fill={batteryColor}
                                fillRule="nonzero"
                                id="svg_7"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        </>
    );
};
