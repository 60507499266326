import { useParams } from "react-router-dom";
import { useQuery } from "urql";

import { GET_ORGANIZATION } from "../graphql/queries/organizations/organizations.query";
import { OrganisationInterface } from "../types/Organisation.interface";

interface UseOrganizationInterface {
    organization: OrganisationInterface;
    fetching: boolean;
}

export const useOrganization = (): UseOrganizationInterface => {
    const { organizationId } = useParams() as any;

    const numberOrganization = parseInt(organizationId);
    const [resultUnit] = useQuery({
        query: GET_ORGANIZATION,
        variables: { id: numberOrganization },
    });

    const { data, fetching } = resultUnit;

    const { Organisation } = data || {};

    return {
        organization: Organisation,
        fetching,
    };
};
