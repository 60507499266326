export const GET_CODECARD = `
	query ($id: String!) {
		Codecard (id: $id) {
			id,
			organisation_id {
			id,email
			},
			active,
			valid_from,
			valid_to,
			created_at,
			updated_at,
			keys {
                lock{
                    id,
                    label,
                    type
                }
            },
		}
  	}
`;
