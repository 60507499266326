import styled from "styled-components";

export const LanguageDropdownStyled = styled.div`
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background: transparent;
        color: #fff;
        border: 1px solid gray;
    }

    .ant-select-arrow {
        color: gray;
    }
`;
