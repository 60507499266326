import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { OrganisationsStore } from "../stores/organisationsStore";
import { Stores, useStore } from "../stores/store";

import { getPathByPageType } from "../routes/paths/dynamic-paths";

import { OrganisationInterface } from "../types/Organisation.interface";
import { RouteParamsInterface } from "../types/RouteParams.interface";
import { BreadcrumbsInterface } from "../types/Breadcrumbs.interface";
import { PageEnum } from "../types/PagesEnum";
import { ApplicationStore } from "../stores/applicationStore";

const getBreadcrumbs = (
    orgs: OrganisationInterface[],
    parentToFind: number,
    getPathCb: any,
): BreadcrumbsInterface[] => {
    if (!orgs?.length) {
        return [];
    }

    const breadcrumbs: BreadcrumbsInterface[] = [];

    const getBreadcrumbsPath = (parentToFind?: number): void => {
        orgs.forEach((org: any) => {
            const { id: orgId, name, parent } = org;

            if (parentToFind === orgId) {
                const breadcrumb = {
                    path: getPathCb(orgId),
                    breadcrumbName: name,
                };

                breadcrumbs.push(breadcrumb);

                if (parent?.id) {
                    getBreadcrumbsPath(parent?.id);
                }
            }
        });
    };

    getBreadcrumbsPath(parentToFind);

    return breadcrumbs;
};

interface UseOrganizationBreadcrumbs {
    organizationBreadcrumbs: BreadcrumbsInterface[];
}

const { ORGANISATION_STORE, APPLICATION_STORE } = Stores;

export const useOrganizationBreadcrumbs = (pageType: PageEnum): UseOrganizationBreadcrumbs => {
    const { organizationId } = useParams() as RouteParamsInterface;

    const { flatOrganisations = [] } = useStore(ORGANISATION_STORE) as OrganisationsStore;
    const { organizationBreadcrumbs, setOrganizationBreadcrumbs } = useStore(
        APPLICATION_STORE,
    ) as ApplicationStore;

    useEffect(() => {
        if (!flatOrganisations.length) {
            return;
        }

        const getPath = getPathByPageType(pageType);
        const breadcrumbs = getBreadcrumbs(flatOrganisations, parseInt(organizationId), getPath);

        setOrganizationBreadcrumbs(breadcrumbs.reverse());
    }, [organizationId, flatOrganisations, pageType]);

    return { organizationBreadcrumbs };
};
