import React, { ReactElement, useEffect } from "react";

import { ContentLayoutBodyStyled, ContentLayoutWrapper } from "./content-layout.styled";
import { MenuComponent, MenuItemsEnum } from "../../components/menu-component/menu.component";
import { ContentLayoutHeaderAdaptiveContainer } from "./content-layout-header.component";
import { withAdaptiveContainer, WithAdaptiveProps } from "../../HOC/withAdaptiveContainer";
import { SupportedDevicesEnum } from "../../types/SupportedDevicesEnum";
import { Stores, useStore } from "../../stores/store";
import { ApplicationStore } from "../../stores/applicationStore";

export interface ContentLayoutProps extends WithAdaptiveProps {
    ContentLayoutBody?: any;
    label?: string;
    isBackPath?: boolean;
    isBreadcrumbExists?: boolean;
    isMenu?: boolean;
    openKey?: MenuItemsEnum;
    navigation?: any;
    breadcrumbs?: any;
    extra?: React.ReactNode;
}

export const ContentLayout = withAdaptiveContainer((props: ContentLayoutProps): ReactElement => {
    const {
        label,
        isBackPath,
        ContentLayoutBody,
        isBreadcrumbExists = true,
        isMenu = true,
        openKey,
        breadcrumbs,
        extra,
        deviceType,
    } = props;

    const isMobile = deviceType !== SupportedDevicesEnum.DESKTOP;

    const { setCurrentPageLabel, setExtraHeaderButton } = useStore(
        Stores.APPLICATION_STORE,
    ) as ApplicationStore;

    useEffect((): any => {
        if (label) {
            setCurrentPageLabel(label);
        }

        if (extra) {
            setExtraHeaderButton(extra);
        }

        return () => {
            label && setCurrentPageLabel("");
            extra && setExtraHeaderButton([]);
        };
    }, [label]);

    return (
        <ContentLayoutWrapper>
            <ContentLayoutHeaderAdaptiveContainer
                // @ts-ignore
                label={label}
                isBackPath={isBackPath}
                isBreadcrumbExists={isBreadcrumbExists}
                breadcrumbs={breadcrumbs}
                extra={extra}
            />

            <ContentLayoutBodyStyled>
                {isMenu && !isMobile && <MenuComponent openKey={openKey} />}

                {ContentLayoutBody}
            </ContentLayoutBodyStyled>
        </ContentLayoutWrapper>
    );
}) as any;
