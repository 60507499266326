import { MenuOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { ApplicationStore } from "../../../stores/applicationStore";
import { Stores, useStore } from "../../../stores/store";
import { MobileHeaderLinksStyled } from "./mobile-header-links.styled";

export const MobileHeaderLinks = observer(() => {
    const { isMobileNavigationOpen, setIsMobileNavigationOpen } = useStore(
        Stores.APPLICATION_STORE,
    ) as ApplicationStore;

    const handleOpenCloseMobileNavigation = (): void => {
        setIsMobileNavigationOpen(!isMobileNavigationOpen);
    };

    return (
        <MobileHeaderLinksStyled>
            <a href="#">FAQ1</a>
            <a href="#">Contacts</a>
            <MenuOutlined onClick={handleOpenCloseMobileNavigation} />
        </MobileHeaderLinksStyled>
    );
});
