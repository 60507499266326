import React, { ReactElement, useEffect, useState } from "react";

import { WarningOutlined } from "@ant-design/icons";

import { BatteryIcon } from "./battery.icon";
import { BatteryThreshold } from "../../constants/battery.constans";

import { LockBatteryContainer } from "./battery.styled";

interface BatteryProps {
    batteryChange: string;
}

export const BatteryComponent = (props: BatteryProps): ReactElement => {
    const { batteryChange } = props;
    const [charge, setCharge] = useState(0);
    const currentDate = Date.parse(new Date().toDateString());
    const days = (currentDate - Date.parse(batteryChange)) / 86400000;
    const percentChange = 100 - (100 / 365) * days;

    useEffect(() => {
        if (percentChange > 0 && percentChange <= BatteryThreshold.MAXIMAL) {
            setCharge(percentChange);
        } else if (percentChange > BatteryThreshold.MAXIMAL) {
            setCharge(100);
        } else {
            setCharge(0);
        }
    }, [percentChange]);

    return (
        <LockBatteryContainer>
            {charge === 0 && (
                <WarningOutlined
                    style={{
                        color: "red",
                        fontSize: "19px",
                        paddingRight: "12px",
                    }}
                />
            )}
            <div
                style={{
                    display: "flex",
                    transform: "rotate(180deg) scale(0.8)",
                }}
            >
                <BatteryIcon charge={charge} />
            </div>
        </LockBatteryContainer>
    );
};
