import React, { ReactElement, useEffect } from "react";
import { LocksState } from "../../lock-page/info/info.styled";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { UPDATE_STATUS_CODECARD } from "../../../graphql/mutations/codecard/codecard.mutations";
import { useMutation } from "urql";
import { KeyValueLine } from "../../../components/key-value-line/key-value-line";
import { renderToast } from "../../../components/toast/toast";

interface CodecardStatusProps {
    status: boolean;
    codecardId: string;
}

export const CodecardStatus = (props: CodecardStatusProps): ReactElement => {
    const { status, codecardId } = props;
    const { t } = useTranslation();
    // eslint-disable-next-line
    const [isActive, setIsActive] = useMutation(UPDATE_STATUS_CODECARD);

    const handleEditStatus = async () => {
        if (codecardId) {
            await setIsActive({ id: codecardId, active: !status });
        }
    };

    useEffect(() => {
        if (!isActive.error && isActive?.data) {
            const messages = isActive.data.UpdateCodecard?.active
                ? "Codecard activate"
                : "Codecard deactivate";

            const type = isActive.data.UpdateCodecard?.active ? "success" : "error";

            renderToast({
                type: type,
                message: messages,
            });
        }
    }, [isActive?.error, isActive?.data]);

    return (
        <>
            <KeyValueLine
                Key={<div>State</div>}
                Value={
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <LocksState state={status} />
                            {status ? "Active" : "Inactive"}
                        </div>
                        <Button onClick={handleEditStatus}>
                            {status ? t("buttons.deactivate") : t("buttons.activate")}
                        </Button>
                    </div>
                }
            />
        </>
    );
};
