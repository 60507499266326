import styled from "styled-components";
import { colors } from "../../styles/colors";

export const ContentHeaderStyled = styled.div<{ isMenuOpen?: boolean }>`
    min-width: 100%;

    .button {
        color: ${({ isMenuOpen }) => (isMenuOpen ? colors.white : "inherit")};
        cursor: pointer;
    }
`;
