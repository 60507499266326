import React, { ReactElement } from "react";
import { PageContainer, PageContentContainer } from "../../styles/layouts";
import { ContentLayout } from "../../layouts/content-layout/content-layout.component";
import { useOrganization } from "../../hooks/useOrganization";
import Loader from "../../components/loader/loader.component";
import { LoaderPageWrapper } from "../../styles/loader-wrapper.styled";
import { useOrganizationBreadcrumbs } from "../../hooks/useOrganizationBreadcrumbs";
import { InDevelopmentMessage } from "../../components/messages/in-development/in-development-message.component";
import { MenuItemsEnum } from "../../components/menu-component/menu.component";
import { PageEnum } from "../../types/PagesEnum";

const MessageTemplatesLayoutBody = (): ReactElement => {
    return (
        <div style={{ width: "100%" }}>
            <InDevelopmentMessage />
        </div>
    );
};

export const MessageTemplatesPage = (): ReactElement => {
    const { fetching } = useOrganization();
    const { organizationBreadcrumbs } = useOrganizationBreadcrumbs(PageEnum.MESSAGES);

    if (fetching) {
        return (
            <LoaderPageWrapper>
                <Loader />
            </LoaderPageWrapper>
        );
    }

    return (
        <PageContainer>
            <PageContentContainer>
                <ContentLayout
                    breadcrumbs={organizationBreadcrumbs}
                    openKey={MenuItemsEnum.Message}
                    ContentLayoutBody={<MessageTemplatesLayoutBody />}
                    isBackPath
                />
            </PageContentContainer>
        </PageContainer>
    );
};
